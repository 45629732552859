import React, {Component} from 'react';
import MainFooter from "./MainFooter";
import MainNavbar from "./MainNavbar";
import {Redirect} from "react-router";
import GetAndDecryptMain from "./GetAndDecryptMain";
import * as PropTypes from 'prop-types';
import queryString from 'query-string';

class GetAndDecryptPage extends Component {
    
    constructor(props) {
        super(props);

        const values = props.location && queryString.parse(props.location.search);
        
        this.state = {
            redirectToLink: "",
            noDecrypt: values.raw && values.raw === "true",
            doCopy: values.copy && values.copy === "true",
        };
    }
    
    redirectTo(here) {
        this.setState({redirectToLink: here});
    }
    
    render() {
        if(this.state.redirectToLink!=="") {
            return <Redirect to={this.state.redirectToLink}/>
        }
        return (<div>
                    <MainNavbar showHome/>
                    <GetAndDecryptMain location={this.props.location} noDecrypt={this.state.noDecrypt} copying={this.state.doCopy} onRedirectRequest={(here) => this.redirectTo(here)} />
                    <MainFooter/>
            </div>);
    }
}

GetAndDecryptPage.propTypes = {
    location: PropTypes.object.isRequired,
};

export default GetAndDecryptPage;