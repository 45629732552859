import $ from 'jquery'
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import * as queryString from "query-string";
import Popup from "../COMMON/Popup";
import {
    addToFileUploadList,
    gotPasswordSet,
    sendDownloadFileRequest, updateCurrentFile
} from "../ACTIONS/clientActions";
import {saveFileViaTempPageLink} from "../SUPPORT/fileHandlers";
import {AESDecrypter, AESEncrypter} from "../SUPPORT/AESHandler";
import * as FileHandler from "../SUPPORT/fileHandlers";
import PasswordHintPrompt from "../COMMON/PasswordHintPrompt";
import ProgressPrompt from "../COMMON/ProgressPrompt";
import {Redirect} from "react-router";
import KSReduxUtils from "../SUPPORT/reduxHelpers";
import KSUtils from "../SUPPORT/ks-utils";

class GetAndDecryptMain extends Component {

    constructor(props) {
        super(props);

        // instance vars so we can cancel jobs.
        this._chunkedOutputFile = undefined;
        this._chunkedLoader = undefined;
        this._cancelDecryptionFlag = false;
        this._cancelLoadFlag = false;

        this.checkForPassIfRequired = this.checkForPassIfRequired.bind(this);
        this.getAndDecrypt = this.getAndDecrypt.bind(this);
        this.setPasswordClicked = this.setPasswordClicked.bind(this);
        this.cancelViewSave = this.cancelViewSave.bind(this);
        this.saveFile = this.saveFile.bind(this);
        this.viewTextPad = this.viewTextPad.bind(this);
        this.updateDecryptProgress = this.updateDecryptProgress.bind(this);
        this.cleanupDecryptionRun = this.cleanupDecryptionRun.bind(this);
        this.cancelDecryptClicked = this.cancelDecryptClicked.bind(this);
        this.moveStepToPasswordPrompt = this.moveStepToPasswordPrompt.bind(this);
        this.getFileName = this.getFileName.bind(this);
        this.getDrawerName = this.getDrawerName.bind(this);
        this.cancelLoadClicked = this.cancelLoadClicked.bind(this);
        this.updateLoadProgress = this.updateLoadProgress.bind(this);
        this.decryptBlob = this.decryptBlob.bind(this);
        this.goBackToFilesList = this.goBackToFilesList.bind(this);
        this.cancelPassClicked = this.cancelPassClicked.bind(this);
        this.cancelSetFileName = this.cancelSetFileName.bind(this);
        this.setFileNameAndRedirect = this.setFileNameAndRedirect.bind(this);
        this.addToUploadList = this.addToUploadList.bind(this);
        
        const query = props.location && queryString.parse(props.location.search);
        const loadededBlob = props.location && props.location.state && props.location.state.loadedBlob;
        
        // are we comign from a link router redirect?
        
        this.state = {
            loadedDataBlob: loadededBlob ? loadededBlob : null,
            decryptedFileName: undefined,
            decryptedFileType: undefined,
            decryptedBlob: undefined,
            decryptHint: "",
            step: "showLoading",
            decryptProgress: 0,
            loadingProgress: 0,
            queryFileName: query && query.fn,   // should be set if we are retrieving a shared file.
            redirectToDashboard: false,
            reloadFilesFlag: false,
            runAfterPass: undefined,
            saveAsFileName: undefined,
            encryptFile: [],
        };
    }
    
    componentDidMount() {
        
        // are we coming from local dir?
        
        if(!this.props.noDecrypt && !this.props.drawerPass) { // traps both undefined and "" (good)
            this.checkForPassIfRequired();
        }
        else { // just get and decrypt the file
            this.getAndDecrypt(this.props.drawerName,this.props.fileName, this.props.drawerPass);
        }
    }
    
    showLoading() {
        return(
            <ProgressPrompt id="loadingProgress" actionVerb="Loading" fileName={this.getFileName()}
                            percent={this.state.loadingProgress} onCancelClick={this.cancelLoadClicked}/>
            );
    }
    showDecryptingVisual() {
        return(
            <ProgressPrompt id="decryptProgress" actionVerb="Decrypting" fileName={this.getFileName()}
                            percent={this.state.decryptProgress} onCancelClick={this.cancelDecryptClicked}/>
        );
    }
    
    getFileName() {
        //return(this.props.sharing?this.state.queryFileName:this.props.fileName);
        return(this.state.queryFileName?this.state.queryFileName:this.props.fileName);
    }
    getDrawerName() {
        return(this.props.sharing?"-":(this.props.drawerName!==""?this.props.drawerName:"MAIN"));
    }
    
    //#region SAVING
    showAskIfSaving() {
        let saveInfoText = "The file has been decrypted and will be saved as:";
        if(this.props.noDecrypt) {
            saveInfoText="The encrypted file has been downloaded and will be saved as:"
        }
        return(
            <div className="ml-auto my-3">
                <div id="ks-login-box">
                    <div>{saveInfoText}
                        <div className="text-muted ks-truncate my-2">
                            <i>{this.state.decryptedFileName?this.state.decryptedFileName:this.getFileName()}</i>
                        </div>
                    </div>
                    <div className="input-group">
                        <button id="saveBTN" className="form-control btn-danger" onClick={this.saveFile}>Save</button>
                        <button id="cancelBTN" className="form-control ml-3" onClick={this.cancelViewSave}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }
    showAskForFileName() {
        Popup.showPrompt("File name for this new copy?",this.state.decryptedFileName+"_copy","Save",
            () => { // cancelled
                this.cancelViewSave();
        },(fileName) => {
            this.setFileNameAndRedirect(fileName);
        },1);
    }
    setFileNameAndRedirect(newSaveFileName) {
        // augment the state AFTER the dispatch so REDUX doesn't complain about messing with
        // the controlled store item decryptedFileBlob.
        const fileBlob = KSReduxUtils.augmentFileBlob(this.state.decryptedBlob,newSaveFileName,this.state.decryptedFileType);
        this.setState({
            encryptFile: fileBlob,
            step: "showPassPrompt",
            runAfterPass: this.addToUploadList,
            fileName: newSaveFileName,
            mode: "Encrypt"
        });
    }
    cancelSetFileName() {
        this.goBackToFilesList(false);
    }
    //#endregion
    
    addToUploadList(drawerName,fileName,pass,hint) { // add this to the global upload list
        this.props.dispatch(addToFileUploadList(drawerName, this.state.encryptFile, pass, hint));
        // we're done here.
        this.goBackToFilesList(false);
    }

    //#region PASSWORD_BOX
    checkForPassIfRequired() {
        // if(!this.props.sharing && this.props.drawerName===undefined) { // no drawers found -- assume reload needed.
        //     this.props.onRedirectRequest("/dashboard");
        //     return;
        // }
        if(!this.props.drawerPass || this.props.drawerPass==="") {
            this.moveStepToPasswordPrompt();
        }
    }
    moveStepToPasswordPrompt() {
        this.setState({
            step: "showPassPrompt",
            runAfterPass: this.getAndDecrypt,
            fileName: this.getFileName(),
            mode: "Decrypt"
        });
    }
    showPasswordPrompt() {
        return(
            <PasswordHintPrompt fileName={this.state.fileName} buttonText={this.state.mode} showHint={this.state.mode==="Encrypt"}
                                pass={this.props.drawerPass} hint={this.props.drawerHint}
                                onSetPasswordClicked={(pass,hint) => this.setPasswordClicked(pass,hint)}
                                onCancelClicked={this.cancelPassClicked}/>
        );
    }
    setPasswordClicked(pass:string,hint:string) {
        if(pass==="") {
            Popup.showError(null,"Password can't be blank", () => {
                $("#passBox").focus();
            });
            return;
        }

        // ### this is likely not what we want (to reset drawer password).
        this.props.dispatch(gotPasswordSet(this.props.drawerName,pass,hint)); // set for the drawer
        const runAfterPass = this.state.runAfterPass;
        runAfterPass && runAfterPass(this.props.drawerName, this.state.fileName, pass , hint); // run CB.
    }
    cancelPassClicked() {
        this.goBackToFilesList(false);
    }
    //#endregion

    getAndDecrypt(drawerName, fileName, pass) { // hint ignored
        if(this.state.loadedDataBlob===null) { // file needs to be loaded yet
            this.setState({step: "showLoading"});
            this.props.dispatch(sendDownloadFileRequest(drawerName, fileName, this.updateLoadProgress, this.props.sharing))
            .then(({remoteFileName, dataBlob}) => this.handleFileLoaded(remoteFileName, dataBlob,pass, this.props.noDecrypt))
            .catch((error) => {
                Popup.showError("Download File", error.error, () => {
                    this.props.onRedirectRequest("/dashboard");
                });
            });
        }
        else { // file has already been loaded -- just needs decrypting attempt
            this.handleFileLoaded(fileName, this.state.loadedDataBlob,pass, this.props.noDecrypt);
        }
    }
    
    /**
     * unencrypt the given dataBlob provided noDecrypt is not true.
     * This also saves or shows the result depending on what it's type is foudn to be after decryption.
     * 
     * Note that the remoteFileName is the name of the file as it should be now (it may have been renamed on
     * the remote side and the internal name shoudl now match really in case it's re-encrypted.
     * 
     * */
    handleFileLoaded(remoteFileName, dataBlob,pass,noDecrypt) { 
        //console.debug(`got data blob back (size: ${dataBlob.size} bytes)`);
        this.setState({loadedDataBlob: dataBlob}); // so we know it's been loaded already in case of pass problem

        if(!noDecrypt) { // do the decryption
            this.decryptBlob(dataBlob, pass)
            .then(({fileNameHint, fileTypeHint, decryptedBlob, decryptHint}) => {
                const isLocalFile = dataBlob["isLocalFile"]; // was augmented if loaded locally.
                const fileName = KSUtils.ResetDecryptedFileNameIfNotMatched(remoteFileName, fileNameHint,this.props.sharing); // remote file name vs the one in the encrypted file header.
                
                this.props.dispatch(updateCurrentFile(this.props.drawerName, this.props.drawerDesc, fileName,
                                                        fileTypeHint, decryptedBlob, decryptHint, isLocalFile)); // redux gets the blob.
                this.setState({
                    decryptedFileName: fileName, 
                    decryptedBlob: decryptedBlob, 
                    decryptedFileType: fileTypeHint,
                    decryptHint: decryptHint,
                });
                if(this.props.sharing) { // always save
                    this.setState({step: "askIfSaving"});
                }
                else if(this.props.copying) { // pass blob up to encrypter
                    this.showAskForFileName();
                }
                else { // not sharing...
                    const typeHint = AESEncrypter.convertFileTypeToString(fileTypeHint);
                    if ( typeHint === AESEncrypter.FILE_TYPE_CCARD) { // to card viewer
                        this.viewCard();   // proceed to the text viewer
                    } else if (typeHint === AESEncrypter.FILE_TYPE_PASS) { // to password viewer
                        this.viewPass();   // proceed to the text viewer
                    } else if (typeHint === AESEncrypter.FILE_TYPE_TEXTPAD) { // textpad viewer
                        this.viewTextPad();   // proceed to the text viewer
                    }
                    else { // save by default if no other matches
                        this.setState({step: "askIfSaving"});
                    }
                }
            })
            .catch((error) => {
                if(error && error.startsWith("unsupported header version")) { // nothing user can do about this.
                    Popup.showError("", "This file is corrupt or has an unsupported encryption type.<br/><br/>Details: "+error, () => {
                        this.goBackToFilesList(true);
                    });
                }
                else { // report and try again
                    Popup.showError("", error, () => {
                        this.props.dispatch(gotPasswordSet(this.props.drawerName, "")); // unset password.
                        this.moveStepToPasswordPrompt();
                    });
                }
            });
        }
        else { // just save (downloading raw)
            
            // fake out the saver to think that the blob has been decrypted.
            this.setState({
                decryptedFileName: this.getFileName(), 
                decryptedBlob: dataBlob,        // encrypted data here!
                decryptedFileType: AESDecrypter.FILE_TYPE_NONE,
            });
            this.setState({step: "askIfSaving"});
        }
    }
    decryptBlob(dataBlob,pass) {

        this.setState({
            step: "isDecrypting"
        });

        const promise = new Promise( (resolve, reject) => {

            const handler = new AESDecrypter(pass, 128, (numBytes) => this.updateDecryptProgress(numBytes/dataBlob.size*100));

            const chunkSize = 16 * 4096; // bytes - needs to be in multiples of 16 (enc block sizes)
            this._chunkedOutputFile = new FileHandler.ChunkedFileSaver();
            this._chunkedLoader = new FileHandler.ChunkedFileLoader(dataBlob, chunkSize, (data, error, doneFlag) => {
                if (error) {
                    reject(error);
                }
                if(this._cancelDecryptionFlag) {
                    this.cleanupDecryptionRun();
                    reject("cancelled");
                }
                if (!doneFlag) {
                    try {
                        let decBytes = handler.doDecryptIteration(data);
                        console.log(`loadFileInChunks(): got back ${decBytes.length} decrypted bytes...`);
                        this._chunkedOutputFile.AddChunk(decBytes);
                    } catch (ex) {
                        console.log("ERROR: "+ex);
                        this.cleanupDecryptionRun();
                        if(ex.message.indexOf("bad password")!==-1) { // add a hint
                            const hintMsg = (handler.DecryptHint===""?"no hint given":`hint is: '${handler.DecryptHint}'`);
                            ex.message=`Bad Password (${hintMsg})`;
                        }

                        reject(ex.message);
                    }
                } else { // done decryption!
                    const finalBlob = this._chunkedOutputFile.ReturnFileAsBlob();  // ### entire blob reconstructed here.
                    resolve({fileNameHint: handler.DecryptedFileName, fileTypeHint: handler.DecryptedFileType, decryptedBlob: finalBlob, decryptHint: handler.DecryptHint});
                }
            });
            this._chunkedLoader.Start();
        });
        return (promise);
    }
    cleanupDecryptionRun() {
        this._chunkedOutputFile.Cancel();
        this._chunkedLoader.Cancel();
        this._cancelDecryptionFlag=false; // reset
    }
    cancelDecryptClicked() {
        console.log("cancelling decryption run...");
        this._cancelDecryptionFlag=true;
    }
    
    updateDecryptProgress(percent) {
        this.setState({decryptProgress: percent});
    }
    updateLoadProgress(percent) {
        //console.log("downloading percent: "+percent);
        this.setState({loadingProgress: percent});
    }
    
    viewTextPad() {
        this.props.onRedirectRequest("/viewText");
    }
    saveFile() {
        console.log("saving file (tmp page link)");
        //chunkedOutputFile.SaveFileName=handler.DecryptedFileName; // save as this.
        //chunkedOutputFile.SaveFile(); // output entire file to disk.
        saveFileViaTempPageLink(this.state.decryptedFileName, this.state.decryptedBlob)
        .then( (result) => {
            Popup.show("Saved",result,() => {
                this.goBackToFilesList(true);
            })
        })
    }
    viewCard() {
        this.props.onRedirectRequest("/viewCards");
    }
    viewPass() {
        this.props.onRedirectRequest("/viewPasses");
    }
    
    goBackToFilesList(reloadFilesFlag) {
       this.setState({
           redirectToDashboard: true, reloadFilesFlag: reloadFilesFlag
       });
    }
   
    cancelViewSave() {
        this.goBackToFilesList(false);
    }
    cancelLoadClicked() {
        console.log("cancelling file load...");
        this._cancelLoadFlag=true;
    }
    
    render() {
        
        if(this.state.redirectToDashboard!==false) {
            return <Redirect to={{
                pathname: "/dashboard",
                search: "?d="+this.props.drawerName+"&dn="+this.props.drawerDesc,                    // back to the current drawer
                state: { reloadFiles: this.state.reloadFilesFlag }
            }}/>
        }
       
        return (<div>
                {this.state.step==="showPassPrompt" && this.showPasswordPrompt()} {/* original file */}
                {this.state.step==="showLoading" && this.showLoading()}
                {this.state.step==="askIfSaving" && this.showAskIfSaving()}
                {this.state.step==="isDecrypting" && this.showDecryptingVisual()}
            </div>
        );
    }
}

GetAndDecryptMain.defaultProps = {
    drawerPass: "",
    drawerName: undefined,
    drawerDesc: "",
    fileName: undefined,
    decryptedFileName: undefined,
    decryptedFileType: 0, // unknown
    sharing: false,
    noDecrypt: false,
    copying: false,
};

GetAndDecryptMain.propTypes =  {
    drawerPass: PropTypes.string,
    drawerName: PropTypes.string,
    drawerDesc: PropTypes.string,
    fileName: PropTypes.string,
    decryptedFileName: PropTypes.string,
    decryptedFileType: PropTypes.number,
    onRedirectRequest: PropTypes.func.isRequired,
    sharing: PropTypes.bool,
    noDecrypt: PropTypes.bool,
    copying: PropTypes.bool,

    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    
};

function mapStateToProps(state,ownProps) {
    const ourDrawer = KSReduxUtils.findOurDrawerFromLocation(ownProps.location,state.myStuff);
    const ourFile = KSReduxUtils.findOurFileFromLocation(ownProps.location);

    return {
        drawerPass: ourDrawer?ourDrawer.pass:"",
        drawerHint: ourDrawer?ourDrawer.hint:"",
        drawerName: ourDrawer?ourDrawer.name:undefined,
        drawerDesc: ourDrawer?ourDrawer.desc:"",
        fileName: ourFile
    };
}

export default connect(mapStateToProps,)(GetAndDecryptMain);