import React from 'react';
import {NavLink} from "react-router-dom";

function MainNavBasic(props) {
    return (<div className="sticky-top w-100">
            <nav className="navbar navbar-light bg-light ">
                <div className="">
                    <a className="nav nav-link d-inline ks-display-6 ks-fg-blue pl-0" href="https://lifekeepers.com">LifeKeepers</a>
                    <span className="ks-display-6">SAFE</span></div>
                <NavLink id="signupBTN" to="/register">Sign Up</NavLink>
            </nav>
        </div>);
}

export default MainNavBasic;