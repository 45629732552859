import $ from 'jquery';
import React from 'react';
import Popup from "./Popup";

function PasswordHintPrompt({fileName, buttonText, showHint, pass:inPass, hint:inHint, onSetPasswordClicked, onCancelClicked}) {
    
    function setPasswordClicked(event) {
        stopDefaultActions(event);
        
        const p = $("#passBox").val();
        const h = $("#hintBox").val();
        if(p === "") {
            Popup.showError(null, "Password can't be blank", () => {
                $("#passBox").focus();
            });
            return; 
        }
        
        onSetPasswordClicked(p, h);
    }
    
    function onPassKeyDown(e) {
        if(e.keyCode===0x0d) { // return pressed
            if(showHint) { // tab to this
                $("#hintBox").focus();
                stopDefaultActions(e);
            }
            else { // submit
                setPasswordClicked(e);    
            }
        }
    }

    function onHintKeyDown(e) {
        if(e.keyCode===0x0d) { // return pressed
            setPasswordClicked(e);
        }
    }

    function handlePassHideShow() {
        const p = $("#passBox");
        const eye = $("#eye");
        if(p.prop("type")==="password") { // to text
            p.prop("type","text");
            eye.removeClass("fa-eye");
            eye.addClass("fa-eye-slash"); // show
        }
        else { // to password
            p.prop("type", "password");
            eye.removeClass("fa-eye-slash");
            eye.addClass("fa-eye"); // show
        }
    }
    
    function cancelClicked(event) {
        stopDefaultActions(event);
        onCancelClicked();
    }
    
    function stopDefaultActions(event) {
        event.stopPropagation();
        event.preventDefault();
    }

    return (
        <div className="">
            <form id="passHintPrompt" className="form-inline">
                <div id="ks-login-box">
                    <div className="mb-2">Enter the password to use to <strong>{buttonText==="Encrypt"?"encrypt":"decrypt"}</strong> your file: <br/>
                        <div className="text-center ks-fg-blue m-3 ks-truncate"><i>{fileName}</i></div>
                    </div>
                    
                    {/* this is a hidden field to effectively disable the save password option */}
                    <div className="ks-hidden">
                        <input type="password" autoComplete="new-password"/>
                    </div>

                    <div className="input-group mb-3">
                        <input type="password" id="passBox" placeholder="password" className="form-control mb-2 w-80"
                               autoFocus 
                               maxLength="15" aria-autocomplete="none" autoComplete={"off"} defaultValue={inPass}
                               onKeyDown={(e) => onPassKeyDown(e)}/>
                        <div className="input-group-append mb-2" tabIndex={-1}>
                            <button className="btn btn-primary" tabIndex={-1} type="button" id="showHideBTN" onClick={handlePassHideShow}>
                                <i id="eye" className="fa fa-eye" tabIndex={-1}/>
                            </button>
                        </div>
                    </div>
                    
                    <input type="text" id="hintBox" placeholder="optional hint?" hidden={!showHint}
                           className="form-control mb-3 w-100" maxLength="15" autoComplete="password-hint" 
                           defaultValue={inHint} tabIndex={0}
                           onKeyDown={(e) => onHintKeyDown(e)}/>
                    <div className="row ks-container-row justify-content-between">
                        <button id="cancelBTN" className="btn btn-secondary col-5" tabIndex={0}
                                onClick={(e) => cancelClicked(e)}>
                            Cancel
                        </button>
                        <button id="nextBTN" className="btn btn-primary col-5" tabIndex={0} 
                                onClick={(e) => setPasswordClicked(e)}>
                            {buttonText}
                        </button>
                       
                    </div>
                </div>
            </form>
        </div>
    );
}

export default PasswordHintPrompt;