import React, {Component} from 'react';
import * as PropTypes from "prop-types";
import $ from 'jquery';

class Bubble extends Component {

    constructor(props) {
        super(props);

        this.updatePosition = this.updatePosition.bind(this);
        
        this.state = {
            bubbleTop: 0,
            bubbleLeft: 0,
            bubblePointer: 50,
            relToElement: undefined,
        }
    }
    
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(!this.state.relToElement || prevProps.relativeToId !== this.props.relativeToId) { 
            this.updatePosition();
        }
    }

    componentDidMount() {
        this.updatePosition();
        window.addEventListener('resize', this.updatePosition);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updatePosition);
    }

    updatePosition() {
        const el = this.findElement(this.props.relativeToId);
        if(el) {
            this.setState({relToElement: el})
            const {bubbleTop, bubbleLeft, bubblePointerFromLeft} = this.calcOffsetTo(el, window.innerWidth);
            this.setState({
                bubbleTop: bubbleTop,
                bubbleLeft: bubbleLeft,
                bubblePointer: bubblePointerFromLeft
            });
        }
    }
    
    findElement(theElement) {
        let el = undefined;
        if(theElement.startsWith(".")) { // class search -- take the first one.
            el = $(theElement)[0];
        }
        else { // id search
            el = $("#" + theElement)[0];
        }
        return(el);
    }
    
    showTitle() {
        if (this.props.title && this.props.title !== "") {
            return <div className="title font-weight-bold mb-2">{this.props.title}</div>
        }
    }
    
    calcOffsetTo(el, windowWidth) {
        let bubbleTop = 0;
        let bubbleLeft = 0;
        let pointerFromLeftPct = 50; // percent
        let pointerLeftDiff = 0; // px
        if (el) {
            const pos = el.getBoundingClientRect();
            
            bubbleTop = pos.bottom + 10;
            let bubbleLeftDesired = pos.left + pos.width / 2 - this.props.width / 2;
            
            if(bubbleLeftDesired < 0) { // off left edge
                bubbleLeft = 10; // margin
                pointerLeftDiff = bubbleLeftDesired - bubbleLeft;
            }
            else if( bubbleLeftDesired + this.props.width > windowWidth) { // off right edge
                bubbleLeft = windowWidth - this.props.width - 10;
                pointerLeftDiff = bubbleLeftDesired - bubbleLeft;
            }
            else {
                bubbleLeft = bubbleLeftDesired;
            }
            
            pointerFromLeftPct = (this.props.width/2 + pointerLeftDiff) / this.props.width * 100; // percent
            if(pointerFromLeftPct > 95) { pointerFromLeftPct = 95;}
            if(pointerFromLeftPct < 5) { pointerFromLeftPct = 5;}
        }
        return ({bubbleTop, bubbleLeft, bubblePointerFromLeft: pointerFromLeftPct})
    }
    
    buildContent() {
        return { __html: this.props.content}
    }
    
    render() {

        if(!this.findElement(this.props.relativeToId)) {
            return null;
        }
        
        return (

            <div style={{position: "absolute", top: this.state.bubbleTop + "px", left: this.state.bubbleLeft + "px", width: this.props.width + "px"}}>
                <div id="infoBubble" className="speech-bubble">
                    <span id="infoBubblePointer" className="speech-bubble-pointer" style={{left: this.state.bubblePointer + "%"}}/>
                    {this.showTitle()}
                    <div className="medium" dangerouslySetInnerHTML={this.buildContent()}/>
                    <div className="d-flex justify-content-end mt-2">
                        <div id="infoSkipBTN" className="btn-link ks-pointer align-self-center mr-3" onClick={() => this.props.onSkipClicked()}>skip</div>
                        <button className="btn btn-small ks-bg-blue text-white" onClick={() => this.props.onNextClicked()}>Got it!</button>
                    </div>
                </div>

            </div>
        )
            ;
    }
}

Bubble.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    relativeToId: PropTypes.string.isRequired,  // not required so it can be null
    onSkipClicked: PropTypes.func.isRequired,
    onNextClicked: PropTypes.func.isRequired
};

export default Bubble;