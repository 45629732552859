import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {withCookies} from 'react-cookie';
import * as queryString from "query-string";
import Footer from './MainFooter';
import LoginWidget from "./LoginWidget";
import KSUtils from "../SUPPORT/ks-utils";
import {Redirect} from "react-router";
import Popup from "../COMMON/Popup";
import MainNavBasic from "./MainNavBasic";

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showGuide: false,
        };
    }
    
    componentDidMount() {
        this.checkForRegLinkRedirect();     // show confirm registration?
    }
    
    checkForRegLinkRedirect() {
        const values = this.props.location && queryString.parse(this.props.location.search);
        if(values.reg && values.reg==="true") {
            Popup.showInfo("<p>Your registration has been confirmed!</p>");
        }
    }
    
    render() {
        
        if(KSUtils.isAuthenticated(this.props.cookies)) {
            return(<Redirect to="/dashboard"/>)
        }
        
        return (
            <div>
                
                <MainNavBasic/>
                <div className="container">
                    <LoginWidget location={this.props.location}/>
                </div>
                <Footer atBottom/>
            </div>
        );
    }
}

HomePage.propTypes = {
    location: PropTypes.object.isRequired,
    cookies: PropTypes.object.isRequired,
};


export default withCookies(HomePage);
