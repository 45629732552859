import Popup from "../COMMON/Popup";
import DrawerChooser from "../COMPONENTS/DrawerChooser";
import KSUtils from "./ks-utils";
import KSReduxUtils from "./reduxHelpers";
import {AESEncrypter} from "./AESHandler";
import React from "react";

class FormCommon {
    
    static DoSharePopupWithClipCopy(shareLink) {
        
        // enable copy text to clipboard in this popup...ug.
        function copyTextToClipboard() {
            const textArea = document.getElementById("shareTextInput");
            /* Select the text field */
            textArea.select();
            textArea.setSelectionRange(0, 99999); /*For mobile devices*/

            /* Copy the text inside the text field */
            document.execCommand("copy");

            const copyButton = document.getElementById("copyBTN");
            copyButton.innerText="copied!";
        }

        Popup.show("",
            `<p>The 24 hour link to your shared file is:</p><div><textarea id="shareTextInput" style="width:100%">${shareLink}</textarea>`+
            '<button id="copyBTN">Copy to clipboard</button>' +
            `</div>`+
            '<div style="margin-top:10px">Anyone with the above link will have access to the file, but they will <strong>still need a password</strong> from you to decrypt it.</div>')
        
        document.getElementById("copyBTN").addEventListener('click',copyTextToClipboard); // user feedback
    }
    
    
    static setInitialTextToBlob() {
        if (!this.props.textFileBlob) {
            this.setState({redirectToLink: "/dashboard"});
            return;
        }

        this.isLocalFile = this.props.textFileBlob.isLocalFile;
        if (this.props.textFileBlob.text) { // use newer API
            this.props.textFileBlob.text().then((text) => {
                if (text !== "") {
                    this.setState({
                        textContent: text,
                        fileName: this.props.fileName,  // use original filename
                    });
                }
            });
        } else { // use older readAsText() API
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                if (text !== "") {
                    this.setState({
                        textContent: text,
                        fileName: this.props.fileName,  // use original filename
                    });
                }
            };

            reader.readAsText(this.props.textFileBlob);  // UTF-8 assumed
        }
    }

    static setNewPassword() {
        this.setState({
            showPassPrompt: true,
            mode: "Encrypt"
        })
    }

    handleDoneClicked() {
        this.setState({redirectToDashboard: true});
    }

    handleSaveClicked(self) {
        if (this.newFile || this.isLocalFile) { // ask for new filename
            Popup.showThreeButtonPrompt("File name?", this.state.fileName,
                "Upload", "Save Local", undefined,
                (fileName) => { // upload...  
                    let drawerName = this.props.drawerName;
                    if (this.isLocalFile) {
                        this.askForDestinationDrawerAndUpload(fileName);
                        return;
                    }
                    this.isLocalFile = false;  // mark for upload (NOT LOCAL) save
                    this.setBlobAndSave(fileName, drawerName);
                },
                (fileName) => { // save locally (drawer doesn't matter)
                    this.isLocalFile = true;
                    this.setBlobAndSave(fileName);
                }, "", 1);
            return;
        }
        this.setBlobAndSave(this.state.fileName, this.props.drawerName); // save as old name automatically
    }

    askForDestinationDrawerAndUpload(fileName) {
        this.isLocalFile = false;  // mark for upload (NOT LOCAL) save
        this.setState({
            showDrawerList: true,
            fileName: fileName,
        });
    }

    showDrawerList() {
        const fileName = this.state.fileName;

        return (
            <div className="container">
                <div className="container mt-3 text-muted">
                    Choose a new drawer for:
                    <div className="text-center mt-2"><strong>{fileName}</strong></div>
                </div>
                <DrawerChooser noDeco drawerList={this.props.myStuff.drawers}
                               onDrawerClicked={(e, newDrawer) => this.handleDrawerClicked(e, newDrawer)}/>
            </div>
        );
    }

    handleDrawerClicked(event, drawer) {
        this.setState({showDrawerList: false});
        this.setBlobAndSave(this.state.fileName, drawer.name);  // drawer number
    }

    setBlobAndSave(fileName, drawerName = KSUtils.MAIN_DRAWER_MAGIC) {
        // build and setup our blob 
        let tmpFile = new Blob([this.state.textContent]);
        tmpFile = KSReduxUtils.augmentFileBlobWithTypeString(tmpFile, fileName, AESEncrypter.FILE_TYPE_TEXTPAD);
        // if(this.isLocalFile) { // need to save back locally
        //     this.saveLocalFile(fileName, tmpFile); // ### to save unecrypted version to disk....
        // }
        // else { // upload
        this.addToUploadList(tmpFile, drawerName, fileName, this.pass, this.hint, this.isLocalFile);
        //}

        this.setState({fileName: fileName}); // update in case of saveas.
    }

    // saveLocalFile(fileName, tempFile) {
    //     saveFileViaTempPageLink(fileName, tempFile)
    //         .then( (result) => {
    //             Popup.show("Saved",result);
    //         })
    // }
}

export default FormCommon;