import React, {Component} from 'react';

import MainNavbar from './MainNavbar';
import Footer from './MainFooter';

class HelpPage extends Component {
    
    render() {
        return (
            <div>
                <MainNavbar showHome/>
                <div className="container">
                    
                    <h3 className="ks-fg-blue">Main Drawer List Page</h3>
                    <h4 className="mt-3"><i className="fa fa-share mr-2"/>Quick-Share</h4>
                    <p>Use this to quickly select and encrypt a normal, everyday file from your device and get a share link back to it for sending to somebody.<br/>
                        The link can be copied to your clipboard and easily sent to somebody by text, email, and chat.</p>
                    <p>The resulting link is good for 24 hours, after which the link and your uploaded encrypted file will be removed from the system.</p>

                    <h4 className="mt-3"><i className="fa fa-pencil mr-2"/>Local Encrypted File</h4>
                    <p>You can use the <strong><i className="fa fa-archive mr-1"/>To Local Drive</strong> menu item on any file to get a raw, encrypted copy of it down from the cloud.  Viewing, editing, or using the file in its
                        raw form is essentially useless.  Using the Local Encrypted File button, you can select this local, encrypted file and have the system decrypt it for you.  If it is a 
                        LifeKeepers password, credit card, or textpad form file, it will be opened in the appropriate form.</p>
                    <p>
                        You can also use this function to edit, re-encrypt and save the file either locally or upload it to the cloud.  Lots of options for your locally backed up, encrypted files. 
                    </p>

                    <h3 className="ks-fg-blue">File Menu Items</h3>
                    <h4 className="mt-3"><i className="fa fa-copy mr-2"/>Copy</h4>
                    <p>Make a copy of the file.  The original file will be downloaded and decrypted (you will be asked for the decryption password if it is needed).</p>
                    <p>The system will then ask you for a name for the new copy as well as the encryption password to use for the copy.  
                        After encrypting the copy, it is uploaded back up to same drawer as the original.</p>
                    <p>You can move the copy using the <strong><i className="fa fa-arrow-left mr-1"/>Move</strong> function on the file menu.</p>
                    <h4 className="mt-3"><i className="fa fa-arrow-left mr-2"/>Move</h4>
                    <p>Move the file to another drawer.  As the name suggests, this provides a simple way to move the encrypted, cloud based file to another drawer.  No decryption is performed and the file
                    is untouched otherwise.</p><p>You will be asked for the file's destination drawer -- create a new drawer if needed by using the "<strong>+</strong>" icon on the main drawer page.</p>
                    <h4 className="mt-3"><i className="fa fa-circle-o mr-2"/>Rename</h4>
                    <p>To change the name of the encrypted file where it sits in the cloud.  This does not perform any decryption on the file, changing only the name that appears in your files list.</p>
                    <p><strong className="ks-fg-blue">NOTE:</strong> the real name of the file at the original time of encryption is embedded in the encryted file bundle and is not changed, so downloading and decrypting this file 
                    will still result in its original name being used for saving at that time.</p>
                    <h4 className="mt-3"><i className="fa fa-trash mr-2"/>Delete</h4>
                    <p>Remove the file from the cloud store.</p>
                    <p><strong className="ks-fg-blue">NOTE:</strong> there is <strong>no undo function</strong> for this and it is immediately and forever removed from the system.</p>
                    <h4 className="mt-3"><i className="fa fa-share mr-2"/>Share </h4>
                    <p>Make this file shareable for a period of 24 hours.  A shareable link will be generated by the system, which can be emailed, texted, or otherwise sent to people that you wish to
                    be able to download the encrypted file.  The system can optionally send emails out to a list of users on your behalf, but -- like with the Quick-Share option -- you are 
                        free to generate a link and send it out in a more personal message using whatever method you prefer.</p>
                    <p>People who you share the link with <i>do not need</i> to be registered with LifeKeepers.com to use the link.</p>
                    <p><strong className="ks-fg-blue">NOTE:</strong> the people who download the file will still need the decryption password before being able to view it.  If you need to change the password before sharing the file, consider
                        <strong> <i className="fa fa-copy mr-1"/>Making A Copy</strong> of it with a different password and sharing that one.</p>
                    <h4 className="mt-3"><i className="fa fa-download mr-2"/>To Local Drive</h4>
                    <p>Use this to download a raw, encrypted version of the file to your local device.  This file is an exact copy of the encrypted one in the cloud and can be kept
                    either as a backup, or used locally with the <strong><i className="fa fa-pencil mr-1"/>Edit Local Encrypted</strong> function, outlined above.</p>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default HelpPage;
