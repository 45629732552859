import * as types from './actionTypes';
import clientApi from '../API/mockClientApi';

// AJAX HANDLERS (GENERIC)
function beginAjaxCall() {
    return { type: types.BEGIN_AJAX_CALL };
}
function endAjaxCall() {
    return{ type: types.ERRORED_AJAX_CALL };
}

// CURRENT USER
export function gotLoginSuccess(userInfo) {
    return { type: types.SEND_SIGNIN_SUCCESS, userInfo};
}
function gotLogoutSuccess() {
    return { type: types.SEND_SIGNOUT_SUCCESS };
}

function gotGuidanceSuccess(guidanceList) {
    return { type: types.GOT_GUIDANCE_SUCCESS, guidanceList };
}

// FILE HANDLING
function gotDrawerListSuccess(parentDrawer, drawerInfo) {
    return { type: types.GOT_DRAWERINFO_SUCCESS, parentDrawer: parentDrawer, drawerInfo: drawerInfo};
}

function gotDeleteFileSuccess(drawerName, fileName) {
    return { type: types.SEND_DELETEFILE_SUCCESS, drawerName, fileName};
}

function gotRenameFileSuccess(drawerName, fileName, newName) {
    return { type: types.GOT_RENAMEITEM_SUCCESS, drawerName, fileName, newName};
}

function gotMoveFileSuccess(oldDrawerName, newDrawerName, newDrawerNumber, fileName, fileSize, fileDate) {
    return { type: types.GOT_MOVEITEM_SUCCESS, oldDrawerName, newDrawerName, newDrawerNumber, fileName, fileSize,fileDate};
}

export function gotAddFileSuccess(drawerName, fileName, fileSize, fileDate) {
    return { type: types.ADD_FILE_TO_DRAWER_SUCCESS, drawerName, fileName, fileSize,fileDate};
}

function gotAddDrawerSuccess(drawerNumber, drawerDesc) {
    return { type: types.SEND_ADD_DRAWER_SUCCESS, drawerNumber, drawerDesc};
}

/* action called when user has typed in a password for a drawer */
export function gotPasswordSet(drawer,password,hint) {
    return { type: types.GOT_DRAWER_PASSWORD, drawer:drawer, password:password, hint:hint};
}

/* update the store's current drawer to this */
export function setCurrentDrawer(drawerName,drawerDesc) {
    return { type: types.SET_CURRENT_DRAWER, drawerName, drawerDesc}
}

/* update the store info used for encrypt/decrypt of the currently selected file 
*  NOTE: filetype here needs to be the NUMBER version (ie. 3!) */
export function updateCurrentFile(drawerName, drawerDesc, fileName, fileType, dataBlob, decryptHint, isLocalFile) {
    return { type: types.SET_FILE_BLOB, drawerName, drawerDesc, fileName, fileType, dataBlob, decryptHint, isLocalFile};
}

/** set the sort ordering type for files
 * 
 */
export function setFileSortOrderTo(sortType, curDrawerNumber) {
    return { type: types.SET_FILE_SORT_ORDER, sortType, curDrawerNumber}
}

/** set the sort ordering type for files
 *
 */
export function setDrawerSortOrderTo(sortType) {
    return { type: types.SET_DRAWER_SORT_ORDER, sortType}
}

/* update the store info that holds the list of files waiting for encrypt and 
   upload.
 */
export function addToFileUploadList(drawerName, fileInfo, pass, hint, isLocalFile) {
    return { type: types.ADD_TO_FILE_UPLOAD_LIST, drawerName, fileInfo, pass, hint, isLocalFile };
}
/* update the store info that holds the list of files waiting for encrypt and 
   upload.
 */
export function removeFromFileUploadList(drawerName, fileName) {
    return { type: types.REMOVE_FROM_FILE_UPLOAD_LIST, drawerName, fileName };
}

export function finishFileUpload(drawerName, fileName,error) {
    return { type: types.FINISH_FILE_IN_UPLOAD_LIST, drawerName, fileName, error };
}

export function removeFinishedFromFileUploadList() {
    return { type: types.REMOVE_FINISHED_IN_UPLOAD_LIST};
}


// GENERAL DISPATCH HANDLERS
export function clearStoreOnLogout() {
    return({type: types.CLEAR_STATE_ON_LOGOUT});
}
export function copyToStoreOnStartup(userInfo) {
    return { type: types.COPY_TO_STORE_ON_STARTUP, userInfo};
}

// HANDLERS FOR INTERACTING WITH WEBSERVICE

export function sendGetFileListRequest(drawerNumber) {
    return(function (dispatch) {
        dispatch(beginAjaxCall());  // NOTE the () here!
        return(
            clientApi.getFileList(drawerNumber).then( (response) => {
                dispatch(gotDrawerListSuccess(response.parentDrawer, response.drawerInfo));
            }).catch( (error) => {
                dispatch(endAjaxCall(error));
                throw (error);
            })
        );
    });
}

export function sendLoginRequest(email,pass) {
    return(function (dispatch) {
        dispatch(beginAjaxCall());  // NOTE the () here!
        return(
            clientApi.sendLoginRequest(email,pass).then( (userInfo) => {
                dispatch(gotLoginSuccess(userInfo));
                return(userInfo); // for other consumers
            }).catch( (error) => {
                dispatch(endAjaxCall(error));
                throw (error);
            })
        );
    });
}

export function sendLogoutRequest() {
    return(function (dispatch) {
        dispatch(beginAjaxCall());  // NOTE the () here!
        return(
            clientApi.sendLogoutRequest().then( (response) => {
                dispatch(gotLogoutSuccess());
                dispatch(clearStoreOnLogout());
                return(response); // for other consumers
            }).catch( (error) => {
                dispatch(endAjaxCall(error));
                throw (error);
            })
        );
    });
}

export function getNewGuidance(fromTimeStamp) {
    return(function (dispatch) {
        dispatch(beginAjaxCall());  // NOTE the () here!
        return (
            clientApi.sendGuidanceRequest(fromTimeStamp)
                .then(({guidanceList}) => {
                    dispatch(gotGuidanceSuccess(guidanceList));
                }).catch((error) => {
                    dispatch(endAjaxCall(error));
                    throw (error);
                })
        );
    });
}

export function clearGuideList() {
    return { type: types.CLEAR_GUIDE_LIST };
}

export function sendDownloadFileRequest(drawerName, fileName, onProgressUpdate, sharedFile=false) {
        return(function (dispatch) {
            dispatch(beginAjaxCall());  // NOTE the () here!
            return(
                clientApi.downloadFile(drawerName,fileName,onProgressUpdate, sharedFile).then( ({remoteFileName,dataBlob}) => {
                    dispatch(endAjaxCall(null)); // just to stop the loader
                    return({remoteFileName, dataBlob});
                }).catch( (error) => {
                    dispatch(endAjaxCall(error));
                    throw (error);
                })
            );
        });
}

export function sendDeleteFileRequest(drawerName, fileName) {
    return(function (dispatch) {
        dispatch(beginAjaxCall());  // NOTE the () here!
        return(
            clientApi.deleteFile(drawerName,fileName).then( () => {
                dispatch(gotDeleteFileSuccess(drawerName,fileName));
            }).catch( (error) => {
                dispatch(endAjaxCall(error));
                throw (error);
            })
        );
    });
}

export function sendRenameFileRequest(drawerName, fileName,newName) {
    return(function (dispatch) {
        dispatch(beginAjaxCall());  // NOTE the () here!
        return(
            clientApi.renameItem(drawerName,fileName,newName).then( ({drawerNumber,fileName,newName}) => {
                dispatch(gotRenameFileSuccess(drawerNumber,fileName,newName));
            }).catch( (error) => {
                dispatch(endAjaxCall(error));
                throw (error);
            })
        );
    });
}

export function sendMoveFileRequest(oldDrawerName, newDrawerName,fileName) {
    return(function (dispatch) {
        dispatch(beginAjaxCall());  // NOTE the () here!
        return(
            clientApi.moveItem(oldDrawerName,newDrawerName,fileName).then( (response) => {
                dispatch(gotMoveFileSuccess(response.oldDrawerName,response.newDrawerName,response.newDrawerNumber,
                    response.name,response.size, response.date));
                return(response);
            }).catch( (error) => {
                dispatch(endAjaxCall(error));
                throw (error);
            })
        );
    });
}

export function sendAddDrawerRequest(drawerName) {
    return(function (dispatch) {
        dispatch(beginAjaxCall());  // NOTE the () here!
        return(
            clientApi.addDrawer(drawerName).then( ({drawerNumber, drawerDesc}) => {
                dispatch(gotAddDrawerSuccess(drawerNumber, drawerDesc));
            }).catch( (error) => {
                dispatch(endAjaxCall(error));
                throw (error);
            })
        );
    });
}
