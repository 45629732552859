import $ from 'jquery';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import MainFooter from "./MainFooter";
import MainNavbar from "./MainNavbar";
import Popup from "../COMMON/Popup";
import {Redirect} from "react-router";
import {connect} from "react-redux";
import {sendMoveFileRequest} from "../ACTIONS/clientActions";
import DrawerChooser from "./DrawerChooser";

class DoMoveFilePage extends Component {

    constructor(props) {
        super(props);

        this.handleDrawerClicked = this.handleDrawerClicked.bind(this);
        this.goBack = this.goBack.bind(this);

        const values = this.props.location && queryString.parse(this.props.location.search);
        this.oldDrawerName = values.d;
        this.fileName = values.fn;

        this.state = {
            redirectToDashboard: false
        }
    }
    
    componentDidMount(): void {
        this.scrollToTop();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        this.scrollToTop();
    }
    goBack() {
        this.props.history.go(-1);
    }

    handleDrawerClicked(e, newDrawer) {
        e.stopPropagation();
        // the "name" is the drawer's 'number' actually
        this.props.dispatch(sendMoveFileRequest(this.oldDrawerName, newDrawer.name,this.fileName))
            .then( (moveInfo) => {
                Popup.show("",
                    `<p>Your file has been moved to '${moveInfo.newDrawerName}'.</p>`,
                    () => this.goBack());
            })
            .catch( error => {
                Popup.showError("Error",error.error,() => this.goBack());
            } );
    }
    
    scrollToTop() {
        const firstDrawer = $("#drawerList li:first");
        if(firstDrawer && firstDrawer[0]) {
            firstDrawer[0].scrollIntoView({block: "center", behavior: "smooth", inline: "nearest"});
        }
    }
    
    render() {

        if (this.state.redirectToDashboard !== false) {
            return <Redirect to={{
                pathname: "/dashboard",
                search: "?d=" + this.drawerName,                    // back to the current drawer
                state: {reloadFiles: false}
            }}/>
        }

        return (<div>
            <MainNavbar/>

            <div className="container mt-3 bg-warning rounded">
                Choose a new remote drawer for:
                <div className="text-center mt-2"><strong>{this.fileName}</strong></div>
            </div>
            <div className="container">
                <DrawerChooser noDeco drawerList={this.props.drawers}
                               onDrawerClicked={(e, newDrawer) => this.handleDrawerClicked(e, newDrawer)}/>
                               
               <div className="row justify-content-around">
                <button id="drawerChooseCancel" className="btn btn-secondary w-25" onClick={() => this.goBack()}>Cancel</button>
               </div>
            </div>
            <MainFooter/>
        </div>);
    }
}

DoMoveFilePage.propTypes = {
    drawers: PropTypes.array,
    loading: PropTypes.bool,
    
    dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        loading: state.ajaxCallsInProgress > 0,
        drawers: state.myStuff ? state.myStuff.drawers : [],
    };
}

export default connect(mapStateToProps)(DoMoveFilePage);