import React, {Component} from 'react';
import { KS_VERSION }from '../SUPPORT/version';
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import Popup from "../COMMON/Popup";
import ErrorPopup from "../COMMON/ErrorPopup";

class MainFooter extends Component {
    
    constructor(props) {
        super(props);
        this.footerClass = "clearfix p-2 ks-bg-blue " + (this.props.atBottom?"at-bottom":"");
    }
    
    render() {
        return (
            <div>
                <footer className={this.footerClass}>
                    <div className="col-12 text-center">
                        <p className="small text-white mb-0 pb-0">Copyright &copy;2020 BITForces Inc
                            <span className="rw-xsmall ml-3">v{KS_VERSION}</span>
                        </p>
                        <a className="badge badge-pill bg-white mr-2" tabIndex={100} href={process.env.PUBLIC_URL + '/termsofuse.html'} target="_ksExtra">Terms of Use</a>
                        <a className="badge badge-pill bg-white mr-2" tabIndex={101} href={process.env.PUBLIC_URL + '/privacy.html'} target="_ksExtra">PrivacyStatement</a>
                    </div>
                   
                </footer>
                <Popup/>
                <ErrorPopup/>
            </div>
                );
    }
}

MainFooter.propTypes = {
  serviceVersion: PropTypes.string.isRequired,  
    atBottom: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {

    return {
        serviceVersion: state.serverInfo ? state.serverInfo.serviceVersion : "-", 
    };
}

export default connect(mapStateToProps)(MainFooter);
