import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function guidanceReducer(state = initialState.guideList, action) {

    switch(action.type) {

        case types.GOT_GUIDANCE_SUCCESS: {
            const guideList = action.guidanceList;
            const newState = [...guideList]; 
            return (newState); 
        }
        case types.CLEAR_GUIDE_LIST: {
            return([]); // no guide list.
        }
        default:
            return(state); // IMPORTANT!
    }
}