import React, {Component} from 'react';
import { withCookies } from 'react-cookie';
import { Switch,
    Route} from 'react-router'
import { withRouter } from "react-router";
import * as PropTypes from 'prop-types';
import {connect} from "react-redux";
import HomePage from "./COMPONENTS/HomePage";
import HelpPage from "./COMPONENTS/HelpPage";
import FeedbackPage from "./COMPONENTS/FeedbackPage";
import ProtectedRoute from "./COMMON/ProtectedRoute";
import DashboardPage from "./COMPONENTS/DashboardPage";

import SignoutPage from "./COMPONENTS/SignoutPage";
import GetAndDecryptPage from "./COMPONENTS/GetAndDecryptPage";
import ViewTextFilePage from "./COMPONENTS/TEXTPAD_FORM/ViewTextFilePage";
import RegisterPage from "./COMPONENTS/RegisterPage";
import GetShareFilePage from "./COMPONENTS/GetShareFilePage";
import DoShareFilePage from "./COMPONENTS/DoShareFilePage";
import {setupClientInfo} from './SUPPORT/setup';
import KSUtils from "./SUPPORT/ks-utils";
import ViewCardsPage from "./COMPONENTS/CARD_FORM/ViewCardsPage";
import ViewPasswordsPage from "./COMPONENTS/PASS_FORM/ViewPasswordsPage";
import DoMoveFilePage from "./COMPONENTS/DoMoveFilePage";
import ResetPassPage from "./COMPONENTS/ResetPassPage";
import EncAndUploadWidget from "./COMPONENTS/EncAndUploadWidget";
import {gotAddFileSuccess, gotPasswordSet} from "./ACTIONS/clientActions";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pass: "",
            hint: "",
        }
    }
    componentDidMount() {
        setupClientInfo(this.props.cookies, this.props.dispatch);
        this.startAuthCheckTimer(this.props.cookies);
    }
    
    startAuthCheckTimer(cookies) {
        setInterval(() => {
            let expires = cookies.get('expires');
            if(expires && expires!=="") {
                if (!KSUtils.isAuthenticated(cookies)) { // kick back to login page
                    this.props.history.push('/signout'); // clears 'expires' too.
                }
            }
        },120000); // check every 2 mins
    }

    doneUploadOfFile({drawerName,fileName,remoteFileName, fileSize, fileDate,error}) {
        if (!error) { // not done due to an error
            this.props.dispatch(gotAddFileSuccess(drawerName, remoteFileName, fileSize, fileDate)); // add to drawer visual
        }
        else { // error
            this.props.dispatch(gotPasswordSet(drawerName,"")); // may be password related?
        }
    }

    doneAllFileUploads() {
        
    }
    
    render() {
        return (<div>
                <Switch>

                    <Route path="/" exact component={HomePage}/>
                    <Route path="/help" component={HelpPage}/>
                    <Route path="/feedback" component={FeedbackPage}/>
                    <Route path="/signout" component={SignoutPage}/>
                    <Route path="/register" component={RegisterPage}/>
                    <Route path="/share" component={GetShareFilePage}/>
                    <Route path="/resetPass" component={ResetPassPage}/>

                    <ProtectedRoute path="/dashboard" component={DashboardPage} cookies={this.props.cookies}/>
                    <ProtectedRoute path="/getAndDecrypt" component={GetAndDecryptPage} cookies={this.props.cookies}/>
                    <ProtectedRoute path="/viewText" component={ViewTextFilePage} cookies={this.props.cookies}/>
                    <ProtectedRoute path="/viewCards" component={ViewCardsPage} cookies={this.props.cookies}/>
                    <ProtectedRoute path="/viewPasses" component={ViewPasswordsPage} cookies={this.props.cookies}/>
                    <ProtectedRoute path="/doShareFile" component={DoShareFilePage} cookies={this.props.cookies}/>
                    <ProtectedRoute path="/doMoveFile" component={DoMoveFilePage} cookies={this.props.cookies}/>

                    {/* FALL THROUGH */}
                    <Route component={HomePage}/>
                </Switch>
                <EncAndUploadWidget pass={this.state.pass} hint={this.state.hint}
                                    onDoneUploadOfFile={(fileDetails) => this.doneUploadOfFile(fileDetails)} onDoneAllFiles={this.doneAllFileUploads}/>
            </div>

        );
    }
}

App.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default withRouter(withCookies(connect()(App)));
