import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function userReducer(state = initialState.currentUserInfo, action) {

    switch(action.type) {
        
        case types.SEND_SIGNIN_SUCCESS: {
            const newState = Object.assign({}, state, action.userInfo);
            return (newState); // mix in our new token
        }
        case types.SEND_SIGNOUT_SUCCESS: {
            const newState = Object.assign({}, state, {expires: 0});
            return (newState); // mix in our new token
        }
        case types.COPY_TO_STORE_ON_STARTUP: {
            const fullName = action.userInfo.fullName;
            const userName = action.userInfo.userName;
            const expires = action.userInfo.expires;
            
            const newState = Object.assign({}, state, {fullName, userName,expires});
            return (newState); // mix in our new token
        }
        default:
            return(state); // IMPORTANT!
    }
}