import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../REDUCERS/index';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';

function configureStore(initialState){
    
    const reduxInvariant = reduxImmutableStateInvariant({
        ignore: [
             'uploadFiles' // upload files lastModifiedDate gets reported as immutable issue (it's not).
         ]
    });
    
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
            rootReducer,
            initialState, composeEnhancers(
                applyMiddleware(thunk,reduxInvariant)
        ));
       
    return(store);
}

export let store = configureStore();