import $ from 'jquery';
import React, {Component} from "react";
import Popup from "../COMMON/Popup";
import {AESEncrypter} from "../SUPPORT/AESHandler";
import FileProgress from "../COMMON/FileProgress";

// Import your worker 
// noinspection ES6CheckImport
import EncWorker from 'workerize-loader!./EncWorker'; // eslint-disable-line import/no-webpack-loader-syntax
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {finishFileUpload, removeFinishedFromFileUploadList} from "../ACTIONS/clientActions";
import {saveFileViaTempPageLink} from "../SUPPORT/fileHandlers";

const MAX_FILE_SIZE_ALLOWED = 100*1024*1024;     // in bytes
const MAX_FILE_SIZE_ALLOWED_MBS = 100;           // in MBs

class EncAndUploadWidget extends Component {
    
    constructor(props) {
        super(props);
        
        this.encryptAndUpload = this.encryptAndUpload.bind(this);
        this.updateEncryptProgress = this.updateEncryptProgress.bind(this);
        this.cleanupEncryptionRun = this.cleanupEncryptionRun.bind(this);
        this.cancelEncryptUploadClicked = this.cancelEncryptUploadClicked.bind(this);
        this.updateUploadProgress = this.updateUploadProgress.bind(this);
        this.hideLoadBox = this.hideLoadBox.bind(this);
        this.updateFilesList = this.updateFilesList.bind(this);
        this.showUploadBox = this.showUploadBox.bind(this);
        this.toggleLoadBoxMinMax = this.toggleLoadBoxMinMax.bind(this);

        this.workerList = [];  // list of our webworkers and their files.
        
        this.state = {
            encryptProgressList: [],    // percent done of what mode the file is in.
            modeList: [],               // whether file is being encrypted or uploading currently
            numFilesRemaining: 0,
            showUploadBox: false,
            showUploadContents: true    // not minimized
        };
    }
    
    componentDidMount(): void {
        this.props.uploadFiles.forEach( item => {
            this.startUploadForFile(item);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot): void {

        let numFilesRemaining = this.state.numFilesRemaining;
        this.props.uploadFiles.forEach( item => {
            if(!prevProps.uploadFiles.find( item2 => item === item2)) { // new file ... start the job for it
                this.startUploadForFile(item);
                numFilesRemaining++;
            }
        });
        
        if(numFilesRemaining!==this.state.numFilesRemaining) { // we've added files -- update state
            this.setState({numFilesRemaining: numFilesRemaining});
        }
    }
    
    startUploadForFile(item) {
        this.showUploadBox(true);
        this.setModeForFile(item.fileInfo.name,"encrypt");  // start in encrypt mode
        this.encryptAndUpload(item.drawerName, [item.fileInfo], item.pass, item.hint, item.fileInfo.isLocalFile);
    }

    filesList() {
        if(!this.props.uploadFiles || this.props.uploadFiles.length===0) {
            return <div>No files to encrypt</div>
        }
        
        let files = this.props.uploadFiles.map( (item,index) => {
            return <FileProgress key={index} id={index} fileName={item.fileInfo.name} 
                                 percent={this.state.encryptProgressList[item.fileInfo.name]}
                                 mode={this.state.modeList[item.fileInfo.name]}
                                 isFinished={item.isFinished} errorText={item.errorText}
                                 onCancelClick={() => this.cancelEncryptUploadClicked(item)}/>
        });

        return(
            <ul className="list-group">
                {files}
            </ul>
        );
    }

    encryptAndUpload(drawerName, fileInfo, pass, hint, isLocalFile) {
        if(!fileInfo || fileInfo.length===0) {
            return;
        }

        const fileType = (fileInfo[0].fileType?fileInfo[0].fileType: AESEncrypter.FILE_TYPE_BINARY); // augmented!
        const selectedFile:File = fileInfo[0];
        const fileSize = selectedFile.size;
        if(fileSize > MAX_FILE_SIZE_ALLOWED) {
            Popup.showError("Upload Error",`File size of '${selectedFile.name}' is greater than ${MAX_FILE_SIZE_ALLOWED_MBS}MB...ignoring.`);
            this.finishedJob({drawerName:drawerName, fileName:selectedFile.name},"file > "+MAX_FILE_SIZE_ALLOWED_MBS+"MB");
            return;
        }

        const myWorker = new EncWorker(); // loaded from external .JS
        const params = {
            file: selectedFile,
            outName: selectedFile.name,
            drawerName: drawerName,
            pass: pass,
            hint: hint,
            fileType: fileType,
            isLocalFile: isLocalFile
        };

        // handle messages coming from the webworker thread
        const us = this; // we need to be able to access *this* from inside worker onmessage handler scope.
        myWorker.onmessage = function(e) {
            if(e.data && e.data.type==="RPC") { // ignore Worker status update
                return;
            }
            if(e.error) { // code problem in worker
                Popup.showError("Worker Error",e.error);
                return;
            }

            let info = e.data;
            if(info.isError) {
                Popup.showError("Encryption Error",info.error);
                us.finishedJob(info,info.error); // displays error in box.
                us.removeWorkerFromList(this);
                return;
            }
            if(info.uploadUpdate) {
                us.updateUploadProgress(selectedFile, info.uploadUpdate);
            }
            else if(info.encryptUpdate) {
                us.updateEncryptProgress(selectedFile, info.encryptUpdate);
            }
            else if(info.isFinished) {
                if(info.isLocalFile) { // we need to do the save since the worker has no access to the DOM
                    saveFileViaTempPageLink(info.remoteFileName,info.encryptedBlob)
                        .then(us.finishedJob(info))
                        .catch( (error) => {
                            Popup.showError("Save Error",error);
                        });
                }
                else {
                    us.finishedJob(info);
                }
            }
            else {
                console.log(`From Worker for file ${selectedFile.name}: ${info}`);
            }
        };

        this.addWorkerToList(myWorker,params);
        myWorker.postMessage(params); // start the job
    }
    
    addWorkerToList(worker, params) {
        let workerList = this.workerList; // just instance global
        workerList.push({worker,params});
    }
    removeWorkerFromList(worker) {
        worker.terminate(); // stop the worker thread.
        this.workerList = this.workerList.filter( (w) => w !== worker);
        worker=null;
    }

    finishedJob(doneInfo, error:string=undefined) {
        
        this.updateFilesList(doneInfo.drawerName, doneInfo.fileName, error);
        
        if(doneInfo.isLocalFile) { // don't update the parent if this is a local file -- no upload done.
            return;
        }
        
        // let parent know we have one done
        if(this.props.onDoneUploadOfFile) {
            this.props.onDoneUploadOfFile({
                drawerName: doneInfo.drawerName,
                fileName: doneInfo.fileName,
                remoteFileName: doneInfo.remoteFileName,
                quickLink: doneInfo.quickLink,
                fileSize: doneInfo.fileSize,
                fileDate: doneInfo.fileDate,
                error: error
            })
        }
    }
    
    updateFilesList(drawerName, fileName, error) {
        this.props.dispatch(finishFileUpload(drawerName,fileName,error)); // mark as done in redux
        
        let numFilesRemaining = this.state.numFilesRemaining - 1;
        this.setState({numFilesRemaining: numFilesRemaining});
        // are we completely done?
        if(numFilesRemaining===0 && this.props.onDoneAllFiles) {
            this.props.onDoneAllFiles(); // tell parent
            this.startMinimizeTimer();
        }
    }
    
    startMinimizeTimer() {
        setTimeout(() => {
            this.minMaxUploadBoxBody(false); // hide
        }, 5000)
    }
    
    cleanupEncryptionRun() {
        this._chunkedOutputFile.Cancel();
        this._chunkedLoader.Cancel();
        this._cancelEncryptionFlag=false; // reset
    }

    updateEncryptProgress(fileInfo, percent) {
        // we always start in encrypt mode
        let progList = this.state.encryptProgressList;
        progList[fileInfo.name] = percent;
        this.setState({encryptProgressList: progList}); // update
    }
    updateUploadProgress(fileInfo, percent) {
        if(this.state.modeList[fileInfo.name]!=="load") { // ensure our mode is correct.
           this.setModeForFile(fileInfo.name,"load");
        }
        let progList = this.state.encryptProgressList;
        progList[fileInfo.name] = percent;
        this.setState({encryptProgressList: progList}); // update
    }
    
    setModeForFile(fileName,mode) {
        let modeList = this.state.modeList;
        modeList[fileName]=mode;
        this.setState({modeList: modeList});
    }

    cancelEncryptUploadClicked(item) {
        console.log(`cancelling encrypt/upload for ${item.fileInfo.name}...`);
        const workerItem = this.workerList.find( (w)=> w.params.file === item.fileInfo);
        if(workerItem.worker) {
            this.removeWorkerFromList(workerItem.worker)
        }
        this.updateFilesList(item.drawerName, item.fileInfo.name, "cancelled");
    }
    
    hideLoadBox() {
        this.showUploadBox(false);
        
        // remove all the items uploads that are done.
        this.props.dispatch(removeFinishedFromFileUploadList()); // kill from redux upload list
    }
    
    showUploadBox(showFlag) {
        this.setState({ showUploadBox: showFlag});
        this.minMaxUploadBoxBody(showFlag);
    }
    
    toggleLoadBoxMinMax() {
        let show = this.state.showUploadContents;
        this.setState({showUploadContents: !show});
        $("#uploadBoxBody").collapse(!show?"show":"hide");
    }
    
    minMaxUploadBoxBody(showFlag) {
        this.setState({ showUploadContents: showFlag});
        $("#uploadBoxBody").collapse(showFlag?"show":"hide");
    }
    
    render() {
        
        let minMaxIcon = "fa fa-caret-down";
        if(!this.state.showUploadContents) {
            minMaxIcon = "fa fa-caret-up";
        }
        
        const hideBoxClass = this.state.showUploadBox?"ks-zindex-top":"fade ks-zindex-bottom";
        
        return (
            <div id="uploaderBox" className={"card fixed-bottom ml-auto mr-2 mb-2 ks-corner-box "+hideBoxClass} >
                <div className="card-header bg-dark text-white m-0 p-0">
                    <div className="row justify-content-between mx-2 my-0 p-0">
                        <div>File Uploads</div>
                        <div>
                            <div className="btn btn-sm btn-dark" onClick={this.toggleLoadBoxMinMax}>
                                <i className={minMaxIcon}/></div>
                            <div id="uploadBoxCloseBTN" className="btn btn-sm btn-dark" onClick={this.hideLoadBox}>
                                <i className="fa fa-close"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="uploadBoxBody" className={"card-body p-2 collapse show"}>
                    {this.filesList()}
                </div>
                
            </div>
        );
    }
}

EncAndUploadWidget.defaultProps = {
    drawerName: "",
    encryptFileInfo: undefined,
    dispatch: PropTypes.func.isRequired,
    onDoneAllFiles: PropTypes.func.isRequired,
};

function mapStateToProps(state,ownProps) {
    return {
        uploadFiles: state.uploadFiles,
    };
}

export default connect(mapStateToProps,)(EncAndUploadWidget);
