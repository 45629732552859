import React, {Component} from 'react';
import * as PropTypes from 'prop-types';

import ksLogo from '../IMAGES/blueLockHeart.png'; // Tell Webpack this JS file uses this image

import {withCookies} from 'react-cookie';
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router";
import {NavLink} from "react-router-dom";
import LoadingSpinner from "../COMMON/LoadingSpinner";
import KSUtils from "../SUPPORT/ks-utils";

const MainNavbar = class MainNavbar extends Component {
    constructor(props) {
        super(props);

        this.goBack = this.goBack.bind(this);
        this.doSignout = this.doSignout.bind(this);
        
        this.state = {
            redirectToSignout: false
        }
    }
    
    goBack() {
        this.props.history.go(-1);
    }
    
    showHomeOrBackButton() {
        if(this.props.showHome) {
            return (<NavLink to="/"><img src={ksLogo} width={"60px"} alt="keepsafe logo"/></NavLink>);
        }
        return (
            <div className="rw-pointer text-center" style={{width:"60px",height:"60px"}} onClick={this.goBack}>
                <i className="fa fa-chevron-left fa-lg rw-dark-fg pr-2 pt-4" />
            </div>
        );
    }
    
    showLoading() {
        if(this.props.loading) {
            return <LoadingSpinner small/> 
        }
        return null;
    }
    
    doSignout() {
        this.setState({redirectToSignout:true});
    }
    
    render() {
        
        const isAuthed = KSUtils.isAuthenticated(this.props.cookies);
        if(!isAuthed) { // go back home if not logged in.
            return(<Redirect to="/"/>);
        }
        
        if(this.state.redirectToSignout!==false) {
            return(<Redirect to="/signout"/>);
        }
        return (
            <div className="sticky-top w-100">
                <nav className="navbar navbar-light bg-light ">
                    <div className="navbar-brand">
                        {this.showHomeOrBackButton()}
                        {this.showLoading()}
                    </div>
                
                    <ul className="d-inline-flex list-inline ml-0 mr-auto mt-3">
                        <li id="helpLink" className="nav-item mr-3"> <NavLink to="/help">help</NavLink></li>
                        <li id="feedbackLink" className="nav-item"> <NavLink to="/feedback">feedback</NavLink></li>
                    </ul>

                    {isAuthed && 
                        <span id="signoutBTN" className="btn-link ks-signout ks-fg-blue small text-center" onClick={this.doSignout}><i className="fa fa-sign-out fa-2x mr-1"/>
                            <br/>sign out</span>}
                </nav>
            </div>
        );
    }
};

MainNavbar.defaultProps = {
    loading: false,    
};

MainNavbar.propTypes = {
    loading: PropTypes.bool,
    haveNewMessages: PropTypes.bool,
    cookies: PropTypes.object,
    history: PropTypes.object.isRequired,
    showHome: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
    return ({
        loading: state.ajaxCallsInProgress > 0,
    });
}


export default withRouter(withCookies(connect(mapStateToProps)(MainNavbar)));