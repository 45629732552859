
class KSUtils {
    
    static SHARE_MAGIC = "-1-1-1-1";       // special drawer number for saving to quickShare dir
    static MAIN_DRAWER_MAGIC = "11111";    // special drawer number of main drawer
    static KS_ENCRYPTED_FILE_EXT = ".enc"; // the file extension that our encrypted files should have.
    
    /** return the current date as a YYMMDD string */
    static getDateAsSextTuple() {
        const date = new Date();
        const year = date.getFullYear().toString().substr(2);
        let month = date.getMonth()+1;
        if(month<10) month="0"+month;
        let day = date.getDate();
        if(day<10) day="0"+day;
        return(year+""+month+""+day);
    }
    /** return an object that has the entire passed in Date object broken down
     *  into it's basic parts.
     * @param date (Date Object)
     * @returns {{year: number, month: number, fullMonth: *, date: number, fullDate: *, day: number, hours: number, fullHours: *, minutes: number, fullMinutes: *}}
     */
    static getParsedDate(date) {
        return {
            year: date.getFullYear(),
            month: date.getMonth(),
            fullMonth: (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1, // One based
            date: date.getDate(),
            fullDate: date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
            day: date.getDay(),
            hours: date.getHours(),
            fullHours: date.getHours() < 10 ? '0' + date.getHours() : date.getHours(),
            minutes: date.getMinutes(),
            fullMinutes: date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
        };
    }

    /** given a date object, format the time suitable for display
     *
     * @param date
     * @returns {string} or '' on error
     */
    static dateToShortTimeString(date) {
        if(!date || date==='')
            return('');
        return (date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}));
    }

    /** return a HH:MM based time string for the given unix timet
     *
     * @param timet
     * @returns {*}
     */
    static timetToShortTimeString(timet) {
        return(KSUtils.dateToShortTimeString(new Date(timet * 1000)));
    }

    /** given a unit timet (secs), build a date string for this (no time is returned).
     *
     * @param timet
     * @returns {string}
     */
    static timetToDateString(timet) {
        return(new Date(timet * 1000).toDateString());
    }

    /** given a unit timet (epoch secs UTC), build a date and time string for this
     * in the local time.
     *
     * @param timet
     * @returns {string}
     */
    static timetToDateTimeString(timet) {
        if(timet==='') {
            return ('never');
        }
        // JS dates are based on 1970 epoch.
        // C# dates are based on 0001 epoch.
        return (new Date(timet*1000).toLocaleString());
    }

    /** change the given size (in bytes) to something nicer
     * 
     * @param size
     */
    static prettyBytesSize(size:number):string {
        if(size<1000) {
            return(size+" bytes");
        }
        if(size<1000000) {
            return((size/1024).toFixed(0)+"KB");
        }
        if(size<1000000000) {
            return((size/1024000).toFixed(0)+"MB");
        }
        return((size/1024000000).toFixed(0)+"GB");
    }

    /** determine if the two Date objects are the same
     *
     * @param date1 - Date object
     * @param date2 - Date object
     * @param type - can be 'day', 'month', or 'year' for compare (default is 'day')
     * @returns { true / false}
     */
    static isSame(date1, date2, type) {
        if (!date1 || !date2) return false;
        let d1 = KSUtils.getParsedDate(date1),
            d2 = KSUtils.getParsedDate(date2),
            _type = type ? type : 'day',

            conditions = {
                day: d1.date === d2.date && d1.month === d2.month && d1.year === d2.year,
                month: d1.month === d2.month && d1.year === d2.year,
                year: d1.year === d2.year
            };

        return conditions[_type];
    }

    /** perform a deep copy of entire specified object
     * NOTE: THIS DOESN't WORK FOR DATE OBJECTS
     * @param objToCopy
     */
    static deepCopyUsingStringify(objToCopy){
       return(JSON.parse(JSON.stringify(objToCopy)));
    }
    
    /** compute a unix time_t for the given date, at the given hours and minutes
     *
     * @param date:  the base date (day, month, year, etc)
     * @param timeHH:  the HH number to set the hours time to for this date
     * @param timeMM:  the MM number to set the minutes time to for this date.
     * @returns number: unix time ticks (seconds)
     */
    static computeTimeT(date, timeHH, timeMM) {
        const jobDate = date;
        jobDate.setHours(timeHH);
        jobDate.setMinutes(timeMM);
        return(Math.floor(jobDate.getTime()/1000));
    }

    /** return the unix timet (secs) given a normal JS date object.
     * This ensures it is not a floating point.
     *
     * @param date
     * @returns {number}
     */
    static computeTimeTFromDate(date) {
        return(Math.floor(date.getTime()/1000));
    }
    
    /** find and ensure that the given cookie gets evaluated
     * and returned as a true bool.
     * @param cookies
     * @param cookieName
     * @returns {boolean}
     */
    static getCookieValueAsBool(cookies,cookieName) {
        let flag = cookies.get(cookieName);
        
        if(!flag || flag==="false" || flag===false)
            flag=false; // true bool
        else
            flag=true;
        
        return(flag);
    }

    /** given the cookies list, figure out if this user is authenticated.
     * This is a convenience function only for the frontend.  The backend doesn't 
     * use this info at all.
     * @param cookies
     */
    static isAuthenticated(cookies) {
        const expires = cookies.get('expires'); // unix secs
        if(!expires || expires==="") { // nothing
            console.debug("no expires date found");
            return false;
        }
        let expiresInt=Number.parseInt(expires);
        if(isNaN(expiresInt)) {
            console.debug("expires nan");
            return false;
        }
        const nowUxSecs = this.computeTimeTFromDate(new Date()); // value here is UTC unix msecs
        if(nowUxSecs <= expiresInt) { // good
            return true;    
        }
        
        console.log("login has expired");
        return false;
    }

    /** clear out the cookies so another user can loggin later.
     *  NOTE: if the rememberFlag is set in the cookies, the username is maintained.
     */
    static doCookieSignout() {
        document.cookie = "expires=0;expires=0;path=/"; // just kill the expires cookie
    }
    
    /** from https://www.w3schools.com/js/js_cookies.asp */
    static setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        const expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    /** from https://www.w3schools.com/js/js_cookies.asp */
    static getCookie(cname) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    
    static makeNiceDisplayDate(dateStr) {
        // inbound string: 6/7/2019, 6:48:19 PM
        // now: 6/7/2019, 11:44:08 PM (toLocalString)
        const now = new Date().toLocaleString();
        const nowParts = now.split(",");
        const inboundParts = dateStr.split(",");
        if(inboundParts.length!==2) { // dont' know how to handle this 
            return(dateStr); // just return
        }
        
        if(inboundParts[0]===nowParts[0])  { // same day
            const inSubParts = inboundParts[1].trim().split(new RegExp(/[: ]/));
            return(inSubParts[0]+":"+inSubParts[1]+inSubParts[3]); // return just the time without secs.
        }
        return(inboundParts[0]); // just the date
    }

    //#region DRAWER_SORT
    static shortDrawerByName(a, b) {
        if(a===undefined || b===undefined) return(0); // no change
        const nameA = (a.desc?a.desc.toUpperCase():""); // ignore upper and lowercase
        const nameB = (b.desc?b.desc.toUpperCase():""); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0; // equal
    }
    static shortDrawerByNameDesc(a, b) {
        return(KSUtils.shortDrawerByName(b,a)); // reverse
    }
    static sortDrawerByRecentlyUsed(drawerList, mostRecentlyUsedList, ascendingFlag) {
        if(!mostRecentlyUsedList) return drawerList;
        for(let i=mostRecentlyUsedList.length-1;i>=0;i--) { // serach list backwards *towards* the most recently used
            const d = drawerList.find( item => item.name === mostRecentlyUsedList[i]);
            if(d) {
                drawerList = drawerList.filter(item => item.name !== d.name);
                if(ascendingFlag) { // ascending - append
                    drawerList.push(d);
                }
                else { // descending - prepend
                    drawerList.unshift(d); 
                }
            }
        }
        return(drawerList);
        
    }
   
    /**
     * sort the given drawer list using the provided "method" and return it.
     * This assumes an inplace sort is done -- reducers should be using this only.
     *
     * @param drawerList
     * @param sortBy
     */
    static sortDrawerNamesBy(drawerList, sortBy="name") {
        if(sortBy === "name") {
            return(drawerList.sort(KSUtils.shortDrawerByName));
        }
        else if(sortBy === "name-d") {
            return(drawerList.sort(KSUtils.shortDrawerByNameDesc));
        }
        else if(sortBy === "recently used") {
            const mostRecentlyUsedList = KSUtils._getMRUDFromCookie();
            return(KSUtils.sortDrawerByRecentlyUsed(drawerList,mostRecentlyUsedList,true));
        }
        else if(sortBy === "recently used-d") {
            const mostRecentlyUsedList = KSUtils._getMRUDFromCookie();
            return(KSUtils.sortDrawerByRecentlyUsed(drawerList,mostRecentlyUsedList,false));
        }

        // unknown so unsorted
        return(drawerList);
    }
    static _getMRUDFromCookie() {
        let mostRecentlyUsedList=[];
        const mrud = KSUtils.getCookie("mrud");
        if(mrud) {
            mostRecentlyUsedList = JSON.parse(mrud);
        }
        return mostRecentlyUsedList;
    }
    //#endregion
    
    //#region FILE SORT
    static sortFileByName(a,b) {
        const nameA = (a.name?a.name.toUpperCase():""); // ignore upper and lowercase
        const nameB = (b.name?b.name.toUpperCase():""); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0; // equal
    }
    static sortFileByNameDesc(a,b) {
        return(KSUtils.sortFileByName(b,a));    // reversed
    }
    static sortFileByLastModified(a,b) {
        const dateA = a.date
        const dateB = b.date
        if (dateA < dateB) {
            return -1;
        }
        if (dateA > dateB) {
            return 1;
        }
        return 0; // equal
    }
    static sortFileByLastModifiedDesc(a,b) {
        return(KSUtils.sortFileByLastModified(b,a)); // reversed
    }
    static sortFileBySize(a,b) {
        const sizeA = a.size
        const sizeB = b.size
        
        if (sizeA < sizeB) {
            return -1;
        }
        if (sizeA > sizeB) {
            return 1;
        }
        return 0; // equal
    }
    static sortFileBySizeDesc(a,b) {
        return(KSUtils.sortFileBySize(b,a)); // reversed
    }
    /**
     * sort the given file list using the provided "method" and return it.
     * This assumes an inplace sort is done -- reducers should be using this only.
     *
     * @param fileList
     * @param sortBy
     * @param ascendingFlag
     */
    static sortFileNamesBy(fileList, sortBy, ascendingFlag=true) {
        if(sortBy === "name") { // asc
            return(fileList.sort(KSUtils.sortFileByName));
        }
        else if(sortBy === "name-d") { // desc
            return(fileList.sort(KSUtils.sortFileByNameDesc));
        }
        else if(sortBy === "last modified") { // asc
            return(fileList.sort(KSUtils.sortFileByLastModified))
        }
        else if(sortBy === "last modified-d") { //desc
            return(fileList.sort(KSUtils.sortFileByLastModifiedDesc))
        }
        else if(sortBy === "size") { // asc
            return(fileList.sort(KSUtils.sortFileBySize))
        }
        else if(sortBy === "size-d") { // size reverse
            return(fileList.sort(KSUtils.sortFileBySizeDesc))
        }
        
        // unknown so unsorted
        return(fileList);
    }
    //#endregion

    /**
     * ensure that the given filename has a ".enc" extension, indicating it's encrypted.
     */
    static AddFileExtensionIfNeeded(fileName) {
        if(fileName.endsWith('.')) {
            fileName = fileName.slice(0,-1)
        }
        if(fileName.endsWith(this.KS_ENCRYPTED_FILE_EXT.toUpperCase())) {
            fileName = fileName.replace(this.KS_ENCRYPTED_FILE_EXT.toUpperCase(),
                this.KS_ENCRYPTED_FILE_EXT); // this goes to lower case always.
        }
        
        if(!fileName.endsWith(this.KS_ENCRYPTED_FILE_EXT)) {
            fileName = fileName+this.KS_ENCRYPTED_FILE_EXT;
        }
        return(fileName);
    }

    /** if the given fileName has the appropriate extension, returns true since
     * this file is likely encrypted, going by the extension.  
     * This is a good method for future expansion into several different encryption schemes.
     * 
     * @param fileName
     */
    static IsFileEncryptedByExtension(fileName) {
        return fileName.endsWith(this.KS_ENCRYPTED_FILE_EXT) ||
            fileName.endsWith(this.KS_ENCRYPTED_FILE_EXT.toUpperCase());
        
    }

    /** remove the file extension if this file is an encrypted file
     *  as we know it (so we can show a more friendly name in most cases).
     *  
     * @param fileName
     * @returns {*}
     * @constructor
     */
    static RemoveKnownFileExtensionIfPresent(fileName) {
        if(!fileName || fileName==="") {
            return undefined;
        }
        
        const extensionList = [
            ".enc",
        ];
        extensionList.forEach( (e) => {
            if (fileName.endsWith(e) || fileName.endsWith(e.toUpperCase)) {
                fileName = fileName.slice(0, -4);
            }
        });
        
        return(fileName);
    }

    /** return a new filename if the remote filename is good but doesn't match
     * the one that's currently in the bundle (file may have been renamed ... we should be
     * using the new name now.)
     *
     * @param remoteFileName
     * @param fileNameInBundle
     * @param isSharing
     * @returns {*}
     * @constructor
     */
    static ResetDecryptedFileNameIfNotMatched(remoteFileName, fileNameInBundle, isSharing=false) {

        if(isSharing) { // we always use the name in the bundle if we are sharing.
            return(fileNameInBundle);
        }
        
        let fileName = fileNameInBundle; // usually correct, unless renamed since last encryption run
       
        // we need to compare decrypted names
        const decFileName = this.RemoveKnownFileExtensionIfPresent(remoteFileName);
        
        
        if(decFileName && fileNameInBundle!==decFileName) { // consider renaming
            fileName = decFileName; // no extension
        }
        return(fileName);
    }
}

export default KSUtils;
