import React from 'react';
import $ from "jquery";

function ProgressPrompt({id, actionVerb, fileName, percent, onCancelClick}) {

    function update() {
        const roundPercent = Math.floor(percent);
        const progressBar = $('#'+id);
        if (roundPercent>0 && roundPercent < 100) {
            progressBar.addClass("loading");
            const percentBit = $('#'+id+' .percent');
            percentBit.css("width",percent + '%');
        }
        else {
            progressBar.removeClass("loading");
        }
    }
    
    return (
        <div className="ks-info-prompt ml-auto my-3">
            <div id="ks-login-box">
                <div>{actionVerb}:
                     <div className="text-muted ks-truncate my-2">
                            <i>{fileName}</i>
                    </div>
                </div>
                <div id={id} className="w-100 progress_bar mb-2"><div className="percent load"/>
                </div>
                <button className="btn btn-primary" onClick={onCancelClick}>Cancel</button>
            </div>
            
            {update()}
        </div>
    );
}

export default ProgressPrompt;