import React from 'react';
import $ from "jquery";

function FileProgress({id, fileName, percent, mode, isFinished, errorText, onCancelClick}) {

    if(!mode || mode==="") {
        mode="load";
    }
    let barClass = "percent "+mode;
    
    function update() {
        //const roundPercent = Math.floor(percent);
        const progressBar = $('#pg' + id);
        if(progressBar.length===0) return;
        if (!isFinished) {
            progressBar.addClass("loading"); // bar shown
            const percentBit = $('#pg' + id + " div").first();
            percentBit.css("width", percent + '%');
        } else {
            progressBar.removeClass("loading"); // bar hidden 
        }
    }
    
    
    let textCompleteClass = "small text-success";
    let infoText="...completed";
    if(errorText && errorText!=="") {
        textCompleteClass+="text-danger";
        infoText=`Not completed: ${errorText}`;
    }

    return (
            <div>
                <div className="text-muted ks-truncate small border-top">
                    <i className="text-left">{fileName}</i>
                </div>
                <div className="row m-0 p-0 justify-content-between">
                    <div id={"pg"+id+"-done"} className={textCompleteClass} hidden={!isFinished}>{infoText}</div>
                    <div id={"pg"+id} className="col-10 progress_bar">
                        <div className={barClass}/>
                    </div>
                    <button className="offset-1 col-1 btn btn-primary text-center p-0" onClick={onCancelClick} hidden={isFinished}>X</button>
                </div>
                {update()}
            </div>
    );
}

export default FileProgress;