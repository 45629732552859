// stolen from https://reacttraining.com/react-router/web/example/auth-workflow
import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import * as PropTypes from 'prop-types';

import KSUtils from "../SUPPORT/ks-utils";

const ProtectedRoute = ({ component:Component, cookies, ...rest}) => (
        <Route {...rest} render={(props) => {
            if(KSUtils.isAuthenticated(cookies)) {
                return (<Component {...props} cookies={cookies}/>);
            }
            else { // redirect to login with the intended original location saved
                return (<Redirect to={{
                    pathname: '/', 
                    state: {from: props.location}
                }}/>);
            }
        }} />
);

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func,PropTypes.object]).isRequired,
  cookies: PropTypes.object.isRequired,
  location: PropTypes.object,  
};

export default ProtectedRoute;