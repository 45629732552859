import React, {Component} from 'react';
import queryString from 'query-string';
import MainFooter from "./MainFooter";
import MainNavbar from "./MainNavbar";
import SharePrompt from "../COMMON/SharePrompt";
import ClientApi from "../API/mockClientApi";
import Popup from "../COMMON/Popup";
import {Redirect} from "react-router";
import FormCommon from "../SUPPORT/FormCommon";

class DoShareFilePage extends Component {

    constructor(props) {
        super(props);

        this.setEmailClicked = this.setEmailClicked.bind(this);
        this.getLinkClicked = this.getLinkClicked.bind(this);
        this.goBack = this.goBack.bind(this);
        this.enableButtonWithId = this.enableButtonWithId.bind(this);

        const values = this.props.location && queryString.parse(this.props.location.search);
        this.drawerName = values.d;
        this.fileName = values.fn;
        
        this.state = {
            redirectToDashboard: false
        }
    }
    
    goBack() {
        this.setState({redirectToDashboard: true});
    }
    
    setEmailClicked(emailList, note) {
        ClientApi.shareFileWithEmail(this.drawerName, this.fileName, emailList, note)
            .then( shareLink => {
                Popup.show("",
                    `<p>Your file has been shared.</p><div>The 24 hour link to it is:<textarea id="shareTextInput" style="width:100%;margin-top:10px">${shareLink}</textarea></div>`,
                    () => this.goBack());
            })
            .catch( error => {
                Popup.showError("Error",error.error,() => this.goBack());
            } );
    }

    enableButtonWithId(id, enableFlag) {
        document.getElementById(id).disabled=!enableFlag;
    }
    
    getLinkClicked(e) {
        e.preventDefault();
        this.enableButtonWithId("doGetLinkBTN",false); // trap double-click

        ClientApi.shareFileWithEmail(this.drawerName, this.fileName)
            .then( (shareLink) => {
                FormCommon.DoSharePopupWithClipCopy(shareLink);
                this.enableButtonWithId("doGetLinkBTN",true); // re-enable 
            })
            .catch( error => {
                Popup.showError("Error",error.error,() => this.goBack());
            } );
    }
   
    render() {
        
        if(this.state.redirectToDashboard!==false) {
            return <Redirect to={{
                pathname: "/dashboard",
                search: "?d="+this.drawerName,                    // back to the current drawer
                state: { reloadFiles: false }
            }}/>
        }
        
        return (<div>
            <MainNavbar />
            <SharePrompt drawerName={this.drawerName} fileName={this.fileName} onSetEmailClicked={this.setEmailClicked}
                            onGetLinkClicked={this.getLinkClicked} onCancelClicked={this.goBack}/>
            <MainFooter/>
        </div>);
    }
}

DoShareFilePage.propTypes = {};

export default DoShareFilePage;