import React, {Component} from 'react';
import Footer from "./MainFooter";
import MainNavBasic from "./MainNavBasic";
import GetAndDecryptMain from "./GetAndDecryptMain";
import {Redirect} from "react-router";
        
class GetShareFilePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectToLink: "",
        };
    }

    redirectTo(here) {
        this.setState({redirectToLink: here});
    }
    
    render() {
        
        if(this.state.redirectToLink!=="") {
            return <Redirect to={this.state.redirectToLink}/>
        }
        
        return (<div>
                <MainNavBasic/>
                <GetAndDecryptMain location={this.props.location} sharing onRedirectRequest={(here) => this.redirectTo(here)} />
                <Footer/>
            </div>)
    }
}

export default GetShareFilePage;