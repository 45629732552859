import $ from 'jquery';
import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {withRouter} from "react-router";

class ErrorPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closeBTN: 'Close',
        };
    }

    componentDidMount(): void {
        $(document).on('shown.bs.modal', '#rwModalErrorPopup', function (e) {
            ErrorPopup.focus();
        });
    }

    componentWillUnmount() {
        $('#rwModalErrorPopup').modal('hide');
        $('.modal-backdrop').remove();
    }

    // private -- show the title if it's not empty
    static _showTitleIfNeeded(title, color) {
        $('#rwModalErrorPopup .modal-header').css({'background':color});
        const titleBar = $('#rwErrorPopupHeader');
        if(!title || title==="") {
            titleBar.hide();
        } else {
            titleBar.show();
            $('#rwModalErrorPopup #rwErrorPopupTitle').html(title);
        }
    }

    static focus() {
        $("#rwCloseBTN").focus();
    }
    
    static _showBody(message) {
        $("#rwOkBTN").hide();
        const theBody = $('#rwModalErrorPopup #rwPopupBody');
        theBody.html(message);

        const thing = $("#rwModalErrorPopup");
        thing.modal('show');
    }

    static showError(title,message, onClose) {
        ErrorPopup._showTitleIfNeeded(title,'indianred');
        ErrorPopup._showBody(message);
        ErrorPopup.onClose=onClose;
    }

    static hide(event,history) {
        $('#rwModalErrorPopup').modal('hide');
        if(ErrorPopup.onClose) {
            ErrorPopup.onClose(event);
        }
    }
    
    render() {
        return (
            <div className="modal" tabIndex="-1" id="rwModalErrorPopup" role="dialog" aria-labelledby="rwPopup"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header" id="rwErrorPopupHeader">
                            <h5 className="modal-title" id="rwErrorPopupTitle">The Title Is: {this.props.name}</h5>
                            <button id="rwPopupClose" type="button" className="close" data-dismiss="modal" aria-label="close" onClick={(e) => ErrorPopup.hide(e,this.props.history)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body" id="rwPopupBody">
                            This is the body of the popup.
                        </div>
                        <div className="modal-footer border-0">
                            <button className="btn btn-secondary" id="rwCloseBTN" onClick={(e) => ErrorPopup.hide(e,this.props.history)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

ErrorPopup.propTypes = {
    name: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};

ErrorPopup.defaultProps = {
    name: "no name"
};

export default withRouter(ErrorPopup);
