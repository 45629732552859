import React, {Component} from 'react';
import * as PropTypes from 'prop-types';

class RegisterForm extends Component{ 
    
    constructor(props) {
        super(props);

        this.gotCaptchaToken = this.gotCaptchaToken.bind(this);
        this.gotCaptchaError = this.gotCaptchaError.bind(this);

        window.gotCaptchaToken = this.gotCaptchaToken.bind(this);
        window.gotCaptchaError = this.gotCaptchaError.bind(this);
    }

    componentDidMount() {
        const rcScript = document.getElementById("rc_script");
        if (rcScript) { // remove and 
            rcScript.remove();
        }

        const script = document.createElement("script");
        script.id = "rc_script";
        script.src = "https://www.google.com/recaptcha/api.js";
        script.defer = true;
        document.body.appendChild(script);
    }

    gotCaptchaToken(recaptchaToken) {
        //console.log(recaptchaToken, "<= your recaptcha token");
        if(this.props.onGoodCaptcha) {
            this.props.onGoodCaptcha();
        }
    }

    gotCaptchaError() {
        if(this.props.onCaptchaError) {
            this.props.onCaptchaError();
        }
    }


    render() {
        
        const registerInfo = this.props.registerInfo;
        const onChange = this.props.onChange;
        const onSubmit = this.props.onSubmit;
        
        return (
            <form id="registerForm" className="my-3">
                <div className="card m-3 ks-shadow">
                    <div className="card-body bg-light">
                        <div className="row">
                            <div className="col-sm-6">
                                <label htmlFor="firstName" className="col-form-label">First Name</label>
                                <input className="form-control" name="firstName" type="text" onChange={onChange}
                                       placeholder="John"
                                       value={registerInfo.firstName}
                                       autoComplete="given-name"
                                       required/>
                            </div>
                            <div className="col-sm-6">
                                <label htmlFor="lastName" className="col-form-label">Last Name</label>
                                <input className="form-control" name="lastName" type="text" onChange={onChange}
                                       placeholder="Smith"
                                       value={registerInfo.lastName}
                                       autoComplete="family-name"
                                       required/>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-md-6">
                                <label htmlFor="email" className="col-form-label">Email</label>
                                <input className="form-control" name="email" type="email" onChange={onChange}
                                       placeholder="john.smith@somewhere.com"
                                       value={registerInfo.email}
                                       autoComplete="email"
                                       required
                                       aria-describedby="emailHelp"/>
                                <small id="emailHelp" className="form-text text-muted ml-2">* this will be your site
                                    login and for site notifications</small>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-sm-6">
                                <label htmlFor="passWord" className="col-form-label">Password</label>
                                <input className="form-control" name="passWord" type="password" onChange={onChange}
                                       placeholder="at least 6 characters"
                                       value={registerInfo.pass}
                                       autoComplete="new-password"
                                       required/>
                            </div>
                            <div className="col-sm-6">
                                <label htmlFor="verifyPass" className="col-form-label">Verify Password</label>
                                <input className="form-control" name="verifyPass" type="password" onChange={onChange}
                                       placeholder=""
                                       value={registerInfo.verifyPass}
                                       autoComplete="new-password"
                                       required/>
                            </div>
                        </div>

                        <div className="d-flex justify-content-around mt-2">
                            <div id="captchaContainer" className="g-recaptcha" data-sitekey="6LdxkroZAAAAALxzAwi8Q_6MyFRWoo9JwZaJPqOc"
                                 data-callback="gotCaptchaToken" data-expired-callback="gotCaptchaError"
                                 data-error-callback="gotCaptchaError">
                            </div>
                        </div>
                        
                        <button type="submit" id="registerBTN" className="btn btn-primary offset-4 col-4 mt-4"
                                onClick={onSubmit} disabled={!this.props.canDoRegister}>Register
                        </button>
                    </div>
                </div>

                <div className="m-3 text-dark small">
                    <strong>*NOTE*</strong> we will send a confirmation to the email address above, so please ensure it
                    is correct.
                </div>
            </form>
        );
    }
};


RegisterForm.propTypes = {
    registerInfo: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onGoodCaptcha: PropTypes.func.isRequired,
    onCaptchaError: PropTypes.func.isRequired,
    canDoRegister: PropTypes.bool.isRequired
};

RegisterForm.defaultProps = {

};

export default RegisterForm;