import $ from 'jquery';
import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import RegisterForm from "./RegisterForm";
import MainFooter from "./MainFooter";
import Popup from '../COMMON/Popup';
import {Redirect} from "react-router";
import ClientApi from "../API/mockClientApi";

class RegisterPage extends Component {
    
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleGoodCaptcha = this.handleGoodCaptcha.bind(this);
        this.handleCaptchaError = this.handleCaptchaError.bind(this);

        this.state = {
            captchaOk: false,
            registerInfo: {
                firstName: "",
                lastName: "",
                email: "",
                passWord: "",
                verifyPass: "",
            }
        }
    }
    
    onChange(e) {
        const curState = this.state;
        curState.registerInfo[e.target.name]=e.target.value;
        this.setState(curState);
    }
    
    onSubmit(event) {
        event.stopPropagation(); // let the validation checks run
        this.enableButtonWithId("registerBTN",false); // trap double-click
        
        const form = $("#registerForm")[0];
        if (form.checkValidity() === false) {
            this.enableButtonWithId("registerBTN",true); // trap double-click
            return;
        }
        event.preventDefault();

        let registerInfo = this.state.registerInfo;
        if(registerInfo.email==='') {
            Popup.showError("Error","Either email OR phone must be provided");
            this.enableButtonWithId("registerBTN",true); // trap double-click
            return;
        }
        // ensure lower case and not space surrounded.
        registerInfo.email = registerInfo.email.toLowerCase().trim();

        if($("input[name='passWord']").val()!==$("input[name='verifyPass']").val()) {
            Popup.showError("Error","Passwords must match");
            this.enableButtonWithId("registerBTN",true); // trap double-click
            return;
        }
        
        ClientApi.sendRegisterRequest(registerInfo)
        .then( ({email}) => this.redirectToSignin(email))
        .catch( (error) => {
            Popup.showError("Problem",error.error);
            this.enableButtonWithId("registerBTN",true); // trap double-click
        });
    }

    enableButtonWithId(id, enableFlag) {
        document.getElementById(id).disabled=!enableFlag;
    }

    // if this component is a child of the router
    // to gain access to redirecting from the child.
    redirectToSignin(email) {
        Popup.show("Success!",
            "Your registration was successful!<br/><br/>We will send an email to '"+email+"'"+
            " shortly containing a confirmation link to enable your new login.<br/><br/>"+
            "<span style='color:red'>You need to click on the link in that email to complete your registration.</span><br/><br/>"+
            "<p class='small'>**please check your SPAM folder if you don't see it in a few minutes!</p>", function() {
                this.setState({registered: true}); // causes rerender
            }.bind(this));
    }

    handleGoodCaptcha() {
        this.setState({captchaOk: true});
    }
    handleCaptchaError() {
        this.setState({captchaOk: false});
    }
    
    render() {

        if(this.state.registered) {
            return(
                <Redirect to={{
                    pathname: '/',
                    state: {
                        registeredUserName: this.state.registerInfo.email,
                        from: '/dashboard' // where we want to go after login
                    }
                }}/>
            );
        }
        
        return (
            <div>
                <div className="sticky-top w-100">
                    <nav className="navbar navbar-light bg-light ">
                        <div>
                            <span className="text-muted ks-display-6">LifeKeepers </span>
                            {/*<i className="fa fa-asterisk" style={{}}/>*/}
                            <span className="ks-display-6">SAFE</span>
                        </div>
                        <NavLink to="/">Home</NavLink>
                    </nav>
                </div>
                <div className="container" style={{height:"auto",marginTop:"50px",marginBottom:"50px"}}>
                    <RegisterForm registerInfo={this.state.registerInfo} 
                                  onChange={this.onChange} 
                                  onSubmit={this.onSubmit}
                                  canDoRegister={this.state.captchaOk}
                                  onGoodCaptcha={this.handleGoodCaptcha} onCaptchaError={this.handleCaptchaError}
                    />
                </div>
                <MainFooter/>
            </div>
    );
    }
}

export default RegisterPage;