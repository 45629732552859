import $ from 'jquery';
import KSUtils from "../SUPPORT/ks-utils";
import {rawurlencode} from "../SUPPORT/byteStrUtils";

//const URL_PREFIX = "/lifekeepers.com";      // added by pott - 230113 for bitforces
const URL_PREFIX = "";      // pott 230225 (after domain name move)

export default class ClientApi {
    
    /* send login request to the server */
    static sendLoginRequest(email, pass) {

        // Description: request a login token from the server
        // 
        // POST /lifekeepers/bin/auth.php
        //
        // Body: {
        //    u: myUsername,
        //    p: myPass,
        //    al: true | false
        // }
        //
        // Returns: {
        //     userName: email,
        //     fullName: "Joe Tester",
        //     expires: Math.floor(Date.now()/1000) + 3*3600  // unix time + 3 hours.
        // }
        //

        console.log(`sendLoginRequest(): sending request for user '${email}'...`);
        
        const promise = new Promise( (resolve, reject) => {
            this.sendRequestAsFormData(`${URL_PREFIX}/bin/auth.php`,"POST",{u: email, p: pass, al: true})
            .then( response => {
                if(response.isError) {
                    reject(response);
                    return; // rejects continue!
                }
                resolve({
                    userName: response.userName, 
                    fullName: response.fullName, 
                    expires: response.expires,
                    lastInTime: response.lastInTime,
                });
            })
            .catch( error => {
                reject(error);
            });
        });
        
        return(promise);
        //return (this.waitAndReturnThis(response,undefined));
    }

    /* send logout request to the server */
    static sendLogoutRequest() {

        // Description: sign this user out of the system 
        // 
        // GET unauth.php
        //
        // Body: { }
        //
        // Returns: {
        //     loggedOut: true
        // }

        console.log("sendSignoutRequest(): sending signoutRequest");

        const promise = new Promise( (resolve, reject) => {
            this.sendRequestAsFormData(`${URL_PREFIX}/bin/unauth.php`,"GET",{})
            .then( response => {
                if(response.isError) {
                    reject(response);
                    return; // rejects continue!
                }
                resolve(response);
            })
            .catch( error => {
                reject(error);
            });
        });
        return(promise);
    }
    
    static sendGuidanceRequest(fromTimeStamp) {
        // Description: ask the server if there is any new guidance for this user.
        //              the results are shown in bubbles.
        // 
        // GET guidance.php?t=<timestamp>
        //
        // Body: { }
        //
        // Returns: {
        //     guidanceList: [
        //      {element: ".ks-rename", title:"Rename a drawer" , text: "Give this drawer a new name" },
        //      ...
        //     ]
        // }

        console.log(`sendGuidanceRequest(): sending request for new guidance (from time: ${fromTimeStamp})`);

        const promise = new Promise( (resolve, reject) => {
            this.sendRequestAsFormData(`${URL_PREFIX}/bin/guidance.php?t=`+fromTimeStamp,"GET",{})
                .then( response => {
                    if(response.isError) {
                        reject(response);
                        return; // rejects continue!
                    }
                    resolve(response);
                })
                .catch( error => {
                    reject(error);
                });
        });
        return(promise);
    }

    /* send a request to reset the password for the given email address
    *
    */
    static sendResetPassRequest(email) {

        // Description: request a that the server generate a password reset link
        // 
        // POST /lifekeepers/bin/resetPass.php
        //
        // Body: {
        //    e: email,
        // }
        //
        // Returns: {
        //      email:  <original user email> 
        // OR
        //     isError: false | true
        //      error: <error message>
        // }
        //

        console.log(`sendResetPassRequest(): sending request for user '${email}'...`);

        const promise = new Promise( (resolve, reject) => {
            this.sendRequestAsFormData(`${URL_PREFIX}/bin/resetPass.php`,"POST",{u: email})
                .then( response => { resolve(true); })
                .catch( error => { reject(error); });
        });

        return(promise);
    }

    /* given the tmp link from the password request confirmation email, reset the
    *  matching user's password with the newPassword.
    *
    */
    static doPassResetRequest(tmpLink, newPassword) {

        // Description: do a password reset.
        // 
        // POST /lifekeepers/bin/doPassReset.php
        //
        // Body: {
        //    tmpLink: <the tmp link>,
        //    pass: <new password>
        // }
        //
        // Returns: {
        //      email:  <original user email> 
        // OR
        //     isError: false | true
        //      error: <error message>
        // }
        //

        console.log(`doPassResetRequest(): doing a password request for tmpLink '${tmpLink}'...`);

        const promise = new Promise( (resolve, reject) => {
            this.sendRequestAsFormData(`${URL_PREFIX}/bin/doPassReset.php`,"POST",{tmpLink: tmpLink, pass: newPassword})
                .then( response => { resolve(response.email); })
                .catch( error => { reject(error); });
        });

        return(promise);
    }

    /* get the list of drawers (and files if any) in the given drawer name (leave "" for
     * base drawer/file listing
     */
    static getFileList(drawerNumber) {

        // Description: the a list of drawers 
        // 
        // GET /lifekeepers/bin/getMyFile.php?d='' || d=342343443
        //
        // Body: { }
        //
        // Returns: {
        //      drawers: [
        //     { 
        //          name: 234234234,
        //          desc: Cards,
        //     },
        //     ...
        //     ],
        //     files: [
        //      {
        //          name: private-notes.txt.enc,
        //          size: 12343,
        //          date: 1571414113,
        //     },
        //     ...
        //     ]
        // }

        console.log(`getFileList(): sending request for drawer number '${drawerNumber}'`);

        const promise = new Promise( (resolve, reject) => {
            const url = `${URL_PREFIX}/bin/getMyFile.php?d=${drawerNumber}`;
            this.sendRequestAsFormData(url,"GET",{})
            .then( response => {
                if(response.error) {
                    reject(response.message);
                    return;
                }
                resolve({parentDrawer:drawerNumber, drawerInfo:response});
            })
            .catch( error => {
                reject(error);
            });
        });
        return(promise);
    }
    
    static deleteFile(drawerNumber, fileName) {
        
        // Description: delete a single file from a drawer or an entire drawer if the drawer is empty.
        // 
        // GET /lifekeepers/bin/deleteMyFile.php?d='' || d=342343443 &fn=<fileName>
        //
        // Body: { }
        //
        // Returns: {
        //      isError: false | true
        //      error: <error message
        // }
        
        console.log(`deleteFile(): sending request to delete file '${fileName}' from drawer '${drawerNumber}'`);

        const promise = new Promise( (resolve, reject) => {
            
            const url = `${URL_PREFIX}/bin/deleteMyFile.php?d=${drawerNumber}&fn=${rawurlencode(fileName)}`;
            this.sendRequestAsFormData(url,"GET",{})
            .then( response => resolve({parentDrawer:drawerNumber, drawerInfo:response}))
            .catch( error => reject(error));
        });
        return(promise);
    }

    /* send a request to share a given file with those listed in the email list. */
    static shareFileWithEmail(drawerNumber, fileName, emailList, note="") {

        // Description: share a single file from a drawer to the given user at the email address
        // 
        // GET /lifekeepers/bin/shareMyFile.php?d=''&fn=<fileName>&de=<emailaddress>
        //
        // Body: { }
        //
        // Returns: {
        //      shareLink: <uri to shared file>
        // OR
        //      isError: true
        //      error: <error message
        // }

        console.log(`shareFileWithEmail(): sending request to share file '${fileName}' from drawer '${drawerNumber}' with '${emailList}'`);

        const promise = new Promise( (resolve, reject) => {

            const body = {
                note: note
            };
            if(!emailList || emailList==="") { // no list provided (ok) - likely just getting an email link.
                emailList="";
            }
            
            const url = `${URL_PREFIX}/bin/shareMyFile.php?d=${drawerNumber}&fn=${rawurlencode(fileName)}&de=${emailList}`;
            this.sendRequestAsFormData(url,"POST",body)
            .then( response => resolve(response.shareLink))
            .catch( error => reject(error));
        });
        return(promise);
    }

    static renameItem(drawerNumber, fileName, newName) {

        // Description: rename a single drawer or file (rename drawer if fn="")
        // 
        // PUT /lifekeepers/bin/renameItem.php?d='' || d=342343443 &fn=<fileName>
        //
        // Body: 
        //     newName: <the new name>  
        //
        // Returns: {
        //      newName: newName 
        // OR
        //      isError: false | true
        //      error: <error message
        // }

        console.log(`renameItem(): sending request to rename item d='${drawerNumber}', f='${fileName}' to '${newName}'`);

        const promise = new Promise( (resolve, reject) => {

            const url = `${URL_PREFIX}/bin/renameItem.php?d=${drawerNumber}&fn=${rawurlencode(fileName)}`;
            const data = {
                newName: newName        // not URI encoded (post)
            };
            this.sendRequestAsFormData(url,"POST",data)
            .then( response => resolve({drawerNumber:drawerNumber, fileName:fileName, newName: response.newName}))
            .catch( error => reject(error));
        });
        return(promise);
    }

    static moveItem(oldDrawerName, newDrawerName, fileName) {

        // Description: move a single file 
        // 
        // PUT /lifekeepers/bin/moveItem.php?d='' || d=342343443 &fn=<fileName>
        //
        // Body: 
        //     newDrawerNumber: <the NUMBER of the new drawer>
        //
        // Returns: {
        //      newDrawerNumber: < NUMBER of the new drawer>
        //      newDrawerName: < name of the new drawer>
        //      name: <fileName>,
        //      size: <file size>,
        //      date: <creation date>
        // OR
        //      isError: false | true
        //      error: <error message
        // }

        console.log(`moveItem(): sending request to move item d='${oldDrawerName}', f='${fileName}' to '${newDrawerName}'`);

        const promise = new Promise( (resolve, reject) => {

            const url = `${URL_PREFIX}/bin/moveItem.php?d=${oldDrawerName}&fn=${rawurlencode(fileName)}`;
            const data = {
                newDrawerNumber: newDrawerName // number actually.
            };
            this.sendRequestAsFormData(url,"POST",data)
                .then( response => resolve({
                    oldDrawerName:oldDrawerName, 
                    newDrawerName: response.newDrawerName,
                    newDrawerNumber: response.newDrawerNumber,
                    name: response.name,
                    size: response.size,
                    date: response.date
                }))
                .catch( error => reject(error));
        });
        return(promise);
    }
    
    static addDrawer(drawerName) {
        
        // Description: add a new drawer with the given name (description really)
        // 
        // GET /lifekeepers/bin/createMyDrawer.php?d='<drawer english name>
        //
        // Body: { }
        //
        // Returns: {
        //      drawerNumber: <new drawer number>
        //      drawerDesc: <description>
        // }
        // or { isError: true, error: <message> } on error.
        //

        console.log(`addDrawer(): sending request to add drawer '${drawerName}'`);

        const promise = new Promise( (resolve, reject) => {

            if(!drawerName) {
                console.debug("you can't add an drawer without a name.");
                reject({isError:true,error:"you can't add a nameless drawer."});
                return;
            }

            const url = `${URL_PREFIX}/bin/createMyDrawer.php?d=${rawurlencode(drawerName)}`;
            this.sendRequestAsFormData(url,"GET",{})
            .then( response => {
                if(response.isError) {
                    reject(response.message);
                    return;
                }
                resolve({drawerNumber:response.drawerNumber, drawerDesc:response.drawerDesc});
            })
            .catch( error => {
                reject(error);
            });
        });
        return(promise);
    }
    
    /** attempt to register new user */
    static sendRegisterRequest({firstName, lastName, email, passWord}) {

        // Description: request a login token from the server
        // 
        // POST /lifekeepers/bin/auth.php
        //
        // Body: { (as form data)
        //    fn: <firstName>,
        //    ln: <lastName>,
        //     e: <email>
        //     p: myPass
        // }
        //
        // Returns: {
        //     email: <email>
        // }
        //

        console.log(`sendLoginRequest(): sending request for user '${email}'...`);

        const promise = new Promise( (resolve, reject) => {
            this.sendRequestAsFormData(`${URL_PREFIX}/bin/register.php`,"POST",
                {fn: encodeURI(firstName),ln: encodeURI(lastName), e: email, p: encodeURI(passWord)})
            .then(response => resolve({email: response.email}))
            .catch( error => reject(error));
        });

        return(promise);
    }

    static downloadFile(drawerName,fileName, onProgressUpdate, sharedFile) {

        /**
         * Description: retrieve the requested file from the server for decryption
         * NOTE:  interesting headers in response:
         * 
         *      Content-Disposition: attachment; filename="private_notes.txt.enc"
         *      Content-Length: 10399
         *      Content-Transfer-Encoding: binary
         *      Content-Type: application/force-download; name="private_notes.txt.enc"
         * 
         * GET /lifekeepers/bin/getMyFile.php?d=&fn=private_notes.txt.enc&sh=true | false
         * 
         * Body: {}
         * 
         * Returns: 
         *  (the raw file)
         */

        const promise = new Promise((resolve, reject) => {
            
            this.sendDownloadRequestAsGet(drawerName, fileName, onProgressUpdate, sharedFile, (fileNameHint, blob) => {
                // fileNameHint is the name of the remote file from the Content-Disposition.
                console.debug(`got file contents for '${fileName}'  (remote name is '${fileNameHint}'...`);
                resolve({remoteFileName: fileNameHint, dataBlob: blob}); // send the blob back directly ... fix this?? ###

            }, (error) => {
                console.error("got download error: "+error);
                reject(error);
            });
        });
        return(promise);
    }
    
    static uploadFile(drawerName, fileName, fileBlob, onUploadProgress) {
        /**
         * Description: post the given encrypted bytes to the server as a file
         *
         * POST /lifekeepers/bin/saveFile-flex.php
         *
         * FormBody:
         *      drawerName=<parent drawer #>
         *      Upload=true
         *      encFileName=<some name>
         *      Filedata
         *
         * Returns:
         *     {
         *          isError: true | false
         *          error: <message if error>
         *              statusCode: <status code if error>
         *          result: success
         *          hasQuickLink: true | false
         *          quickLink: <uri to resulting upload if quick sharing>
         *      }
         */

        const promise = this.postFileAsFormData(`${URL_PREFIX}/bin/saveFile-flex.php`, drawerName, fileName, fileBlob, onUploadProgress);
        return (promise); // promise
    }
        
    //#region HELPERS
    static waitAndReturnThis(returnThis,error) {
        const promise = new Promise((resolve, reject) => {
            if(!error) { // good result sent
                setTimeout(resolve.bind(null, returnThis), 2000);
            }
            else {
                setTimeout(reject.bind(null,error),1000);
            }
        });
        return(promise);
    }

    /**
     * issue a GEt to the server for the given filename in the given drawer.
     *
     * @param drawerName
     * @param fileName
     * @param onProgressUpdate - a function to update with percent complete as loading progresses
     * @param sharedFile - set to true to download this file from the shared store.
     * @param successFunc
     * @param errorFunc
     */
    static sendDownloadRequestAsGet(drawerName, fileName, onProgressUpdate, sharedFile, successFunc, errorFunc) {

        let url = `${URL_PREFIX}/bin/getMyFile.php?d=${drawerName}&fn=${rawurlencode(fileName)}`;
        if(sharedFile===true) {
            url+="&sh=true";
        }
        const jqXHR = $.ajax({
            type: "GET", url: url, //data: null,
            dataType: "binary",  // purposely blank
            xhrFields: {
                responseType: 'arraybuffer'
            },
            xhr: function() {
                const xhr = $.ajaxSettings.xhr();
                xhr.onprogress = function (e) { // downloads
                    if (e.lengthComputable && onProgressUpdate) {
                         onProgressUpdate(e.loaded / e.total*100);
                    }
                };
                xhr.upload.onprogress = function (e) { //uploads
                    if (e.lengthComputable && onProgressUpdate) {
                        onProgressUpdate(e.loaded / e.total*100);
                    }
                };
                return xhr;
            }
        })
        .done((data, status, xhr) => {
            // find the fileName hint if the server sent it.
            // note sure if we'll use this yet.
            // 200613 - this seems to mess up when presented with UTF8/UCS-2 filenames -pott
            let fileNameHint = '';
            let disposition = xhr.getResponseHeader('Content-Disposition');
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                    matches = filenameRegex.exec(disposition);

                if (matches != null && matches[1]) fileNameHint = matches[1].replace(/['"]/g, '');
            }
            // override for now (200613 -pott)
            fileNameHint = fileName; // as passed in.
            const blob = new Blob([data], {type: "application/octet-stream"});
            successFunc(fileNameHint, blob);
        })
        .fail( (jqxhr, textStatus, error) => { // 404 etc.
            errorFunc({isError: true, error:error, statusCode: jqxhr.status});
        });
        
        return(jqXHR);  // promise
    }
    
    /** send some data to the server using GET, PUT, POST or any REST action.
     * The body is sent as a JSON string.
     * NOTE: if GET is specified, the body will not be included in the message as per
     * REST requirements.
     *
     * @param url: url to send the request to (plus any query string)
     * @param method: one of GET, POST, PUT, DELETE (default is GET)
     * @param body: body object to send (empty by default)
     * @returns {Promise<any | never>}
     */
    static sendRequestAsJson(url = '', method = "GET", body = {}) {

        let requestInit = {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: "same-origin", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "include", // include, *same-origin, omit (JS credentials)
            headers: {
                "Content-Type": "application/json",
            }, redirect: "error", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
        };

        const bearerToken = ClientApi.KSToken;

        if (bearerToken && bearerToken !== "undefined") { // add tokens if we have them.
            requestInit.headers["Authorization"] = "Bearer " + bearerToken;
        }

        if (method !== "GET") { // GET requests cannot have a body
            requestInit["body"] = JSON.stringify(body);
        }

        return fetch(url, requestInit)
        .then( response => this.handleServerResponse(response))
        .catch( (e) => { return({isError: true, statusCode: 500, error: e})}); 

    }

    /** send some data to the server using GET, PUT, POST or any REST action.
     * The body is sent as a JSON string.
     * NOTE: if GET is specified, the body will not be included in the message as per
     * REST requirements.
     *
     * @param url: url to send the request to (plus any query string)
     * @param method: one of GET, POST, PUT, DELETE (default is GET)
     * @param body: body object to send (empty by default)
     * @returns {Promise<any | never>}
     */
    static sendRequestAsFormData(url = '', method = "GET", body = {}) {

        let formData = new FormData();
        if (method !== "GET") { // GET requests cannot have a body
            for (let [key, value] of Object.entries(body)) {
                formData.append(key,value);
            }
        }
        else {
            formData=undefined;
        }
        let requestInit = {
            method: method, // *GET, POST, PUT, DELETE, etc.
            credentials: 'include',
            // mode: "same-origin", // no-cors, cors, *same-origin
            // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: "omit", // include, *same-origin, omit (JS credentials)
            // headers: {
            //     "Content-Type": "application/json",
            // }, redirect: "error", // manual, *follow, error
            // referrer: "no-referrer", // no-referrer, *client
            body: formData
        };
        
        const promise = new Promise( (resolve, reject) => {
            fetch(url, requestInit)
            .then(response => this.handleServerResponse(response, resolve, reject))
            .catch(e => reject({isError: true, statusCode: 500, error: e}));
        });
        return(promise);
    }

    /** post a file to the server
     *
     * @param url: the url to post to
     * @param drawerName
     * @param fileName
     * @param fileBlob
     */
    static postFileAsFormDataUsingFetch(url = '',drawerName, fileName, fileBlob) {
        
        let formData = new FormData();
        formData.append("drawerName",drawerName); // # actually
        formData.append("Upload","true");
        formData.append("encFileName",rawurlencode(fileName));
        formData.append("Filedata", fileBlob, rawurlencode(fileName));

        let requestInit = {
            credentials: 'include',
            method: 'POST',
            body: formData,
        };

        const promise = new Promise( (resolve, reject) => {
            return fetch(url, requestInit)
            .then(response => this.handleServerResponse(response,resolve,reject))
            .catch(e => reject({isError: true, statusCode: 500, error: e}));
        });
        return(promise);
    }
    
    static postFileAsFormData(url = '',drawerName, fileName, fileBlob, onProgressUpdate) {
        
        const promise = new Promise((resolve, reject) => {

            console.log("starting xhr request to "+url);
            
            let xhr = new XMLHttpRequest();
            
            let formData = new FormData();
            formData.append("drawerName", drawerName); // # actually
            formData.append("Upload", "true");
            formData.append("encFileName", rawurlencode(fileName));
            formData.append("Filedata", fileBlob, rawurlencode(fileName));

            xhr.open('POST', url);
            xhr.withCredentials=true;

            xhr.onprogress = function (e) { // downloads
                if (e.lengthComputable && onProgressUpdate) {
                    onProgressUpdate(e.loaded / e.total * 100);
                }
            };
            xhr.upload.onprogress = function (e) { //uploads
                if (e.lengthComputable && onProgressUpdate) {
                    onProgressUpdate(e.loaded / e.total * 100);
                }
            };
            xhr.onloadend = function(e) {
                const target = e.currentTarget;
                console.debug("done load (2) => " + target.statusText);
                if(target.status>299) { // some error
                    reject({isError: true, error: target.statusText, statusCode: target.status});
                }
                
                // all good
                let result = JSON.parse(target.response);
                result["result"]="success";
                resolve(result);
            }

            xhr.send(formData);


            /*
            $.ajax({
                type: "POST", url: url, xhr: function () {
                    const xhr = $.ajaxSettings.xhr();
                    xhr.onprogress = function (e) { // downloads
                        if (e.lengthComputable && onProgressUpdate) {
                            onProgressUpdate(e.loaded / e.total * 100);
                        }
                    };
                    xhr.upload.onprogress = function (e) { //uploads
                        if (e.lengthComputable && onProgressUpdate) {
                            onProgressUpdate(e.loaded / e.total * 100);
                        }
                    };
                    return xhr;
                }, data: formData, processData: false, contentType: false
            })
            .done((data, status, xhr) => {
                resolve(data);
            })
            .fail((jqxhr, textStatus, error) => { // 404 etc.
                reject({isError: true, error: error, statusCode: jqxhr.status});
            });
            */
             
        });

        return (promise);
    }

    /** returns a promise that handles a server's response (json, or text with errors
     *
     * @param response
     * @param resolve
     * @param reject
     * @returns {*|void|Promise<any>}
     */
    static handleServerResponse(response, resolve, reject) {

        if (response.headers.has('Content-Type')) {
            if (response.headers.get('Content-Type').startsWith("application/json")) { // convert to json
                try {
                    response.json().then((payload) => {
                        if (payload.isError===true) {
                            if (payload.statusCode === 401) { // handle unauthourized here
                                KSUtils.doCookieSignout(); // kill expires (cause header to re-route)
                            }
                            reject(payload);
                            return; // don't fall thru.
                        }
                        resolve(payload);
                    });
                }
                catch(e) {
                    reject({isError:true, error: e, statusCode:500}); // no json body.
                }
            }
        }
        
        // not json...hmm...internal server error?
        if (response.ok === false) {
            response.text()
                .then( text => {
                    if (text.indexOf("Could not proxy") !== -1) { // service is gone 
                        text = "<p>We apologize, but the LifeKeepers Server appears to not be responding.</p><p>Please try your request again shortly.</p>";
                    }
                    const retVal = {
                        isError: true,
                        statusCode: response.status,
                        error: (text === "" ? "Server Response: " + response.statusText : text)
                    };
                    reject(retVal);
                })
                .catch((e) => {
                    reject( {
                        isError: true, statusCode: 500, error: e
                    });
                });
        }
        else {
            return(response); // not a promise ... return entire response.
        }
    }
    
    //#endregion


}
