import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';
import KSReduxUtils from "../SUPPORT/reduxHelpers";

export default function curFileReducer(state = initialState.currentFile, action) {

    switch (action.type) {

        case types.SET_FILE_BLOB: {
            const fileName = action.fileName;
            const fileType = action.fileType;
            const isLocalFile = action.isLocalFile;
            let dataBlob = undefined;
            if(action.dataBlob) {
                dataBlob = new Blob([action.dataBlob]);
                dataBlob = KSReduxUtils.augmentFileBlob(dataBlob, fileName, fileType, isLocalFile);
            }
            
            const newState = Object.assign({},action); // saves all object parts!
            newState.dataBlob = dataBlob;  // copied
            return (newState);
        }
        case types.SET_CURRENT_DRAWER: {
            const newState = Object.assign({},state);
            newState.drawerName = (action.drawerName?action.drawerName.toString():"");
            newState.drawerDesc = (action.drawerDesc?action.drawerDesc.toString():"");
            return(newState);
        }
        default:
            return (state);
    }
}