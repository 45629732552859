import React from "react";

function PasswordListItem({keyName,site,url,username,password, notes,lastModified,onClick,onTrashClick}) {

    const thisDropdownId="dropDown"+keyName;

    function doNothing(e) {
        e.stopPropagation();
    }
    function deleteClicked(e) {
        e.stopPropagation();
        onTrashClick();
    }
    
    function gotoSite(e) {
        e.stopPropagation();
    }
    
    let webLink = "-";
    if(url) { // ensure some formatting so we can use this as a visit link
        if(!url.startsWith("http://") && !url.startsWith("https://")) { 
            url = "http://"+url; // assume https redirection.
            url = url.replace("////","//"); // in case // was already there.
        }
        webLink = <a href={url} target="_blank" rel="noopener noreferrer" onClick={(e)=>gotoSite(e)}>{url}</a>;
    }

    return (
        <li className="list-group-item list-group-item-action" id={thisDropdownId} onClick={onClick}>
            <div className="d-flex justify-content-start">
                <div className="">
                    <i className="fa fa-key fa-2x ks-file-fg m-2"/>
                </div>
                <div className="ks-truncate" style={{width:"90%"}}>
                    <div className="ks-passform-site-name ks-truncate ml-2 mb-0 pb-0">{site}</div>
                    <div className="text-muted small ml-2 p-0">Web Link: {webLink}</div>
                </div>
            </div>

            <div className="dropdown">
                <div className="position-absolute ks-pointer dropdown-toggle" data-toggle="dropdown"
                     style={{width:"2rem",height:"2rem",bottom:"12px",right:"10px"}} onClick={doNothing}>
                    <i className="fa fa-gear fa-lg ks-trashcan"/>
                </div>
                <ul className="dropdown-menu ks-pointer">
                    <div className="dropdown-item py-1" onClick={deleteClicked}>Delete</div>
                    {/*<div className="dropdown-divider"/>*/}
                    {/*<div className="dropdown-item py-1" onClick={getRawClicked}>Download Raw</div>*/}
                </ul>

            </div>


        </li>);
}

export default PasswordListItem;