import $ from 'jquery';
import React from 'react';
import * as PropTypes from 'prop-types';

function SharePrompt({drawerName, fileName, onSetEmailClicked, onGetLinkClicked, onCancelClicked}) {

    function setEmailClicked(e) {
        e.preventDefault();
        enableButtonWithId("doShareBTN",false); // trap double-click
        
        const emailList = $("#emailBox").val();
        const fixedList = checkEmailList(emailList);
        if(fixedList==null) {
            enableButtonWithId("doShareBTN",true); // trap double-click
            return;
        }
        // share...
        const note = $("#notesTB").val();
        onSetEmailClicked(fixedList,note);
    }
    
    function checkEmailList(emails) {
        let list = emails.replace(";",",");
        list = list.replace(" ","");  // trim.
        const emailList=list.split(",");
        emailList.forEach( (item,index) =>{
            if(item.indexOf("@")===-1 || item.indexOf(".")===-1 ) { // basic check of each in the list.
                return null; // fail
            }
        });
        
        return(emailList.join(","));
    }

    function enableButtonWithId(id, enableFlag) {
        document.getElementById(id).disabled=!enableFlag;
    }
    
    return (
        <div className="">
            <form className="form-inline">
                <div id="ks-share-box">
                    <div className="bg-light p-2"><strong>Share file...</strong>
                        <button id="doGetLinkBTN" className="float-right btn btn-primary btn-sm" onClick={onGetLinkClicked}><i className="fa fa-link mr-1"/>get link</button>
                    </div>
                        <div className="text-center text-truncate text-muted m-3">
                                <i>{fileName}</i>
                        </div>
                        <div className="border-top py-2">
                            Share with users at...
                        </div>
                    
                    <input type="text" id="emailBox" placeholder="user@somewhere.com,..." className="form-control mb-2 w-100" 
                           autoFocus maxLength="128" required autoComplete="dont-do"/>
                    <textarea id="notesTB" className="form-control mb-2 w-100" rows="3" placeholder="Note from you?"/>
                    <div className="ks-xsmall text-muted my-2">
                        *NOTE: we will sent a notification to the email addresses - check the spelling!
                    </div>

                    <div className="row justify-content-around">
                    <button type="submit" id="doShareBTN" className="btn btn-primary" onClick={setEmailClicked}>
                        Send Share Email
                    </button>
                        <button className="btn btn-secondary px-5" onClick={onCancelClicked}>Close</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

SharePrompt.propTypes= {
    drawerName: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    onSetEmailClicked: PropTypes.func.isRequired,
};

export default SharePrompt;