import React, {Component} from 'react';

import MainNavbar from './MainNavbar';
import Footer from './MainFooter';

class FeedbackPage extends Component {
    
    render() {
        return (
            <div>
                <MainNavbar showHome/>
                <div className="container mt-3">
                    We are always interested in your comments, feedback, and questions.
                    Please let us know how we can do better!
                    
                    <p className="mt-3">
                        While we work at making our feedback system better, please 
                        <a href="mailto:sales@lifekeepers.com?subject=App Feedback"> click here</a> to send an old fashioned email to 
                        sales@lifekeepers.com.
                    </p>
                    
                    <p>We really do want to hear from you!</p>
                    <p>Thanks for taking the time to investigate and ask about our LifeKeepers application.</p>
                    <p>
                    -The LifeKeepers.com Team.
                    </p>
                    
                </div>
                <Footer/>
            </div>
        );
    }
}

export default FeedbackPage;
