import * as queryString from "query-string";
import {AESEncrypter} from "./AESHandler";

class KSReduxUtils {

    static findOurDrawerFromLocation(location, myStuff) {
        
        let drawerName = "";  // really depicts toplevel
        const values = location && queryString.parse(location.search);
        if (values && values.d) {
            drawerName = values.d;
        }
        
        return this.findOurDrawerGivenNumber(drawerName, myStuff);
    }

    static findOurDrawerGivenNumber(drawerName, myStuff) {

        // drawerName is drawerNumber really
        if (drawerName === "") { // toplevel is special
            return myStuff;
        }

        if (myStuff.drawers && myStuff.drawers.length !== 0) {
            const drawer = myStuff.drawers.find((item) => {
                if (!item.name) {
                    return false;
                }
                return (item.name.toString() === drawerName);
            });
            return (drawer);
        }

        return undefined;
    }
    
    
    static findOurFileFromLocation(location) {

        let fileName = "";  // none
        const values = location && queryString.parse(location.search);
        if (values && values.fn) {
            fileName = values.fn;
        }
        return fileName;
    }

    /**
     * add some important properties to our file blob to ensure the encrypter has all the info
     * it needs
     * @param blob
     * @param fileName - the filename hint that is associated with this blob
     * @param fileType - number version of the AESHandler.<filetype> for this file
     * @param isLocalFile
     * @returns {*}
     */
    static augmentFileBlob(blob, fileName, fileType, isLocalFile=false) {
        return(this.augmentFileBlobWithTypeString(blob,fileName, AESEncrypter.convertFileTypeToString(fileType), isLocalFile));
    }

    /**
     * add some important properties to our file blob to ensure the encrypter has all the info
     * it needs
     * @param blob
     * @param fileName - the filename hint that is associated with this blob
     * @param fileType - string version of the AESHandler.<filetype> for this file
     * @param isLocalFile
     * @returns {*}
     */
    static augmentFileBlobWithTypeString(blob, fileName, fileType, isLocalFile = false) {
        if(blob) {
            blob["name"] = fileName;       // augment the object
            blob["fileType"] = fileType;    // as string
            blob["isLocalFile"] = isLocalFile;
        }
        return blob;
    }
    
}

export default KSReduxUtils;