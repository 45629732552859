import React from 'react';
import * as PropTypes from "prop-types";

const LoadingSpinner = ({small, medium}) => {
    
    if(small) {
        return <i className="mt-2 ml-2 fa fa-spinner d-inline-block fa-pulse fa-lg fa-fw" aria-hidden/>
    }
    if(medium) {
        return <i className="my-2 mx-auto fa fa-spinner d-inline-block fa-pulse fa-2x fa-fw" aria-hidden/>
    }
    return <i className="my-5 mx-auto fa fa-spinner d-inline-block fa-pulse fa-3x fa-fw" aria-hidden/>
};

LoadingSpinner.propTypes = {
    small: PropTypes.bool,
    medium: PropTypes.bool,
};

export default LoadingSpinner;