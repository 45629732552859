import $ from 'jquery';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import MainFooter from "../MainFooter";
import MainNavbar from "../MainNavbar";
import {Redirect} from "react-router";
import KSUtils from "../../SUPPORT/ks-utils";
import * as queryString from "query-string";
import Popup from '../../COMMON/Popup';
import {AESEncrypter} from '../../SUPPORT/AESHandler'
import CardListItem from "./CardListItem";
import KSReduxUtils from "../../SUPPORT/reduxHelpers";
import {addToFileUploadList} from "../../ACTIONS/clientActions";
import PasswordHintPrompt from "../../COMMON/PasswordHintPrompt";
import DrawerChooser from "../DrawerChooser";

class ViewCardsPage extends Component {

    constructor(props) {
        super(props);

        this.handleDoneClicked = this.handleDoneClicked.bind(this);
        this.setBlobAndSave = this.setBlobAndSave.bind(this);
        this.handleSaveClicked = this.handleSaveClicked.bind(this);
        this.handleAddCardClicked = this.handleAddCardClicked.bind(this);
        this.handleDetailsSaveClicked = this.handleDetailsSaveClicked.bind(this);
        this.handleDetailsDoneClicked = this.handleDetailsDoneClicked.bind(this);
        this.handleMoveToEditClicked = this.handleMoveToEditClicked.bind(this);
        this.setNewPassword = this.setNewPassword.bind(this);
        this.goBackToFilesList = this.goBackToFilesList.bind(this);
        this.cancelPassClicked = this.cancelPassClicked.bind(this);
        this.showDrawerList = this.showDrawerList.bind(this);

        const values = this.props.location && queryString.parse(this.props.location.search);
        if(values && values.new) { // mark this as a new file if ?new=true
            this.newCardForm=true;
        }

        this.state = {
            redirectToLink: "",
            redirectToDashboard: false,
            fileName: "cards-" + KSUtils.getDateAsSextTuple() + ".txt", // default for new files
            cardList: [],
            cardListEdited: false,
            showCardDetails: undefined, // the card we are viewing/editting details on
            editingCardDetails: false,
            searchFilter: "",
            lastSearchMatch: undefined,
            showPassPrompt: false,
            reloadFiles: false,
        }

        // not states
        this.pass="";
        this.hint="";

        this.isLocalFile = false;       // will be set with blob from parent.
    }

    componentDidMount(): void {
        if(this.props.drawerName===undefined) { // refresh?
            this.setState({redirectToLink: "/dashboard"});
            return;
        }

        if(this.newCardForm) { // this is not a brand new file we're making
            this.setState({cardList: []}); // get started!
        }
        else {
            this.setCardListFromBlob();
        }

        // set defaults
        const drawer = KSReduxUtils.findOurDrawerGivenNumber(this.props.drawerName, this.props.myStuff);
        this.pass = drawer.pass;
        this.hint = drawer.hint && drawer.hint!==""?drawer.hint:this.props.decryptHint; // use file hint if given during decryption
        
    }
    
    setCardListFromBlob() {
        if(!this.props.textFileBlob){
            this.setState({redirectToLink: "/dashboard"});
            return;
        }

        this.isLocalFile = this.props.textFileBlob.isLocalFile;
        if(this.props.textFileBlob.text) { // user newer API
            this.props.textFileBlob.text().then((json) => {
                if (json !== "") {
                    this.setState({
                        cardList: JSON.parse(json),
                        fileName: this.props.fileName,  // use original filename
                    });
                }
            });
        }
        else { // use older readAsText() API
            const reader = new FileReader();
            reader.onload = (e) => {
                const json = e.target.result;
                if (json !== "") {
                    this.setState({
                        cardList: JSON.parse(json),
                        fileName: this.props.fileName,  // use original filename
                    });
                }
            };

            reader.readAsText(this.props.textFileBlob);  // UTF-8 assumed
        }
    }

    //#region HANDLERS
    handleDoneClicked() {
        this.setState({redirectToDashboard: true});
    }
    handleSaveClicked() {
        if (this.newFile || this.isLocalFile) { // ask for new filename
            Popup.showThreeButtonPrompt("File name?", this.state.fileName, "Upload", "Save Local", undefined,
                (fileName) => { // upload...  
                    let drawerName = this.props.drawerName;
                    if (this.isLocalFile) {
                        this.askForDestinationDrawerAndUpload(fileName);
                        return;
                    }
                    this.isLocalFile = false;  // mark for upload (NOT LOCAL) save
                    this.setBlobAndSave(fileName, drawerName);
                },
                (fileName) => { // save locally (drawer doesn't matter)
                    this.isLocalFile = true;
                    this.setBlobAndSave(fileName);
                }, "", 1);
            return;
        }


        this.setBlobAndSave(this.state.fileName, this.props.drawerName);
        this.newCardForm = false;

    }

    askForDestinationDrawerAndUpload(fileName) {
        this.isLocalFile = false;  // mark for upload (NOT LOCAL) save
        this.setState({
            showDrawerList: true,
            fileName: fileName,
        });
    }
    showDrawerList() {
        const fileName = this.state.fileName;

        return (
            <div className="container">
                <div className="container mt-3 bg-warning rounded">
                    Choose a new remote drawer for:
                    <div className="text-center mt-2"><strong>{fileName}</strong></div>
                </div>
                <DrawerChooser noDeco drawerList={this.props.myStuff.drawers} onDrawerClicked={(e,newDrawer) => this.handleDrawerClicked(e,newDrawer)}/>
            </div>
        );
    }
    handleDrawerClicked(event, drawer) {
        this.setState({showDrawerList: false});
        this.setBlobAndSave(this.state.fileName,drawer.name);  // drawer number
    }
    
    setBlobAndSave(fileName, drawerName=KSUtils.MAIN_DRAWER_MAGIC) {
        let tmpFile = new Blob([JSON.stringify(this.state.cardList)]);
        tmpFile = KSReduxUtils.augmentFileBlobWithTypeString(tmpFile, fileName, AESEncrypter.FILE_TYPE_CCARD);

        this.props.dispatch(addToFileUploadList(drawerName, tmpFile, this.pass, this.hint, this.isLocalFile));
        this.goBackToFilesList(false);
    }

    //#region RE_ENCRYPT_PASS
    setNewPassword() {
        this.setState({
            showPassPrompt: true,
            mode: "Encrypt"
        })
    }
    // this is COMMON TO ALL FORMS
    showPasswordPrompt() {
        return(
            <PasswordHintPrompt fileName={this.state.fileName} buttonText={this.state.mode} showHint={this.state.mode==="Encrypt"}
                                pass={this.pass} hint={this.hint}
                                onSetPasswordClicked={(pass,hint) => this.setPasswordClicked(pass,hint)}
                                onCancelClicked={this.cancelPassClicked}/>
        );
    }
    setPasswordClicked(pass:string,hint:string) {

        this.pass=pass;
        this.hint=hint;

        this.setState({showPassPrompt:false});
        this.setBlobAndSave(this.state.fileName,this.props.drawerName);
    }
    cancelPassClicked() {
        this.setState({showPassPrompt:false});
    }
    goBackToFilesList(reloadFilesFlag) {
        this.setState({
            redirectToDashboard: true, reloadFiles: reloadFilesFlag
        });
    }
    //#endregion
    
    handleDeleteCardClicked(cardItem) {
        let cardList = this.state.cardList;
        const updatedList = cardList.filter( (item) => item!==cardItem);
        this.setState({
            cardListEdited: true,
            cardList: updatedList
        });
    }
    handleAddCardClicked() {
        const newCard = { // bare minimal defaults here       
            type: "VISA",
            expiryMonth: "01",
            expiryYear: "20",
        };
        this.setState({
            showCardDetails: newCard,   // card details are new
            editingCardDetails: true,   // card in edit mode
        }); // new card object
    }
    handleSearchClicked(e) {
        const searchFilter = $("#searchTB").val();
        
        let lastMatchIndex = undefined;
        if(searchFilter===this.state.searchFilter) { // searching again - same search string
            lastMatchIndex = this.state.lastSearchMatch;
        }

        if(lastMatchIndex!==undefined) { // unhighlight last
            $("#dropDown"+lastMatchIndex).removeClass("ks-search-item-match");
        }
        
        let [match,matchIndex] = this.findNextMatch(searchFilter,lastMatchIndex);
        if(match) { // have one - hilight it
            const listItem = $("#dropDown"+matchIndex);
            listItem[0].scrollIntoView({block: "start", behavior: "smooth", inline: "nearest"});
            listItem.addClass("ks-search-item-match");
        }
        else {
            Popup.showInfo("no matches found");
            matchIndex=undefined; // reset
        }
        
        this.setState({
            searchFilter: searchFilter,
            lastSearchMatch: matchIndex
        });
    }
    handleSearchKeyDown(e) {
        if(e.keyCode===0x0d) { // return pressed
            this.handleSearchClicked(e);
            e.stopPropagation();
        }
    }
    //#endregion
    
    findNextMatch(searchFilter,lastMatchIndex) {
        const cardList = this.state.cardList;
        const lowSearchFilter = searchFilter.toLowerCase();
        let foundStart=(lastMatchIndex===undefined);        // need to find the start if lastMatch is set
        const matchIndex = cardList.findIndex( (item,index) => {
            if (item.type.toLowerCase().indexOf(lowSearchFilter) !== -1 ||
                item.name.toLowerCase().indexOf(lowSearchFilter) !== -1 ||
                item.notes.toLowerCase().indexOf(lowSearchFilter) !== -1 ||
                item.number.toLowerCase().indexOf(lowSearchFilter) !== -1 ) { // found a match
                if(foundStart) {
                    return true; // first match after lastMatch
                }
                if(index===lastMatchIndex) {
                    foundStart=true; // next one matches
                }
            }
            return false;
        });
        if(matchIndex===-1) { // nothing found
            return([undefined,undefined]);
        }
        return([cardList[matchIndex],matchIndex]); // []
    }
    
    //#region CARD_DETAILS
    handleCardClicked(cardItem) {
        this.setState({showCardDetails:cardItem})
    }
    handleMoveToEditClicked() {
        this.setState({editingCardDetails: true});
    }
    handleDetailsDoneClicked(e) {
        e.preventDefault();
        
        this.setState({
            showCardDetails: undefined,
            editingCardDetails: false
        });
    }
    handleDetailsSaveClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        const cardFormDetails = this.fillCardFromForm(this.state.showCardDetails);
        this.updateOrAddCardInfo(cardFormDetails);
        this.setState({
            showCardDetails: undefined, // window goes away
            cardListEdited: true,       // change the main list buttons
            editingCardDetails: false,  // buttons change to 'done'
        });
    }
    showDetailsActionButtons() {
        if(this.state.editingCardDetails) {
            return (
                <div>
                    <button id="detailActionSaveBTN" type="submit" className="btn btn-primary mr-2" onClick={(e) => this.handleDetailsSaveClicked(e)}>Set</button>
                    <button id="detailActionCancelBTN" type="submit" className="btn btn-light" onClick={(e) => this.handleDetailsDoneClicked(e)}>Cancel</button>
                </div>
            );
        }
        return(
            <button id="detailActionDoneBTN" className="btn btn-primary" onClick={(e)=>this.handleDetailsDoneClicked(e)}>Done</button>
        );
    }
    updateOrAddCardInfo(cardItem) {
        const cardList = this.state.cardList;
        const newCardList = cardList.filter( (item) => item!==cardItem);
        newCardList.push(cardItem); // updated or added
        this.setState({cardList: newCardList});
    }
    fillCardFromForm(cardItem) {
        const expiry = $("#expiry").val();
        let expiryMonth = "--";
        let expiryYear = "----";
        if (expiry.indexOf("/") !== -1) {
            [expiryMonth, expiryYear] = expiry.split("/");
        }

        // update details
        cardItem.type = $("#type").val();
        cardItem.number = $("#number").val();
        cardItem.expiryMonth = expiryMonth;
        cardItem.expiryYear = expiryYear;
        cardItem.cvv = $("#cvv").val();
        cardItem.name = $("#name").val();
        cardItem.pin = $("#pin").val();
        cardItem.address = $("#address").val();
        cardItem.notes = $("#notes").val();

        return (cardItem);
    }
    
    showCardDetails(card) {
        const readonly = !this.state.editingCardDetails;
        const pencilClass = "btn rounded-circle btn-outline-info ks-pointer "+(readonly?"":"d-none");
        
        return (
            <form id="cardDetailsForm" className="">
                <div id="ks-centered-box-md">
                    <div className="row justify-content-between bg-light p-2 mx-1" style={{minHeight:"40px"}}>
                        <div className="ks-display-7">CARD</div>
                        <div id="makeEditableBTN" className={pencilClass}
                             onClick={this.handleMoveToEditClicked}><i className="fa fa-pencil"/>
                        </div>
                    </div>

                    <div className="row my-1 justify-content-around">
                        <label className="col-2" htmlFor="type">Type</label>
                        <select id="type" className="form-control col-9 mr-2" defaultValue={card.type} disabled={readonly}>
                            <option value="visa">VISA</option>
                            <option value="mastercard">Mastercard</option>
                            <option value="discover">Discover</option>
                            <option value="amex">AMEX</option>
                            <option value="bank">BANK</option>
                            <option value="health">HEALTH</option>
                            <option value="other">OTHER</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <div className="ml-2">Number</div>
                        <input type="text" id="number" className="form-control" placeholder="####-####-####-####"
                               defaultValue={card.number} maxLength="19" disabled={readonly}/>
                    </div>
                    <div className="row">
                        <div className="form-group col-4">
                            <div className="ml-2">Expiry</div>
                            <input type="text" id="expiry" className="form-control" placeholder="mm/yyyy"
                                   defaultValue={card.expiryMonth + "/" + card.expiryYear} disabled={readonly}/>
                        </div>
                        <div className="form-group col-4">
                            <div className="ml-2" >CVV</div>
                            <input type="text" id="cvv" className="form-control" defaultValue={card.cvv}
                                   disabled={readonly}/>
                        </div>
                        <div className="form-group col-4">
                            <div className="ml-2">PIN</div>
                            <input type="text" id="pin" className="form-control" defaultValue={card.pin}
                                   disabled={readonly}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="text-left ml-2">Name</div>
                        <input type="text" id="name" className="form-control" defaultValue={card.name}
                               disabled={readonly}/>
                    </div>
                    <div className="form-group">
                        <div className="text-left ml-2">Address</div>
                        <textarea id="address" className="form-control" defaultValue={card.address}
                                  rows="3" disabled={readonly}/>
                    </div>
                    <div className="form-group">
                        <div className="text-left ml-2">Notes</div>
                        <textarea id="notes" className="form-control" defaultValue={card.notes}
                                  disabled={readonly}/>
                    </div>
                    <div className="row justify-content-center mt-3">
                        {this.showDetailsActionButtons()}
                    </div>
                </div>
            </form>
        );
    }
    //#endregion
    
    //#region CARD_LIST
    displayCards() {

        function sortByCardType(a,b) {
            const nameA = (a.type?a.type.toUpperCase():""); // ignore upper and lowercase
            const nameB = (b.type?b.type.toUpperCase():""); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0; // equal
        }
        
        let cardsList = this.state.cardList.sort(sortByCardType); // *** SORTS ARRAY IN PLACE!
        if(!cardsList || cardsList.length===0) {
            return(<p className="text-center">( no cards found )</p>);
        }

        const cards = cardsList.map( (item,index) => {
            return <CardListItem key={index} keyName={index} number={item.number} type={item.type} 
                                 notes={item.notes} onClick={() =>this.handleCardClicked(item)}
                                 onTrashClick={() => this.handleDeleteCardClicked(item)}/>
        });

        return(
                <ul id="cardsList" className="list-group my-3">
                    {cards}
                </ul>
        );
    }
    showCardList() {
        const drawerDesc = this.props.drawerDesc===undefined?" -":(this.props.drawerDesc===""?"MAIN":this.props.drawerDesc);

        return(
            <div>

                <div className="input-group position-fixed px-2" style={{top:"80px",zIndex:"2",width:"50%",marginLeft:"25%",left:"0",minWidth:"200px"}}>
                <input className="form-control" id="searchTB" placeholder="search" defaultValue={this.state.searchFilter} tabIndex="0"
                       onKeyDown={(e) => this.handleSearchKeyDown(e)}/>
                    <button className="ks-btn-no-bg input-group-append" onClick={(e) => this.handleSearchClicked(e)}>
                       <span id="nextBTN" className="btn btn-success" tabIndex="1">
                        <i className="fa fa-search"/>
                       </span>
                    </button>
                </div>
              
                <div className="row justify-content-between m-3 pt-5">
                    <div>
                        <strong>Drawer</strong>: {drawerDesc}<br/>
                        <strong>File</strong>: {this.props.fileName?this.props.fileName:" -"}<br/>
                    </div>
                </div>

                <div>
                    <div className="row mx-2 justify-content-between">
                        <div className="ks-display-6">CARDS</div>
                        <button className="btn rounded-circle btn-outline-info mt-3 mr-3 ks-pointer"
                             id="addCardBTN" autoFocus
                             onClick={this.handleAddCardClicked}><i className="fa fa-plus"/>
                        </button>
                    </div>
                    {this.displayCards()}
                </div>
                {this.showActionButtons()}
            </div>
        );
    }
    showActionButtons() {
        if(this.state.cardListEdited) {
            return (
                <div className="row justify-content-center mt-3">
                    <button id="saveActionBTN" className="btn btn-danger ks-pulse-button mr-2" onClick={this.handleSaveClicked}>Encrypt & Save</button>
                    <button id="cancelActionBTN" className="btn btn-light" onClick={this.handleDoneClicked}>Cancel</button>
                </div>
            );
        }
        return(
            <div className="row justify-content-around mt-3">
                <button id="setNewPassBTN" className="btn btn-light" onClick={this.setNewPassword}>Set New Password</button>
                <button id="doneActionBTN" className="btn btn-primary" onClick={this.handleDoneClicked}>Done</button>
            </div>
        );
    }
    //#endregion
    
    render() {
        if(this.state.redirectToDashboard!==false) {
            return <Redirect to={{
                pathname: "/dashboard",
                search: "?d="+this.props.drawerName+"&dn="+this.props.drawerDesc, // back to the current drawer
                state: { reloadFiles: this.state.reloadFiles }
            }}/>
        }
        if(this.state.redirectToLink!=="") {
            return <Redirect to={this.state.redirectToLink}/>
        }
        if(this.state.showPassPrompt) {
            return(
                <div>{this.showPasswordPrompt()}</div>
            );
        }
        if(this.state.showDrawerList) {
            return (
                <div>
                    <MainNavbar showHome/>
                    <div className="container" id="mainContainer">
                        {this.showDrawerList()}
                    </div>
                    <MainFooter/>
                </div>
            );
        }
        
        return (<div>
            <MainNavbar showHome/>
            <div className="container" id="mainContainer">
                { this.state.showCardDetails? this.showCardDetails(this.state.showCardDetails) : this.showCardList() } 
            </div>
            <MainFooter/>
        </div>);
    }
}

ViewCardsPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    textFileBlob: PropTypes.object,
    drawerName: PropTypes.string,
};

function mapStateToProps(state,ownProps) {

    return {
        fileName: state.currentFile.fileName,
        drawerName: state.currentFile.drawerName,
        drawerDesc: state.currentFile.drawerDesc,
        decryptHint: state.currentFile.decryptHint,
        textFileBlob: state.currentFile.dataBlob,
        myStuff: state.myStuff?state.myStuff:[],
    };
}

export default connect(mapStateToProps,)(ViewCardsPage);