import React from 'react';
import KSUtils from "../SUPPORT/ks-utils";

function FileListItem({keyName,name,size,date,
                          onClick,onTrashClick,onRenameClicked,onShareClicked,
                          onGetRawFileClicked,onCopyClicked, onMoveClicked}) {
    
    const thisDropdownId="dropDown"+keyName;
    
    function doNothing(e) {
        e.stopPropagation();
    }
    function deleteClicked(e) {
        e.stopPropagation();
        onTrashClick();
    }
    function renameClicked(e) {
        e.stopPropagation();
        onRenameClicked();
    }

    function copyClicked(e) {
        e.stopPropagation();
        onCopyClicked();
    }

    function getRawClicked(e) {
        e.stopPropagation();
        onGetRawFileClicked();
    }

    function shareClicked(e) {
        e.stopPropagation();
        onShareClicked();
    }
    
    function moveClicked(e) {
        e.stopPropagation();
        onMoveClicked();
    }
    
    return (
        <li className="list-group-item list-group-item-action ks-filelist-item" onClick={onClick}>
            <div className="d-flex justify-content-start">
                <div className="">
                    <i className="fa fa-file-archive-o fa-2x ks-file-fg m-2"/>
                </div>
                <div className="ks-truncate" style={{width:"90%"}}>
                    <div className="ks-filename ks-truncate mb-3">{name}</div>
                    <div className="small text-right mr-5 text-muted mt-1">
                        {KSUtils.prettyBytesSize(size)} - <span className="text-info">{KSUtils.timetToDateTimeString(date)}</span> </div>
                </div>
            </div>
            
            <div className="dropdown" id={thisDropdownId}>
                <div className="position-absolute ks-pointer dropdown-toggle" data-toggle="dropdown" 
                     style={{width:"2rem",height:"2rem",bottom:"5px",right:"0px"}} onClick={doNothing}>
                    <i className="fa fa-bars fa-lg ks-trashcan"/>
                </div>
                <ul className="dropdown-menu ks-pointer">
                    {/*<div className="dropdown-item py-1 text-muted" onClick={doNothing}>Make Readonly</div>*/}
                    <div className="dropdown-item py-1" onClick={copyClicked}><li className="fa fa-copy mr-3"/>Copy</div>
                    <div className="dropdown-item py-1" onClick={moveClicked}><li className="fa fa-arrow-right mr-3"/>Move</div>
                    <div className="dropdown-item py-1" onClick={renameClicked}><li className="fa fa-circle-thin mr-3"/>Rename</div>
                    <div className="dropdown-item py-1" onClick={deleteClicked}><li className="fa fa-trash mr-3"/>Delete</div>
                    {/*<div className="dropdown-item py-1 text-muted" onClick={doNothing}>Move</div>*/}
                    <div className="dropdown-divider"/>
                    <div className="dropdown-item py-1" onClick={shareClicked}><li className="fa fa-share mr-3"/>Share</div>
                    <div className="dropdown-divider"/>
                    <div className="dropdown-item py-1" onClick={getRawClicked}><li className="fa fa-download mr-3"/>To Local Drive</div>
                </ul>

            </div>
            
               
        </li>);
}

export default FileListItem;