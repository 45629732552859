import {copyToStoreOnStartup, getNewGuidance, sendGetFileListRequest} from "../ACTIONS/clientActions";
import KSUtils from "./ks-utils";

/** setup the client's info in the store and load up the main drawer files list.
 *  This would be called whenever a refresh is done or on first login.
 *  
 * @param cookies
 * @param dispatch
 */
export function setupClientInfo(cookies, dispatch) {
    const fullName = cookies.get('fullName');
    const userName = cookies.get('user');
    const expires = cookies.get('expires');
    const lastInTime = cookies.get('lastInTime');
    const fileSortOrder = cookies.get('fso');
    const drawerSortOrder = cookies.get('dso');
    const drawerMostRecents = cookies.get('mrud');
    
    const userInfo = {
        fullName, userName, expires, lastInTime,
        fileSortOrder: fileSortOrder,
        drawerSortOrder: drawerSortOrder,
        drawerMostRecents: drawerMostRecents
    };

    dispatch(copyToStoreOnStartup(userInfo));    // setup the redux db with cookies
    if (KSUtils.isAuthenticated(cookies)) {
        dispatch(sendGetFileListRequest(""))
            .then( () => { // get bubbles
                dispatch(getNewGuidance(lastInTime)).catch(e => {
                }); // ignore guidance errors.
            });
    }
}

/** save the user's info into cookies (for simple auth checks).
 *
 * @param userName
 * @param fullName
 * @param expires
 * @param lastInTime
 * @param cookies
 */
export function saveClientInfoToCookies(userName, fullName, expires, lastInTime, cookies) {
    cookies.set('user', userName, {path: '/'});
    cookies.set('fullName', fullName, {path: '/'});
    cookies.set('expires', expires, {path: '/'});
    cookies.set('lastInTime', lastInTime, {path: '/'});
}

/** kill any client cookies on logout.
 * 
 * @param cookies
 */
export function removeClientCookies(cookies) {
    cookies.remove('expires');
    cookies.remove('fullName');
    cookies.remove('user');
    cookies.remove('lastInTime');
    cookies.remove('PHPSESSID');  // in case PHP is being used.
    cookies.remove('al');         // auto-login cookie (old implementation)
    
    // dont' remove the sort orders
    // "fso"
    // "dso"
    
    // cookies.set('expires', 0, {path: '/'});
    // cookies.set('fullName', '', {path: '/'});
    // cookies.set('user', '', {path: '/'});
    // cookies.set('PHPSESSID','', {path: '/'});   
}