import React from 'react';
import PropTypes from 'prop-types'
import DrawerListItem from "./DrawerListItem";

function DrawerChooser ({drawerList, noDeco, onDrawerClicked, isLoadingFlag, isLocked, onTrashClicked, onEditClicked}) {

    function showDrawerList() {
        if (!drawerList || drawerList.length === 0) {
            return (<ul className="list-group my-3" id="drawerList">
                        <p className="text-center">
                            {isLoadingFlag ? "loading..." : " (no drawers found )"}
                        </p>
                    </ul>);
        }

        const drawers = drawerList.map((item, index) => {
            return <DrawerListItem key={index} name={item.name} description={item.desc} noDeco={noDeco}
                                   isLocked={isLocked}
                                   onClick={(e) => onDrawerClicked(e,item)}
                                    onEditClicked={(e) => onEditClicked(e,item)}
                                    onTrashClicked={(e) => onTrashClicked(e,item)}/>
        });

        return (
                <ul className="list-group my-3" id="drawerList">
                    {drawers}
                </ul>
        );
    }

    return (<div>
                {showDrawerList()}
            </div>);

}

DrawerChooser.propTypes = {
    drawerList: PropTypes.array.isRequired,
    noDeco: PropTypes.bool,
    isLoadingFlag: PropTypes.bool,
    onDrawerClicked: PropTypes.func.isRequired,
    onEditClicked: PropTypes.func,
    onTrashClicked: PropTypes.func,
    isLocked: PropTypes.bool,
}

export default DrawerChooser;