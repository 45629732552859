import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function uploadFilesReducer(state = initialState.uploadFiles, action) {

    switch (action.type) {

        case types.ADD_TO_FILE_UPLOAD_LIST: {
            let drawerName = action.drawerName; // drawer number actually
            const pass = action.pass;
            const hint = action.hint;
            const newFile = new File([action.fileInfo],action.fileInfo.name,{ type: action.fileInfo.type, lastModified: action.fileInfo.lastModified});
            newFile["fileType"] = action.fileInfo.fileType; // our augmented type!
            newFile["isLocalFile"] = action.isLocalFile;    // augmenting
            
            const newState = [...state, {drawerName: drawerName, fileInfo: newFile, pass:pass, hint:hint}];
            return(newState);
        }
        case types.REMOVE_FROM_FILE_UPLOAD_LIST: {
            const drawerName = action.drawerName; // drawer number actually
            const fileName = action.fileName;
            
            const newState = state.filter( t => !(t.drawerName===drawerName && t.fileInfo.name === fileName));
            return(newState);
        }
        case types.FINISH_FILE_IN_UPLOAD_LIST: {  // mark the upload for the given file as being completed (not removed from list)
            const drawerName = action.drawerName; // drawer number actually
            const fileName = action.fileName;
            const error = action.error;             // any error during upload (if any)

            let revUploads = [...state].reverse(); // copy and reverse so we search from the end.
            // get the last match.
            let updatedUploadItem = revUploads.find( t => t.drawerName===drawerName && t.fileInfo.name === fileName &&
                                t.isFinished !==true);  // unfinished only
            if(!updatedUploadItem) { // ignore
                return (state);
            }
            updatedUploadItem.isFinished=true;
            updatedUploadItem.errorText=error;
            const newState = state.filter( t => !(t===updatedUploadItem));
            newState.push(updatedUploadItem);
            return(newState);
        }
        case types.REMOVE_FINISHED_IN_UPLOAD_LIST: { // remove all finsihed file uplaods from the list
            const newState = state.filter( t => t.isFinished!==true);
            return(newState);
        }
        default:
            return (state);
    }
}