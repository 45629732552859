// holds the default state of our store.

export default {
    
    ajaxCallsInProgress: 0,
    serverInfo: {
        serviceVersion: '',
    },
    
    guideList: [],          // [{element: <name>, title: <str> , text: <str> },...]
    
    currentUserInfo: {
        fullName: '',
        userName: '',       // email
        expires: 0,         // unix timestamp when user's login expires
        lastInTime: 0,      // unix timestamp when user was last seen in the system.
    },
    
    myStuff: {                // list of drawers and their files
        initialized: false,
        fileSortOrder: "name",  // sort order for files
        drawerSortOrder: "desc",    // sort order for drawers
        drawerMostRecents: [],      // most recently used drawers
        name: "",             // toplevel
        pass: "",
        hint: "",
        drawers: [],          // each item has { name:,desc:,pass: (if set this time around yet) }
        files: [],            // each item has { name:,size:,date:,}
    },
    
    currentFile: {
        dataBlob: undefined,         // a reference to the data downloaded from the server for this file
                                     // this has a filename and file type copied into it too (don't ask why)
        fileName: "",                // name of the current file downloaded
        fileType: undefined,         // the fileType of the currently downloaded file.
        decryptHint: "",             // any hint that was original present in the decrypted file after decryption.
        drawerName: undefined,       // the name of the drawer this file came from
        drawerDesc: undefined,       // the description of the currend drawer.
    },
    
    uploadFiles: [],                // array of {drawerName:<string>, fileInfo:<fileBlob>, pass:<string>, hint:<string>, isFinished: true/false}, errorText: <error text>
};