import userReducer from './userReducer';      
import ajaxStatusReducer from './ajaxStatusReducer';
import myStuffReducer from "./myStuffReducer";
import curFileReducer from "./curFileReducer";
import uploadFilesReducer from "./uploadFilesReducer";

import * as types from "../ACTIONS/actionTypes";
import initialState from "./initialState";
import guidanceReducer from "./guidanceReducer";

// this combines the state from each of the reducers into our initial state
// NOTE: we remap the reducers to their better known names in in the larger
// state object here.
function appReducer(state = initialState, action) {
    return {
        
        currentUserInfo: userReducer(state.currentUserInfo, action),
        ajaxCallsInProgress: ajaxStatusReducer(state.ajaxCallsInProgress,action),
        myStuff: myStuffReducer(state.myStuff, action),
        currentFile: curFileReducer(state.currentFile, action),
        uploadFiles: uploadFilesReducer(state.uploadFiles, action),
        guideList: guidanceReducer(state.guideList, action)
    };
}

const rootReducer = (state, action) => {
    switch(action.type) {
        
        case types.CLEAR_STATE_ON_LOGOUT: {
            let newState = initialState;
            return (Object.assign({}, newState)); // null out the store on logout.
        }
        default:
            return appReducer(state, action); // on to the rest since we didn't match a logout action
    }
};

export default rootReducer;