import React from 'react';
import PropTypes from 'prop-types';

function DrawerListItem({name,description,onClick, onTrashClicked, onEditClicked, isLocked, noDeco}) {
    
    function showDetails() {
        return(
            <div>
                
                <div className="position-absolute ks-pointer" style={{bottom:"5px",right:"10px"}}>
                <span className="fa-stack fa-lg ks-rename" onClick={(event) => onEditClicked(event)}>
                    <i className="fa fa-circle-thin fa-stack-2x"/>
                    <i className="fa fa-pencil fa-stack-1x"/>
                </span>
                <span className="fa-stack fa-lg ks-trashcan" onClick={(event) => onTrashClicked(event)}>
                        <i className="fa fa-circle-thin fa-stack-2x"/>
                        <i className="fa fa-trash-o fa-stack-1x"/>
                </span>
                </div>
            </div>
        );
    }
    
    let lockIndicator = null;
    //isLocked ? <i className="fa fa-lock fa-stack-2x text-dark"/> : 
    //    <i className="fa fa-unlock fa-stack-2x ks-drawer-fg"/>;
    
    
    return (
        <li className="list-group-item list-group-item-action ks-drawername" id={name} onClick={onClick}>
            <div>
                <span className="float-left">
                <i className="fa fa-archive fa-2x ks-drawer-fg mr-2"/>
                    {lockIndicator}
                </span>
                <div className="ml-2 ks-truncate">{description}</div>
            </div>
            { !noDeco && showDetails() }
        </li>);
}

DrawerListItem.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    noDeco: PropTypes.bool,
    onTrashClicked: PropTypes.func,
    onEditClicked: PropTypes.func,
    onClick: PropTypes.func.isRequired,
    isLocked: PropTypes.bool,
};

export default DrawerListItem;