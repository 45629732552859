import $ from 'jquery';
import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {withRouter} from "react-router";

class Popup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showOkBTN: false,
            closeBTN: 'Close',
            okBTN: 'Ok',
        };
    }
    
    componentDidMount(): void {
        $(document).on('shown.bs.modal', '#rwModalPopup', function (e) {
            Popup.focus();
        });
    }

    componentWillUnmount() {
        $('#rwModalPopup').modal('hide');
        $('.modal-backdrop').remove();
    }

    // private -- show the title if it's not empty
    static _showTitleIfNeeded(title, color) {
        $('#rwModalPopup .modal-header').css({'background':color});
        const titleBar = $('#rwPopupHeader');
        if(!title || title==="") {
            titleBar.hide();
        } else {
            titleBar.show();
            $('#rwModalPopup #rwPopupTitle').html(title);
        }
    }
    
    static _noTitle() {
        const titleBar = $('#rwPopupHeader');
        titleBar.hide();
    }
    
    static _noCloseBtn() {
        $("#rwCloseBTN").hide();
    }
    
    static _showBody(message) {
        $("#rwOkBTN").hide();
        $("#rwThirdBTN").css("visibility","hidden");  // keep taking up space, but don't show.
        $("#hintLabel").hide();                       // hide hintLabel by default
        const theBody = $('#rwModalPopup #rwPopupBody');
        theBody.html(message);
        
        const thing = $("#rwModalPopup");
        thing.modal('show');
    }

    static show(title, message, onClose) {
        Popup._showTitleIfNeeded(title,'#e0e0e0');
        Popup._showBody(message);
        Popup.onClose=onClose;
    }

    
    
    static focus() {
        const textarea = $("#rwConfirmInput");
        if(textarea && textarea.is(":visible")) { // focus text input
            $("#rwConfirmInput").focus();
        }
        else { // default button
            const okButton = $("#rwOkBTN");
            if (okButton && okButton.is(":visible")) {
                $("#rwOkBTN").focus();
            } else {
                $("#rwCloseBTN").focus();
            }
        }
    }

    /** Show a prompt text area and let the user type some stuff in that's returned
     *  when they push OK.
     * 
     * @param title
     * @param defaultText
     * @param okBtnText
     * @param onClose
     * @param onOk
     * @param rows - number of rows in the text area (default=3)
     */
    static showPrompt(title, defaultText, okBtnText, onClose, onOk, rows=3) {
        
        Popup.showThreeButtonPrompt(title,defaultText,okBtnText,undefined,onClose,onOk,undefined, undefined, rows);
    }

    /** Show a prompt text area and two other buttons
     *
     * @param title
     * @param defaultText
     * @param okBtnText
     * @param thirdBtnText
     * @param onClose
     * @param onOk
     * @param onThird
     * @param hintContent - the help printed under the prompt area (undefined if you don't want any)
     * @param rows - number of rows in the text area (default=3)
     */
    static showThreeButtonPrompt(title, defaultText, okBtnText, thirdBtnText, onClose, onOk, onThird, hintContent, rows=3) {
        let textInput = "<textarea class='form-control' rows="+rows+" id='rwConfirmInput' placeholder=''>";
        textInput += defaultText + "</textarea>";
        Popup.show(title,textInput,onClose); // same.
        const okBTN = $("#rwOkBTN");
        if(okBtnText && okBtnText!=="") {
            okBTN.text(okBtnText);
            okBTN.show();
        }
        Popup.onOk = onOk;
        
        const thirdBTN = $("#rwThirdBTN");
        if(thirdBtnText && thirdBtnText!=="") {
            Popup.onThird = onThird;
            thirdBTN.text(thirdBtnText);
            thirdBTN.css("visibility","visible"); // not the same as show.
        }
        
        if(hintContent && hintContent!=="") {
            const hintLabel = $("#hintLabel");
            hintLabel.text(hintContent);
            hintLabel.show();
        }
       
        $("#rwConfirmInput").on('keydown', Popup.onHintKeyDown);

        // select all text
        $("#rwConfirmInput").focus();
        $("#rwConfirmInput").select();

    }

    static onHintKeyDown(e) {
        if(e.keyCode===0x0d) { // return pressed
            e.stopPropagation();
            e.preventDefault();
            Popup.ok(e);
        }
    }

    static showConfirm(title, msg, okBtnText, onClose, onOk) {
        Popup.show(title,msg,onClose); // same.
        const okBTN = $("#rwOkBTN");
        if(okBtnText && okBtnText!=="") {
            okBTN.text(okBtnText);
            okBTN.show();
        }
        Popup.onOk = onOk;
    }

    static showError(title,message, onClose) {
        Popup._showTitleIfNeeded(title,'indianred');
        Popup._showBody(message);
        Popup.onClose=onClose;
    }

    /**
     * Show a message window with no decorations
     * @param message
     */
    static showInfo(message) {
        Popup._noTitle();
        Popup._noCloseBtn();
        Popup._showBody(message);
    }

    static hide(event,history) {
        $('#rwModalPopup').modal('hide');
        if(Popup.onClose) {
            Popup.onClose(event);
        }
    }

    static ok(event) {
        $('#rwModalPopup').modal('hide');
        const content = $("#rwConfirmInput").val();
        if(Popup.onOk) {
            Popup.onOk(content);
        }
    }

    static onThirdClicked(event) {
        $('#rwModalPopup').modal('hide');
        const content = $("#rwConfirmInput").val();
        if(Popup.onThird) {
            Popup.onThird(content);
        }
    }

    render() {
        return (
            <div className="modal" tabIndex="-1" id="rwModalPopup" role="dialog" aria-labelledby="rwPopup"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header" id="rwPopupHeader">
                            <h5 className="modal-title" id="rwPopupTitle">The Title Is: {this.props.name}</h5>
                            <button id="rwPopupClose" type="button" className="close" data-dismiss="modal" aria-label="close" onClick={(e) => Popup.hide(e,this.props.history)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        
                        <div className="modal-body pb-0" id="rwPopupBody">
                            This is the body of the popup.
                        </div>
                        <div id="hintLabel" className="small mx-4"/>
                        <div className="modal-footer border-0 row justify-content-between mx-2">
                            <button className="btn btn-secondary" id="rwThirdBTN" onClick={(e) => Popup.onThirdClicked(e)}>Third</button>
                            <div>
                                <button className="btn btn-primary" id="rwOkBTN" onClick={(e) => Popup.ok(e)}>Ok</button>
                                <button className="btn btn-secondary ml-3" id="rwCloseBTN" onClick={(e) => Popup.hide(e,this.props.history)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

Popup.propTypes = {
    name: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};

Popup.defaultProps = {
    name: "no name"
};

export default withRouter(Popup);
