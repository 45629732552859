/* generate an array Uint8Array bytes of a given length */
export function generateBytesOfLen(bufferLen:number, haveThisByte:number=0x0a) {
    const buffer = [];
    for(let i=0;i<bufferLen;i++) {
        buffer.push(haveThisByte);
    }
    return(new Uint8Array(buffer));
}
/**
 * convert a string (UTF-8) to a byte array taking care of hanlding
 * UTF to byte conversions (not fun by default).
 *
 * @param inString
 * @returns {[]}
 */
export function stringToByteArray(inString) {

    //borrowed from:
    //   https://stackoverflow.com/questions/18729405/how-to-convert-utf8-string-to-byte-array
    const utf8 = unescape(encodeURIComponent(inString));

    let arr = [];
    for (let i = 0; i < utf8.length; i++) {
        arr.push(utf8.charCodeAt(i));
    }

    return(arr);
}

/**
 *  borrowed from:
 *  https://weblog.rogueamoeba.com/2017/02/27/javascript-correctly-converting-a-byte-array-to-a-utf-8-string/
 *
 * @param inBytes
 * @returns {string|null}
 */
export function convertBytesToString(inBytes:Uint8Array):string {

    const extraByteMap = [1, 1, 1, 1, 2, 2, 3, 0];
    let count = inBytes.length;
    let str = "";

    for (let index = 0; index < count;) {
        let ch = inBytes[index++];
        if (ch & 0x80) {
            let extra = extraByteMap[(ch >> 3) & 0x07];
            if (!(ch & 0x40) || !extra || ((index + extra) > count)) return null;

            ch = ch & (0x3F >> extra);
            for (; extra > 0; extra -= 1) {
                let chx = inBytes[index++];
                if ((chx & 0xC0) !== 0x80) return null;

                ch = (ch << 6) | (chx & 0x3F);
            }
        }

        str += String.fromCodePoint(ch);
    }

    return str;
}

/**
 * convert a string of bytes to a string of hex numbers representing those bytes.
 * @param content
 * @returns {string}
 */
export function convertToByteChars(content) {
    let outString = "";
    for(let c=0;c<content.length;c++) {
        outString +="0x"+content[c].toString(16)+",";
    }

    return(outString);
}

/** implement a php rawurlencode like functionality
 *  Borrowed from:  http://www.navioo.com/javascript/tutorials/Javascript_rawurlencode_1540.html
 * @param inStr
 * @returns {string}
 */
export function rawurlencode( inStr="" ) {
    // URL-encodes string  
    // 
    // version: 901.1411
    // discuss at: http://phpjs.org/functions/rawurlencode
    // +   original by: Brett Zamir
    // *     example 1: rawurlencode('Kevin van Zonneveld!');
    // *     returns 1: 'Kevin van Zonneveld%21'
    // *     example 2: rawurlencode('http://kevin.vanzonneveld.net/');
    // *     returns 2: 'http%3A%2F%2Fkevin.vanzonneveld.net%2F'
    // *     example 3: rawurlencode('http://www.google.nl/search?q=php.js&ie=utf-8&oe=utf-8&aq=t&rls=com.ubuntu:en-US:unofficial&client=firefox-a');
    // *     returns 3: 'http%3A%2F%2Fwww.google.nl%2Fsearch%3Fq%3Dphp.js%26ie%3Dutf-8%26oe%3Dutf-8%26aq%3Dt%26rls%3Dcom.ubuntu%3Aen-US%3Aunofficial%26client%3Dfirefox-a'

    let histogram = {};
    let ret = inStr.toString();

    let replacer = function(search, replace, str) {
        let tmp_arr = [];
        tmp_arr = str.split(search);
        return tmp_arr.join(replace);
    };

    // The histogram is identical to the one in urldecode.
    histogram["'"]   = '%27';
    histogram['(']   = '%28';
    histogram[')']   = '%29';
    histogram['*']   = '%2A';
    histogram['~']   = '%7E';
    histogram['!']   = '%21';
    histogram[' ']   = '%20';    // added by pott to match php rawurlencode (5.3?)
    
    // Begin with encodeURIComponent, which most resembles PHP's encoding functions
    ret = encodeURIComponent(ret);

    // Restore spaces, converted by encodeURIComponent which is not rawurlencode compatible
    //ret = replacer('%20', ' ', ret); // Custom replace. No regexing

    for (let search in histogram) {
        let replace = histogram[search];
        ret = replacer(search, replace, ret) // Custom replace. No regexing
    }

    // Uppercase for full PHP compatibility
    return ret.replace(/(%([a-z0-9]{2}))/g, function(full, m1, m2) {
        return "%"+m2.toUpperCase();
    });
}
