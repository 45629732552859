import React from "react";

/** supporting code for Card Form **/
function CardListItem({keyName,number,type,notes,onClick,onTrashClick}) {

    const thisDropdownId="dropDown"+keyName;

    function doNothing(e) {
        e.stopPropagation();
    }
    function deleteClicked(e) {
        e.stopPropagation();
        onTrashClick();
    }

    return (
        <li className="list-group-item list-group-item-action" id={thisDropdownId} onClick={onClick}>
            <div className="d-flex justify-content-start">
                <div className="">
                    <i className="fa fa-credit-card fa-2x ks-file-fg m-2"/>
                </div>
                <div className="ks-truncate" style={{width:"90%"}}>
                    <div className="ks-cardform-card-info ks-truncate mb-3 ml-2">({type}) {number}</div>
                    <div className="text-muted small ml-2 p-0">{notes}</div>
                </div>
            </div>

            <div className="dropdown">
                <div className="position-absolute ks-pointer dropdown-toggle" data-toggle="dropdown"
                     style={{width:"2rem",height:"2rem",bottom:"12px",right:"10px"}} onClick={doNothing}>
                    <i className="fa fa-gear fa-lg ks-trashcan"/>
                </div>
                <ul className="dropdown-menu ks-pointer">
                    <div className="dropdown-item py-1" onClick={deleteClicked}>Delete</div>
                    {/*<div className="dropdown-divider"/>*/}
                    {/*<div className="dropdown-item py-1" onClick={getRawClicked}>Download Raw</div>*/}
                </ul>

            </div>


        </li>);
}

export default CardListItem;