import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import MainFooter from "../MainFooter";
import MainNavbar from "../MainNavbar";
import {Redirect} from "react-router";
import KSUtils from "../../SUPPORT/ks-utils";
import * as queryString from "query-string";
import Popup from '../../COMMON/Popup';
import {AESEncrypter} from '../../SUPPORT/AESHandler'
import {addToFileUploadList} from "../../ACTIONS/clientActions";
import KSReduxUtils from "../../SUPPORT/reduxHelpers";
import PasswordHintPrompt from "../../COMMON/PasswordHintPrompt";
import DrawerChooser from "../DrawerChooser";

class ViewTextFilePage extends Component {

    constructor(props) {
        super(props);

        this.handleDoneClicked = this.handleDoneClicked.bind(this);
        this.changeHideContent = this.changeHideContent.bind(this);
        this.handleTextChanged = this.handleTextChanged.bind(this);
        this.handleSaveClicked = this.handleSaveClicked.bind(this);
        this.makeContentEditable = this.makeContentEditable.bind(this);
        this.setBlobAndSave = this.setBlobAndSave.bind(this);
        this.addToUploadList = this.addToUploadList.bind(this);
        this.goBackToFilesList = this.goBackToFilesList.bind(this);
        this.cancelPassClicked = this.cancelPassClicked.bind(this);
        this.setNewPassword = this.setNewPassword.bind(this);
        this.showDrawerList = this.showDrawerList.bind(this);

        const values = this.props.location && queryString.parse(this.props.location.search);
        if(values && values.new) { // mark this as a new file if ?new=true
            this.newFile=true;
        }
        
        this.isLocalFile = false;       // will be set with blob from parent.
        
        this.state = {
            redirectToLink: "",
            redirectToDashboard: false,
            fileName: "textPad-"+KSUtils.getDateAsSextTuple()+".txt", // default for new files
            textContent: "(no content found)",
            textContentHidden: "********",
            encryptFileList: undefined,         // FileList
            areEditing: this.newFile,
            showText: true,
            showPassPrompt: false,
            showDrawerList: false,
            
            reloadFiles: false,
        };
        
        // not states
        this.pass="";
        this.hint="";
    }
    
    componentDidMount(): void {
        if (this.props.drawerName === undefined) { // refresh?
            this.setState({redirectToLink: "/dashboard"});
            return;
        }

        if (this.newFile) { // this is not a brand new file we're making
            this.setState({textContent: ""}); // get started!
        } else {
            this.setInitialTextToBlob();
        }

        // set defaults
        const drawer = KSReduxUtils.findOurDrawerGivenNumber(this.props.drawerName, this.props.myStuff);
        this.pass = drawer.pass;
        this.hint = drawer.hint && drawer.hint!==""?drawer.hint:this.props.decryptHint; // use file hint if given during decryption
    }
    
    setInitialTextToBlob() {
        if(!this.props.textFileBlob){
            this.setState({redirectToLink: "/dashboard"});
            return;
        }

        this.isLocalFile = this.props.textFileBlob.isLocalFile;
        if(this.props.textFileBlob.text) { // use newer API
            this.props.textFileBlob.text().then((text) => {
                if (text !== "") {
                    this.setState({
                        textContent: text,
                        fileName: this.props.fileName,  // use original filename
                    });
                }
            });
        }
        else { // use older readAsText() API
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                if (text !== "") {
                    this.setState({
                        textContent: text,
                        fileName: this.props.fileName,  // use original filename
                    });
                }
            };
                
            reader.readAsText(this.props.textFileBlob);  // UTF-8 assumed
        }
    }

    setNewPassword() {
        this.setState({
            showPassPrompt: true,
            mode: "Encrypt"
        })
    }
    handleDoneClicked() {
        this.setState({redirectToDashboard: true});
    }
    handleSaveClicked() {
        if(this.newFile || this.isLocalFile) { // ask for new filename
            Popup.showThreeButtonPrompt("File name?", this.state.fileName, 
                "Upload", "Save Local", undefined,
                (fileName) => { // upload...  
                    let drawerName = this.props.drawerName;
                    if (this.isLocalFile) {
                        this.askForDestinationDrawerAndUpload(fileName);
                        return;
                    }
                    this.isLocalFile = false;  // mark for upload (NOT LOCAL) save
                    this.setBlobAndSave(fileName,drawerName);
                },
                (fileName) => { // save locally (drawer doesn't matter)
                    this.isLocalFile = true;
                    this.setBlobAndSave(fileName);
                }, "", 1);
            return;
        }
        this.setBlobAndSave(this.state.fileName, this.props.drawerName); // save as old name automatically
        this.newFile = false;
    }
    
    askForDestinationDrawerAndUpload(fileName) {
        this.isLocalFile = false;  // mark for upload (NOT LOCAL) save
        this.setState({
            showDrawerList: true,
            fileName: fileName,
        });
    }
    showDrawerList() {
        const fileName = this.state.fileName;
        
        return (
            <div className="container">
                <div className="container mt-3 bg-warning rounded">
                    Choose a new remote drawer for:
                    <div className="text-center mt-2"><strong>{fileName}</strong></div>
                </div>
                <DrawerChooser noDeco drawerList={this.props.myStuff.drawers} onDrawerClicked={(e,newDrawer) => this.handleDrawerClicked(e,newDrawer)}/>
            </div>
        );
    }
    handleDrawerClicked(event, drawer) {
        this.setState({showDrawerList: false});
        this.setBlobAndSave(this.state.fileName,drawer.name);  // drawer number
    }
    
    setBlobAndSave(fileName, drawerName=KSUtils.MAIN_DRAWER_MAGIC) {
        // build and setup our blob 
        let tmpFile = new Blob([this.state.textContent]);
        tmpFile = KSReduxUtils.augmentFileBlobWithTypeString(tmpFile, fileName, AESEncrypter.FILE_TYPE_TEXTPAD);
        // if(this.isLocalFile) { // need to save back locally
        //     this.saveLocalFile(fileName, tmpFile); // ### to save unecrypted version to disk....
        // }
        // else { // upload
            this.addToUploadList(tmpFile, drawerName, fileName, this.pass, this.hint, this.isLocalFile);
        //}
        
        this.setState({fileName: fileName}); // update in case of saveas.
    }

    // saveLocalFile(fileName, tempFile) {
    //     saveFileViaTempPageLink(fileName, tempFile)
    //         .then( (result) => {
    //             Popup.show("Saved",result);
    //         })
    // }
    
    changeHideContent() {
        if(!this.isTextHidden()) {
            this.setState({showText: false}); // hide
        }
        else {
            this.setState({showText: true}); // show
        }
    }
    showProperContent() {
        return ( this.isTextHidden()?this.state.textContentHidden:this.state.textContent);
    }
    
    isTextHidden() {
        return(!this.state.showText); // hidden
    }
    showEyeIcon() {
        if(this.isTextHidden()) {
            return(<i className="fa fa-eye"/>);
        }
        return(<i className="fa fa-eye-slash"/>);
    }
    
    handleTextChanged(e) {
        if(this.isTextHidden()) return; // no typing.
        const text = e.target.value;
        this.setState({textContent: text});
    }
    
    showActionButtons() {
        if(this.state.areEditing) {
            return (
                <div className="row justify-content-center">
                    <button id="cancelBTN" className="btn btn-light mr-2" onClick={this.handleDoneClicked}>Cancel</button>
                    <button id="saveBTN" className="btn btn-danger px-4" onClick={this.handleSaveClicked}>Save</button>
                </div>
            );
        }
        return(
            <div className="row justify-content-around">
                <button id="setNewPassBTN" className="btn btn-light" onClick={this.setNewPassword}>Set New Password</button>
                <button id="doneBTN" className="btn btn-primary" onClick={this.handleDoneClicked}>Done</button>
            </div>
        );
    }
    
    showMakeEditableIfRequired() {
        if(this.state.areEditing) {
            return null;
        }
        return(
            <div className="text-right">
                <button id="editBTN" className="btn btn-light mt-2" onClick={this.makeContentEditable}><i className="fa fa-pencil btn-light"/></button>
            </div>
        );
    }
    makeContentEditable(){
        this.setState({areEditing: true});
    }
    
    //#region RE_ENCRYPT_PASS
    showPasswordPrompt() {
        return(
            <PasswordHintPrompt fileName={this.state.fileName} buttonText={this.state.mode} showHint={this.state.mode==="Encrypt"}
                                pass={this.pass} hint={this.hint}
                                onSetPasswordClicked={(pass,hint) => this.setPasswordClicked(pass,hint)}
                                onCancelClicked={this.cancelPassClicked}/>
        );
    }
    setPasswordClicked(pass:string,hint:string) {
        
        this.pass=pass;
        this.hint=hint;
        
        this.setState({showPassPrompt:false});
        this.setBlobAndSave(this.state.fileName,this.props.drawerName);

    }
    cancelPassClicked() {
        this.setState({showPassPrompt:false});
    }
    
    addToUploadList(fileBlob, drawerName,fileName,pass,hint, isLocalFile) { // add this to the global upload list
        this.props.dispatch(addToFileUploadList(drawerName, fileBlob, pass, hint, isLocalFile));
        // we're done here.
        this.goBackToFilesList(false);
    }
    goBackToFilesList(reloadFilesFlag) {
        this.setState({
            redirectToDashboard: true, reloadFiles: reloadFilesFlag
        });
    }
    //#endregion
    
    render() {
        if(this.state.redirectToDashboard!==false) {
            return <Redirect to={{
                pathname: "/dashboard",
                search: "?d="+this.props.drawerName+"&dn="+this.props.drawerDesc, // back to the current drawer
                state: { reloadFiles: this.state.reloadFiles }
            }}/>
        }
        if(this.state.redirectToLink!=="") {
            return <Redirect to={this.state.redirectToLink}/>
        }

        if(this.state.showDrawerList) {
            return (
                <div>
                    <MainNavbar showHome/>
                    <div className="container" id="mainContainer">
                        {this.showDrawerList()}
                    </div>
                    <MainFooter/>
                </div>
            );
        }
      
        const drawerDesc = this.props.drawerDesc===undefined?" -":(this.props.drawerDesc===""?"MAIN":this.props.drawerDesc);
        
        return (<div>
            <MainNavbar showHome/>
            {this.state.showPassPrompt && this.showPasswordPrompt()}
            {!this.state.showDrawerList &&
                <div className="container">
                    <div className="row justify-content-between m-3">
                        <div>
                            <strong>Drawer</strong>: <span id="drawerName">{drawerDesc}</span><br/>
                            <strong>File</strong>: <span
                            id="fileName">{this.props.fileName ? this.props.fileName : " -"}</span><br/>
                            <strong>Size</strong>: {this.props.textFileBlob ? KSUtils.prettyBytesSize(this.props.textFileBlob.size) : " -"}
                        </div>
                        <div>
                            <div className="btn btn-secondary" id="hideCB" onClick={this.changeHideContent}>
                                {this.showEyeIcon()}
                            </div>
                            {this.showMakeEditableIfRequired()}
                        </div>
                    </div>
                    <textarea id="fileContent" className="form-control" rows="20" value={this.showProperContent()} autoFocus
                              onChange={this.handleTextChanged} readOnly={!this.state.areEditing}/>
    
                    <div className="mt-3">
                        {this.showActionButtons()}
                    </div>
                </div>
            }
            <MainFooter/>
        </div>);
    }
}

ViewTextFilePage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    textFileBlob: PropTypes.object,
    drawerName: PropTypes.string,
    drawerDesc: PropTypes.string,
    decryptHint: PropTypes.string,
    fileName: PropTypes.string,
};

function mapStateToProps(state) {

    return {
        fileName: state.currentFile.fileName,
        drawerName: state.currentFile.drawerName,
        drawerDesc: state.currentFile.drawerDesc,
        decryptHint: state.currentFile.decryptHint,
        textFileBlob: state.currentFile.dataBlob,
        myStuff: state.myStuff?state.myStuff:[],
    };
}

export default connect(mapStateToProps,)(ViewTextFilePage);