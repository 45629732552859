import $ from 'jquery';
import React, {Component} from 'react';
import { withCookies } from 'react-cookie';
import {Redirect} from "react-router";
import * as PropTypes from 'prop-types';
import {connect} from "react-redux";

import Popup from '../COMMON/Popup';
import safeImage from '../IMAGES/blueLockHeart.png';

import {sendLoginRequest} from "../ACTIONS/clientActions";
import LoadingSpinner from "../COMMON/LoadingSpinner";
import {saveClientInfoToCookies, setupClientInfo} from "../SUPPORT/setup";
import ClientApi from "../API/mockClientApi";

class LoginWidget extends Component {

    constructor(props) {
        super(props);

        this.setEmail = this.setEmail.bind(this);
        this.setPassAndLogin = this.setPassAndLogin.bind(this);
        this.saveAndRedirect = this.saveAndRedirect.bind(this);
        this.handleInputKeyDown = this.handleInputKeyDown.bind(this);

        let registeredUser = "";
        if(props.location && props.location.state) {
            registeredUser = props.location.state["registeredUserName"];
        }
        
        this.state = {
            step: 0,        // 0 for login, 1 for password, 2 for forgotPass
            email: registeredUser,
            busy: false,
            redirectToReferrer: false,
        };
    }
    
    handleInputKeyDown(e) {
        const step = this.state["step"];
        if(e.key==="Enter") {
            e.preventDefault();
            if(step===0) { // on to password
                this.setEmail(e);
            }
            else if(step===2) { // forgot password
                this.sendPassReset(e);
            }
            else if(e.target.id==="passBox") { // login
                this.setPassAndLogin(e);
            }
        }
    }

    setEmail(e) {
        e.preventDefault();
        
        let lcEmail = $("#emailBox").val().toLowerCase().trim();
        if(lcEmail==="") {
            Popup.showError(null,"Email can't be blank", () => {
                $("#emailBox").focus();
            });
            return;
        }
        
        this.setState({email: lcEmail});
        this.setState({step: 1});
        $("#passBox").focus();
    }
    setPassAndLogin(e) {
        e.preventDefault();

        const passValue = $("#passBox").val();
        if(passValue==="") {
            this.setState({ busy:false,step:0});
            Popup.showError(null,"Password can't be blank", () => {
                $("#emailBox").focus();
            });
            return;
        }
        this.enableButtonWithId("loginBTN",false); // can't double click
        this.setState({busy: true});
        
        this.props.dispatch(sendLoginRequest(this.state.email, passValue))
        .then( ({userName,fullName,expires, lastInTime}) => {
            //this.enableButtonWithId("loginBTN",true); // can't double click
            console.debug("logged in");
            this.saveAndRedirect(userName,fullName,expires, lastInTime);
        })
        .catch( (error) => {
            //this.enableButtonWithId("loginBTN",true); // can't double click
            this.setState({ busy:false,step:0});
            Popup.showError(null,error.error, () => {
                $("#emailBox").focus();
            });
        })
    }
    
    enableButtonWithId(id, enableFlag) {
        document.getElementById(id).disabled=!enableFlag;
    }
    
    sendPassReset(e) {
        
        e.preventDefault();

        const emailBox = $("#emailBox");
        const email = emailBox.val();
        this.setState({email: email});
        this.setState({busy: true});
        
        ClientApi.sendResetPassRequest(email).then( () => {
            this.setState({
                step:0,
                busy:false
            });
            Popup.showInfo(`A password reset email has been sent to <strong>'${email}'</strong>.<br><br>` +
                "Please follow the instructions in the email to reset your password.");
        })
        .catch( (error) => {
            this.setState({
                busy:false
            });
            Popup.showError(null,error.error, () =>
            {
                $("#emailBox").focus();
            });
        });
    }
    
    showProperInput() {
        if(this.state.step===0) { // show login email prompt
            return (<div>
                        <div className="text-left mb-2">Your Login Email</div>
                        <div className="input-group">
                            <input key="emailBox1" type="text" id="emailBox" className="form-control" autoFocus tabIndex="0"
                                   onKeyDown={this.handleInputKeyDown} maxLength="30" defaultValue={this.state.email} autoComplete="email"/>
                            <button id="setEmailBTN" className="ks-btn-no-bg input-group-append" onClick={(e) => this.setEmail(e)}>
                                {this.arrowOrSpinner()}
                            </button>
                        </div>
                    <div className="row justify-content-end">
                        <button id="ks-login-box-forgot" className="btn nav-link ks-fg-blue" onClick={() => this.startForgotPass()}>...I forgot my password</button>
                    </div>
                    </div>
            );
        }
        else if(this.state.step===2) { // show forgot pass
            return(<div>
                    <div className="text-left mb-2">Enter your login email<br/><span className="small">to reset your password</span></div>
                    <div className="input-group">
                        <input key="emailBox1" type="text" id="emailBox" className="form-control" autoFocus tabIndex="0"
                               onKeyDown={this.handleInputKeyDown} maxLength="30" defaultValue={this.state.email} autoComplete="email"/>
                        <button className="ks-btn-no-bg input-group-append" onClick={(e) => this.sendPassReset(e)}>
                            {this.arrowOrSpinner()}
                        </button>
                    </div>
                </div>
            );
        }
        
        // show login password.
        return(
            <div>
                <div className="text-left mb-2">Your Login Password</div>
                <div className="input-group">
                    <input key="emailBox2" type="password" id="passBox" placeholder="password" className="form-control"
                           autoFocus tabIndex="0"
                           onKeyDown={this.handleInputKeyDown} maxLength="30" defaultValue="" autoComplete="password"/>
                    <button id="loginBTN" className="ks-btn-no-bg input-group-append" onClick={(e) => this.setPassAndLogin(e)}>
                        {this.arrowOrSpinner()}
                    </button>
                </div>
            </div>
        );
    }
    
    startForgotPass() {
        this.setState( {
           step: 2 
        });
    }
    
    arrowOrSpinner() {
        if(this.state.busy) {
            return(<div><LoadingSpinner small/></div>);
        }
        return(
            <span id="nextBTN" className="btn btn-success" tabIndex="1">
                <i className="fa fa-arrow-right"/>
            </span>
        );
    }

    saveAndRedirect(userName,fullName, expires, lastInTime) {
        saveClientInfoToCookies(userName, fullName, expires, lastInTime, this.props.cookies);
        setupClientInfo(this.props.cookies, this.props.dispatch);
    }
     
    render() {

        if (this.state.redirectToReferrer === true) {
            let from = { pathname: '/dashboard' };
            if(this.props.location && this.props.location.state && this.props.location.state.from) {
                from = this.props.location.state.from;
            }
            return(<Redirect to={from}/>);
        }
        
        return (<div id="ks-login-safe" style={{backgroundImage: 'url('+safeImage+')'}} className="text-center ml-auto my-3">
            <form className="form-inline">
                <div id="ks-login-box">
                    {this.showProperInput()}
                </div>
            </form>
        </div>);
    }
}

LoginWidget.propTypes = {
    dispatch: PropTypes.func.isRequired,
    cookies: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};


export default withCookies(connect()(LoginWidget));
