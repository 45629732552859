import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';
import KSUtils from "../SUPPORT/ks-utils";

export default function myStuffReducer(state = initialState.myStuff, action) {

    let drawerName;
    let fileName;
    let newName;
    let newState;
    
    switch(action.type) {

        case types.GOT_DRAWERINFO_SUCCESS: {
            const parentDrawer = action.parentDrawer ? action.parentDrawer.toString() : "";

            // we sort here because sort() sorts the array in place and mutates.
            const sortedDrawers = KSUtils.sortDrawerNamesBy(action.drawerInfo.drawers,state.drawerSortOrder);
            const sortedFiles = KSUtils.sortFileNamesBy(action.drawerInfo.files,state.fileSortOrder);

            if (parentDrawer === "") { // top level is special
                const newState = Object.assign({}, state, {drawers: sortedDrawers}, {files: sortedFiles});
                newState.initialized = true;
                // add in any contents for child drawers that were already in the state 
                // (this action will only contain the child drawer names and the files immediately
                // at THIS level, we don't want or nee to kill the files in the child drawers just
                // because we are updating this parent drawer list).  ug.
                
                state.drawers.forEach( (d,index) => {
                    const updatedStateDrawer = newState.drawers.find( t => t.name === d.name );
                    if(updatedStateDrawer) { // attach content to new
                        updatedStateDrawer.desc = d.desc;
                        updatedStateDrawer.pass = d.pass;
                        updatedStateDrawer.files = d.files;
                    }
                })
                
                return (newState);
            }

            // doing a sub drawer
            let curParent = state.drawers.find((item) => item.name.toString() === parentDrawer);
            if (!curParent) { // no drawer structure yet likely (refresh)? (### this is a mess.
                curParent = {
                    name: parentDrawer,
                    desc: "??",
                    pass: ""
                }
            }

            const newParentDrawer = Object.assign({}, curParent, {files: sortedFiles}); // replace the files section inside this parent
            // ### TODO -- ignoring drawers inside drawers right now.

            const newState = Object.assign({}, state);
            newState.drawers = replaceDrawerWithDrawer(parentDrawer,newParentDrawer);
            //const newStateDrawers = newState.drawers.filter((item) => item.name.toString() !== parentDrawer); // remove current parent drawer
            //newStateDrawers.push(newParentDrawer);
            //newState.drawers = KSUtils.sortDrawerNamesBy(newStateDrawers,state.drawerSortOrder);
            newState.initialized = true;
            return (newState);

        }
        case types.SEND_DELETEFILE_SUCCESS: {
            const drawerName = action.drawerName.toString();
            const fileName = action.fileName.toString();

            let newState = deleteFileFromDrawer(drawerName, fileName);

            return (newState);
        }
        case types.GOT_DRAWER_PASSWORD: {
            const drawerName = (action.drawer===undefined?"":action.drawer.toString()); // drawer Number!
            const password = (action.password ? action.password.toString() : "");
            const hint = (action.hint ? action.hint.toString() : "");

            if (drawerName === "") { // toplevel drawer is special
                const newMyStuff = Object.assign({}, state);
                newMyStuff.pass = password; // toplevel password set
                newMyStuff.hint = hint;
                return (newMyStuff);
            }

            const curDrawer = state.drawers.find((item) => item.name.toString() === drawerName);
            const newDrawer = Object.assign({}, curDrawer); // replace the files section inside this parent
            newDrawer.pass = password;
            newDrawer.hint = hint;

            const newState = Object.assign({}, state);
            newState.drawers = replaceDrawerWithDrawer(drawerName,newDrawer);
            //const newStateDrawers = state.drawers.filter((item) => item.name.toString() !== drawerName); // remove current parent drawer
            //newStateDrawers.push(newDrawer);
            //newState.drawers = KSUtils.sortDrawerNamesBy(newStateDrawers,state.drawerSortOrder);
            return (newState);
        }
        case types.SEND_ADD_DRAWER_SUCCESS: {
            const drawerNumber = action.drawerNumber.toString();
            const drawerDesc = action.drawerDesc.toString();

            // ### TODO -- we only add drawers to the top level right now.
            const drawers = [...state.drawers, {name: drawerNumber, desc: drawerDesc, pass: ""}]; // append new one
            const newState = Object.assign({}, state);
            newState.drawers = KSUtils.sortDrawerNamesBy(drawers,state.drawerSortOrder);
            return (newState);
        }
        case types.GOT_RENAMEITEM_SUCCESS:
            drawerName = action.drawerName.toString();
            fileName = action.fileName.toString();
            newName = action.newName.toString();

            newState = Object.assign({}, state);
            if (drawerName === "") { // top level drawer -- special
                let origFile = state.files.filter((item) => item.name.toString() === fileName)[0];
                origFile = Object.assign({}, origFile, {name: newName});  // copy
                const updatedFiles = state.files.filter((item) => item.name.toString() !== fileName);
                updatedFiles.push(origFile); // add to end
                newState.files = KSUtils.sortFileNamesBy(updatedFiles,state.fileSortOrder);
                newState.drawers = state.drawers;
                return (newState);
            }

            let renameFromDrawer = state.drawers.find((item) => item.name.toString() === drawerName);
            let updatedFromDrawer = Object.assign({}, renameFromDrawer); // copy
            
            if(fileName!=="") { // renaming a file
                let origFile = renameFromDrawer.files.filter((item) => item.name.toString() === fileName)[0];
                origFile = Object.assign({}, origFile, {name: newName});  // copy
                
                updatedFromDrawer.files = renameFromDrawer.files.filter((item) => item.name.toString() !== fileName);
                updatedFromDrawer.files.push(origFile); // add to end
                updatedFromDrawer.files= KSUtils.sortFileNamesBy(updatedFromDrawer.files,state.fileSortOrder);
            }
            else { // update drawer name only
                updatedFromDrawer.desc = newName;
            }

            // remove and append the new updated drawer.
            newState.drawers = replaceDrawerWithDrawer(drawerName, updatedFromDrawer);
            //newState.drawers = state.drawers.filter((drawer) => drawer.name.toString() !== drawerName);
            //newState.drawers.push(updatedFromDrawer); // append
            //newState.drawers = KSUtils.sortDrawerNamesBy(newState.drawers,state.drawerSortOrder);

            return (newState);
        case types.GOT_MOVEITEM_SUCCESS: {

            const oldDrawerName = action.oldDrawerName.toString();
            const newDrawerNumber = action.newDrawerNumber.toString();
            fileName = action.fileName.toString();
            const fileSize = action.fileSize.toString();
            const fileDate = action.fileDate.toString();

            let s = deleteFileFromDrawer(oldDrawerName, fileName); // delete from old location
            newState = addFileToDrawer(s, newDrawerNumber, fileName, fileSize, fileDate);

            return (newState);
        }
        case types.ADD_FILE_TO_DRAWER_SUCCESS: { // add a new file to the given drawer
            const drawerName = action.drawerName.toString();  // drawer number!
            const fileName = action.fileName.toString();
            const fileSize = action.fileSize.toString();
            const fileDate = action.fileDate.toString();

            if(drawerName === KSUtils.SHARE_MAGIC) { // don't track quickShares
                return (state);
            }
            
            let newState = Object.assign({}, state);
            newState = addFileToDrawer(newState, drawerName, fileName, fileSize, fileDate);
            return (newState);
        }
        case types.SET_FILE_SORT_ORDER: { // user setting sort order for files
            const sortType = action.sortType;
            const curDrawerNumber = action.curDrawerNumber; // we need to resort this to show user a change.
            
            // set the fileSortOrder into the state so we can use it
            let newState = Object.assign({}, state);
            newState.fileSortOrder = sortType;
            
            // set it into the cookie so we remember it
            KSUtils.setCookie("fso",sortType,10000);  
            
            const curDrawer = state.drawers.find((item) => item.name.toString() === curDrawerNumber);
            let curDrawerCopy = Object.assign({}, curDrawer); // shallow copy

            const fileList = [...curDrawerCopy.files]; //Object.assign([],curDrawerCopy.files); // make shallow copy
            curDrawerCopy.files = KSUtils.sortFileNamesBy(fileList,newState.fileSortOrder);
            
            newState.drawers = replaceDrawerWithDrawer(curDrawerNumber, curDrawerCopy);
            return(newState);
        }
        case types.SET_DRAWER_SORT_ORDER: { // user setting sort order for drawers
            const sortType = action.sortType;

            // set the drawerSortOrder into the state so we can use it
            let newState = Object.assign({}, state);
            newState.drawerSortOrder = sortType;

            // set it into the cookie so we remember it
            KSUtils.setCookie("dso",sortType,10000);
            
            const drawerList = Object.assign([],state.drawers); // make shallow copy
            newState.drawers = KSUtils.sortDrawerNamesBy(drawerList,newState.drawerSortOrder);
            return(newState);
        }

        case types.COPY_TO_STORE_ON_STARTUP: {  // at startup we want to set some stuff up with sort order from cookies.
            const fileSortOrder = action.userInfo.fileSortOrder;
            const drawerSortOrder = action.userInfo.drawerSortOrder;
            const drawerMostRecents = action.userInfo.drawerMostRecents;

            const newState = Object.assign({}, state);
            newState.fileSortOrder = (!fileSortOrder?"name":fileSortOrder);
            newState.drawerSortOrder = (!drawerSortOrder?"name":drawerSortOrder);
            newState.drawerMostRecents = (!drawerMostRecents?[]:drawerMostRecents);
            
            return (newState); // mix in our new token
        }

        case types.SET_CURRENT_DRAWER: {  // whenever a drawer is clicked, we want to track recently used/most used in cookies.
            const drawerNumber = action.drawerName; // number actually!
            //const drawerDesc = action.drawerDesc;
            
            if(drawerNumber === "") { // user going back up to main -- ignore
                return(state);
            }
            
            const newState = Object.assign({},state);
            
            let mostRecentList = state.drawerMostRecents.filter( d => d !== drawerNumber); // copy, remove original
            mostRecentList.unshift(drawerNumber); // prepend
            // const numItems = mostRecentList.unshift(drawerNumber); // at end.
            //if(numItems > 5) {
            //    mostRecentList = mostRecentList.slice(0,-1);
            //}

            // set it into the cookie so we remember it
            KSUtils.setCookie("mrud",JSON.stringify(mostRecentList),10000);
            
            newState.drawerMostRecents = mostRecentList;
            return(newState);
        }
        
        default:
            return (state); // IMPORTANT!
    }

    /**
     * given a drawer name, find the drawer object and replace it with the one that's been
     * passed in.  The drawers list is resorted and returned.
     * 
     * @param origDrawerNumber
     * @param newDrawerObject
     */
    function replaceDrawerWithDrawer(origDrawerNumber, newDrawerObject) {
        let drawersList = state.drawers.filter((drawer) => drawer.name.toString() !== origDrawerNumber); // copy
        drawersList.push(newDrawerObject); // append
        drawersList = KSUtils.sortDrawerNamesBy(drawersList,state.drawerSortOrder);
        return(drawersList);
        
    }
    
    /** add a file to a drawer, removing any others that have the same name
     * 
     * @param newState
     * @param newDrawerNumber
     * @param fileName
     * @param fileSize
     * @param fileDate
     * @returns {*}
     */
    function addFileToDrawer(newState , newDrawerNumber, fileName,fileSize, fileDate) {
        let destDrawer = state;  // root drawer here
        if(newDrawerNumber!=="") { destDrawer = state.drawers.find((item) => item.name.toString() === newDrawerNumber); }
        let newFile = {
            name: fileName,
            size: fileSize,
            date: fileDate
        };
        let newDrawer = {...destDrawer}; // expand object
        let newFilesList = destDrawer.files?destDrawer.files.filter( item => item.name!==fileName):[]; //expand array
        
        newFilesList.push(newFile); // add our new file
        newFilesList = KSUtils.sortFileNamesBy(newFilesList,state.fileSortOrder);
        newDrawer.files = newFilesList;

        if(newDrawerNumber==="") { // top level drawer
            newState = newDrawer;
            return(newState);
        }
        
        // remove and append the new updated drawer.
        newState.drawers = replaceDrawerWithDrawer(newDrawerNumber,newDrawer);
        //newState.drawers = state.drawers.filter((drawer) => drawer.name.toString() !== newDrawerNumber); // copy
        //newState.drawers.push(newDrawer); // append
        //newState.drawers = KSUtils.sortDrawerNamesBy(newState.drawers, state.drawerSortOrder);
        
        return(newState);
    }
    
    function deleteFileFromDrawer(drawerName, fileName) {
        let newState = Object.assign({}, state);
        if (fileName !== "") { // deleting a file from a drawer
            if (drawerName === "") { // from top level drawer -- special
                const updatedFiles = state.files.filter((item) => item.name.toString() !== fileName);  // filter out the one that matches
                newState.drawers = state.drawers;
                newState.files = updatedFiles;
                return (newState);
            }

            const deletedFromDrawer = state.drawers.find((item) => item.name.toString() === drawerName);

            const updatedParentDrawer = Object.assign({}, deletedFromDrawer);
            updatedParentDrawer.files = deletedFromDrawer.files.filter((item) => item.name.toString() !== fileName); // filter the file

            newState.drawers = replaceDrawerWithDrawer(drawerName,updatedParentDrawer);
            // remove and append the new updated drawer.
            //newState.drawers = state.drawers.filter((drawer) => drawer.name.toString() !== drawerName);
            //newState.drawers.push(updatedParentDrawer); // append
        } else { // deleting a drawer only (top level drawers only for now ###)
            newState.drawers = state.drawers.filter((drawer) => drawer.name.toString() !== drawerName);
        }
        return(newState);
    }
}