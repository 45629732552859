import React, {Component} from 'react';
import Bubble from "../COMMON/Bubble";
import {connect} from "react-redux";
import $ from "jquery";
import {clearGuideList} from "../ACTIONS/clientActions";

class Guide extends Component {
    
    constructor(props) {
        super(props);

        this.handleSkipClicked = this.handleSkipClicked.bind(this);
        this.handleNextClicked = this.handleNextClicked.bind(this);
        this.findElement = this.findElement.bind(this);
        this.markGuideDone = this.markGuideDone.bind(this);
        
        this.state ={
            showGuide: true,
            curIndex: 0
        };
    }
    
    handleSkipClicked() {
        this.markGuideDone();
    }
    
    markGuideDone() {
        this.setState({showGuide:false});
        this.props.dispatch(clearGuideList());
    }
    
    handleNextClicked() {
        let nextIndex = this.state.curIndex;
        while (true) { // look for an element that exists
            nextIndex++;
            if (nextIndex >= this.props.guideList.length) { // done
                this.markGuideDone();
                return;
            }
            const guidePoint = this.props.guideList[nextIndex];
            if (!this.findElement(guidePoint.element)) { // element doesn't exist ... keep looking
                continue;
            }

            this.setState({curIndex: nextIndex});// use this one
            break;
        }
        
    }

    findElement(theElement) {
        let el = undefined;
        if(theElement.startsWith(".")) { // class search -- take the first one.
            el = $(theElement)[0];
        }
        else { // id search
            el = $("#" + theElement)[0];
        }
        return(el);
    }
  
    render() {
        if(!this.state.showGuide || !this.props.guideList) return null;
        
        const guidePoint = this.props.guideList[this.state.curIndex];
        if(!guidePoint) return null;
        
        return (
            <div>
                <Bubble id="bubble" title={guidePoint.title}
                        content={guidePoint.text} 
                        relativeToId={guidePoint.element}
                        width={250}
                        onSkipClicked={this.handleSkipClicked}
                        onNextClicked={this.handleNextClicked}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        guideList: state.guideList,
    };
}

export default connect(mapStateToProps)(Guide);