import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withCookies} from 'react-cookie';
import * as PropTypes from 'prop-types';
import {sendLogoutRequest} from "../ACTIONS/clientActions";
//import Popup from "../COMMON/Popup";
import {removeClientCookies} from "../SUPPORT/setup";

class SignoutPage extends Component {
    
    componentDidMount() {
        this.props.dispatch(sendLogoutRequest())
        .then( (response) => {
            removeClientCookies(this.props.cookies);
            //Popup.show("","You are signed out", () => {
            window.location = "/";
            //});
        })
        .catch( (error) => {
            console.error("signout: "+error);
        })
    }
    
    render() {
        return (<div>
            <div className="container my-3">
                <div className="text-center">
                    ... logging you out...
                </div>
            </div>
            {/*<Popup/>*/}
            </div>);
    }
}

SignoutPage.propTypes = {   
    dispatch: PropTypes.func.isRequired,
};

export default withCookies(connect()(SignoutPage));