import $ from 'jquery';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import MainFooter from "../MainFooter";
import MainNavbar from "../MainNavbar";
import {Redirect} from "react-router";
import KSUtils from "../../SUPPORT/ks-utils";
import * as queryString from "query-string";
import Popup from '../../COMMON/Popup';
import {AESEncrypter} from '../../SUPPORT/AESHandler'
import PasswordListItem from "./PasswordListItem";
import KSReduxUtils from "../../SUPPORT/reduxHelpers";
import {addToFileUploadList} from "../../ACTIONS/clientActions";
import PasswordHintPrompt from "../../COMMON/PasswordHintPrompt";
import DrawerChooser from "../DrawerChooser";

class ViewPasswordsPage extends Component {

    constructor(props) {
        super(props);

        this.handleDoneClicked = this.handleDoneClicked.bind(this);
        this.setBlobAndSave = this.setBlobAndSave.bind(this);
        this.handleSaveClicked = this.handleSaveClicked.bind(this);
        this.handleAddPassClicked = this.handleAddPassClicked.bind(this);
        this.handleDetailsSaveClicked = this.handleDetailsSaveClicked.bind(this);
        this.handleDetailsDoneClicked = this.handleDetailsDoneClicked.bind(this);
        this.handleMoveToEditClicked = this.handleMoveToEditClicked.bind(this);
        this.handlePassHideShow = this.handlePassHideShow.bind(this);
        this.setNewPassword = this.setNewPassword.bind(this);
        this.goBackToFilesList = this.goBackToFilesList.bind(this);
        this.cancelPassClicked = this.cancelPassClicked.bind(this);
        this.showDrawerList = this.showDrawerList.bind(this);

        const values = this.props.location && queryString.parse(this.props.location.search);
        if(values && values.new) { // mark this as a new file if ?new=true
            this.newPassForm=true;
        }

        this.state = {
            redirectToLink: "",
            redirectToDashboard: false,
            fileName: "pw-" + KSUtils.getDateAsSextTuple() + ".txt", // default for new files
            passList: [],
            passListEdited: false,
            showPassDetails: undefined, // the pass we are viewing/editting details on
            editingPassDetails: false,
            searchFilter: "",
            lastSearchMatch: undefined,
            showPassPrompt: false,
            reloadFiles: false,
        };

        // not states
        this.pass="";
        this.hint="";

        this.isLocalFile = false;       // will be set with blob from parent.
    }

    componentDidMount(): void {
        if(this.props.drawerName===undefined) { // refresh?
            this.setState({redirectToLink: "/dashboard"});
            return;
        }

        if(this.newPassForm) { // this is not a brand new file we're making
            this.setState({passList: []}); // get started!
        }
        else {
            this.setPassListFromBlob();
        }
        
        // set defaults
        const drawer = KSReduxUtils.findOurDrawerGivenNumber(this.props.drawerName, this.props.myStuff);
        this.pass = drawer.pass;
        this.hint = drawer.hint && drawer.hint!==""?drawer.hint:this.props.decryptHint; // use file hint if given during decryption
    }

    setPassListFromBlob() {
        if(!this.props.textFileBlob){
            this.setState({redirectToLink: "/dashboard"});
            return;
        }

        this.isLocalFile = this.props.textFileBlob.isLocalFile;
        if(this.props.textFileBlob.text) { // use newer API
            this.props.textFileBlob.text().then((json) => {
                if (json !== "") {
                    this.setState({
                        passList: JSON.parse(json),
                        fileName: this.props.fileName,  // use original filename
                    });
                }
            });
        }
        else { // use older readAsText() API
            const reader = new FileReader();
            reader.onload = (e) => {
                const json = e.target.result;
                if (json !== "") {
                    this.setState({
                        passList: JSON.parse(json),
                        fileName: this.props.fileName,  // use original filename
                    });
                }
            };

            reader.readAsText(this.props.textFileBlob);  // UTF-8 assumed
        }
    }

    //#region HANDLERS
    handleDoneClicked() {
        this.setState({redirectToDashboard: true});
    }
    handleSaveClicked() {
        if (this.newFile || this.isLocalFile) { // ask for new filename
            Popup.showThreeButtonPrompt("File name?", this.state.fileName, 
                "Upload", "Save Local", undefined,
                (fileName) => { // upload...  
                    let drawerName = this.props.drawerName;
                    if (this.isLocalFile) {
                        this.askForDestinationDrawerAndUpload(fileName);
                        return;
                    }
                    this.isLocalFile = false; // mark for upload (NOT LOCAL) save
                    this.setBlobAndSave(fileName, drawerName);
                },
                (fileName) => { // save locally (drawer doesn't matter)
                    this.isLocalFile = true;
                    this.setBlobAndSave(fileName);
                }, "", 1);
            return;
        }

        this.setBlobAndSave(this.state.fileName, this.props.drawerName);
        this.newPassForm = false;
    }

    askForDestinationDrawerAndUpload(fileName) {
        this.isLocalFile = false;  // mark for upload (NOT LOCAL) save
        this.setState({
            showDrawerList: true,
            fileName: fileName,
        });
    }
    showDrawerList() {
        const fileName = this.state.fileName;

        return (
            <div className="container">
                <div className="container mt-3 bg-warning rounded">
                    Choose a new remote drawer for:
                    <div className="text-center mt-2"><strong>{fileName}</strong></div>
                </div>
                <DrawerChooser noDeco drawerList={this.props.myStuff.drawers} onDrawerClicked={(e,newDrawer) => this.handleDrawerClicked(e,newDrawer)}/>
            </div>
        );
    }
    handleDrawerClicked(event, drawer) {
        this.setState({showDrawerList: false});
        this.setBlobAndSave(this.state.fileName,drawer.name);  // drawer number
    }
    
    setBlobAndSave(fileName, drawerName=KSUtils.MAIN_DRAWER_MAGIC) {
        let tmpFile = new Blob([JSON.stringify(this.state.passList)]);
        tmpFile = KSReduxUtils.augmentFileBlobWithTypeString(tmpFile, fileName, AESEncrypter.FILE_TYPE_PASS);
        
        this.props.dispatch(addToFileUploadList(drawerName, tmpFile, this.pass, this.hint, this.isLocalFile));
        this.goBackToFilesList(false);
    }

    //#region RE_ENCRYPT_PASS
    setNewPassword() {
        this.setState({
            showPassPrompt: true,
            mode: "Encrypt"
        })
    }
    // this is COMMON TO ALL FORMS
    showPasswordPrompt() {
        return(
            <PasswordHintPrompt fileName={this.state.fileName} buttonText={this.state.mode} showHint={this.state.mode==="Encrypt"}
                                pass={this.pass} hint={this.hint}
                                onSetPasswordClicked={(pass,hint) => this.setPasswordClicked(pass,hint)}
                                onCancelClicked={this.cancelPassClicked}/>
        );
    }
    setPasswordClicked(pass:string,hint:string) {

        this.pass=pass;
        this.hint=hint;

        this.setState({showPassPrompt:false});
        this.setBlobAndSave(this.state.fileName,this.props.drawerName);
    }
    cancelPassClicked() {
        this.setState({showPassPrompt:false});
    }
    goBackToFilesList(reloadFilesFlag) {
        this.setState({
            redirectToDashboard: true, reloadFiles: reloadFilesFlag
        });
    }
    //#endregion
    
    handleDeletePassClicked(passItem) {
        let passList = this.state.passList;
        const updatedList = passList.filter( (item) => item!==passItem);
        this.setState({
            passListEdited: true,
            passList: updatedList
        });
    }
    handleAddPassClicked() {
        const newPass = { // bare minimal defaults here
        };
        this.setState({
            showPassDetails: newPass,   // pass details are new
            editingPassDetails: true,   // pass in edit mode
        }); // new pass object
    }
    handleSearchClicked() {
        const searchBox = $("#searchTB");
        const searchFilter = searchBox.val();
        
        let lastMatchIndex = undefined;
        if(searchFilter===this.state.searchFilter) { // searching again - same search string
            lastMatchIndex = this.state.lastSearchMatch;
        }

        if(lastMatchIndex!==undefined) { // unhighlight last
            $("#dropDown"+lastMatchIndex).removeClass("ks-search-item-match");
        }
        
        let [match,matchIndex] = this.findNextMatch(searchFilter,lastMatchIndex);
        if(match) { // have one - hilight it
            const listItem = $("#dropDown"+matchIndex);
            listItem[0].scrollIntoView({block: "start", behavior: "smooth", inline: "nearest"});
            listItem.addClass("ks-search-item-match");
        }
        else {
            Popup.show("","no matches found",() => searchBox.focus());
            
            matchIndex=undefined; // reset
        }
        
        this.setState({
            searchFilter: searchFilter,
            lastSearchMatch: matchIndex
        });
    }
    handleSearchKeyDown(e) {
        if(e.keyCode===0x0d) { // return pressed
            this.handleSearchClicked(e);
            e.stopPropagation();
        }
    }
    //#endregion
    
    findNextMatch(searchFilter,lastMatchIndex) {
        const passList = this.state.passList;
        const lowSearchFilter = searchFilter.toLowerCase();
        let foundStart=(lastMatchIndex===undefined);        // need to find the start if lastMatch is set
        const matchIndex = passList.findIndex( (item,index) => {
            if (item.site.toLowerCase().indexOf(lowSearchFilter) !== -1 ||
                item.username.toLowerCase().indexOf(lowSearchFilter) !== -1 ||
                item.password.toLowerCase().indexOf(lowSearchFilter) !== -1 ||
                item.notes.toLowerCase().indexOf(lowSearchFilter) !== -1 ) { // found a match
                if(foundStart) {
                    return true; // first match after lastMatch
                }
                if(index===lastMatchIndex) {
                    foundStart=true; // next one matches
                }
            }
            return false;
        });
        if(matchIndex===-1) { // nothing found
            return([undefined,undefined]);
        }
        return([passList[matchIndex],matchIndex]); // []
    }
    
    //#region PASSWORD_CARD_DETAILS
    
    handlePassClicked(passItem) {
        this.setState({showPassDetails:passItem})
    }
    handleMoveToEditClicked() {
        this.setState({editingPassDetails: true});
    }
    handleDetailsDoneClicked(e) {
        e.preventDefault();
        
        this.setState({
            showPassDetails: undefined,
            editingPassDetails: false
        });
    }
    handleDetailsSaveClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        const passFormDetails = this.fillPassFromForm(this.state.showPassDetails);
        this.updateOrAddPassInfo(passFormDetails);
        this.setState({
            showPassDetails: undefined, // window goes away
            passListEdited: true,       // change the main list buttons
            editingPassDetails: false,  // buttons change to 'done'
        });
    }
    handlePassHideShow() {
        const p = $("#password");
        const showHide = $("#showHideBTN");
        if(p.prop("type")==="password") { // to text
            p.prop("type","text");
            showHide.text("hide");
        }
        else { // to password
            p.prop("type", "password");
            showHide.text("show");
        }
    }
    showDetailsActionButtons() {
        if(this.state.editingPassDetails) {
            return (
                <div>
                    <button id="detailActionSaveBTN" type="submit" className="btn btn-primary mr-2" onClick={(e) => this.handleDetailsSaveClicked(e)}>Set</button>
                    <button id="detailActionCancelBTN" type="submit" className="btn btn-light" onClick={(e) => this.handleDetailsDoneClicked(e)}>Cancel</button>
                </div>
            );
        }
        return(
            <button id="detailActionDoneBTN" className="btn btn-primary" onClick={(e)=>this.handleDetailsDoneClicked(e)}>Done</button>
        );
    }
    updateOrAddPassInfo(passItem) {
        const passList = this.state.passList;
        const newPassList = passList.filter( (item) => item!==passItem);
        newPassList.push(passItem); // updated or added
        this.setState({passList: newPassList});
    }
    fillPassFromForm(passItem) {

        // update details
        passItem.site = $("#site").val();
        passItem.url = $("#url").val();
        passItem.username = $("#username").val();
        passItem.password = $("#password").val();
        passItem.notes = $("#notes").val();
        passItem.lastModified = new Date().toDateString();
        return (passItem);
    }
    showPassDetails(pass) {
        const readonly = !this.state.editingPassDetails;
        const pencilClass = "btn rounded-circle btn-outline-info ks-pointer "+(readonly?"":"d-none");
        const lastModified = !pass.lastModified?"-":pass.lastModified;
        
        return (
            <form id="passDetailsForm" className="">
                <div id="ks-centered-box-md">
                    <div className="row justify-content-between bg-light p-2 mx-1" style={{minHeight:"40px"}}>
                        <div className="ks-display-7">PASSWORD</div>
                        <div id="makeEditableBTN" className={pencilClass}
                             onClick={this.handleMoveToEditClicked}><i className="fa fa-pencil"/>
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <div className="ml-2">Site Name:</div>
                        <input type="text" id="site" className="form-control" placeholder="name or location"
                               defaultValue={pass.site} maxLength="64" disabled={readonly}/>
                    </div>
                    <div className="form-group">
                        <div className="ml-2">Web Link:</div>
                        <input type="text" id="url" className="form-control" placeholder="website address"
                               defaultValue={pass.url} maxLength="64" disabled={readonly}/>
                    </div>
                    <div className="form-group">
                        <div className="ml-2">User/Login Id:</div>
                        <input type="text" id="username" className="form-control" placeholder=""
                               defaultValue={pass.username} autoComplete="new-user-id" aria-autocomplete="none" maxLength="64" disabled={readonly}/>
                    </div>
                    <div className="form-group">
                        <div className="text-left ml-2">Password</div>
                        <div className="input-group">
                        <input type="password" id="password" maxLength="92" className="form-control" defaultValue={pass.password}
                               disabled={readonly} autoComplete="new-password" aria-autocomplete="none"/>
                            <div className="input-group-append">
                                <button className="btn btn-secondary" type="button" id="showHideBTN" onClick={this.handlePassHideShow}>show</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="text-left ml-2">Notes</div>
                        <textarea id="notes" className="form-control" defaultValue={pass.notes}
                                  disabled={readonly}/>
                    </div>
                    <div className="small text-muted text-right">last modified: {lastModified}</div>
                    <div className="row justify-content-center mt-3">
                        {this.showDetailsActionButtons()}
                    </div>
                </div>
            </form>
        );
    }
    
    //#endregion
    
    //#region PASS_LIST
    displayPasses() {

        function sortBySiteName(a,b) {
            const nameA = (a.site?a.site.toUpperCase():""); // ignore upper and lowercase
            const nameB = (b.site?b.site.toUpperCase():""); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0; // equal
        }
        
        let passesList = this.state.passList.sort(sortBySiteName); // *** SORTS ARRAY IN PLACE!
        if(!passesList || passesList.length===0) {
            return(<p className="text-center">( no passwords found )</p>);
        }

        const passes = passesList.map( (item,index) => {
            return <PasswordListItem key={index} keyName={index} site={item.site} url={item.url} username={item.username} 
                                     password={item.password} notes={item.notes} lastModified={item.lastModified} 
                                     onClick={() =>this.handlePassClicked(item)}
                                     onTrashClick={() => this.handleDeletePassClicked(item)}/>
        });

        return(
                <ul id="passwordsList" className="list-group my-3">
                    {passes}
                </ul>
        );
    }
    showPassList() {
        const drawerDesc = this.props.drawerDesc===undefined?" -":(this.props.drawerDesc===""?"MAIN":this.props.drawerDesc);

        return(
            <div>

                <div className="input-group position-fixed px-2" style={{top:"80px",zIndex:"2",width:"50%",marginLeft:"25%",left:"0",minWidth:"200px"}}>
                <input className="form-control" id="searchTB" placeholder="search" defaultValue={this.state.searchFilter} 
                       tabIndex="0" onKeyDown={(e) => this.handleSearchKeyDown(e)}/>
                    <button className="ks-btn-no-bg input-group-append" onClick={(e) => this.handleSearchClicked(e)}>
                       <span id="nextBTN" className="btn btn-success" tabIndex="1">
                        <i className="fa fa-search"/>
                       </span>
                    </button>
                </div>
              
                <div className="row justify-content-between m-3 pt-5">
                    <div>
                        <strong>Drawer</strong>: {drawerDesc}<br/>
                        <strong>File</strong>: {this.props.fileName?this.props.fileName:" -"}<br/>
                    </div>
                </div>

                <div>
                    <div className="row mx-2 justify-content-between">
                        <div className="ks-display-6">PASSWORDS</div>
                        <button className="btn rounded-circle btn-outline-info mt-3 mr-3 ks-pointer"
                             id="addPassBTN" autoFocus
                             onClick={this.handleAddPassClicked}><i className="fa fa-plus"/>
                        </button>
                    </div>
                    {this.displayPasses()}
                </div>
                {this.showActionButtons()}
            </div>
        );
    }
    showActionButtons() {
        if(this.state.passListEdited) {
            return (
                <div className="row justify-content-center mt-3">
                    <button id="saveActionBTN" className="btn btn-danger ks-pulse-button mr-2" onClick={this.handleSaveClicked}>Encrypt & Save</button>
                    <button id="cancelActionBTN" className="btn btn-light" onClick={this.handleDoneClicked}>Cancel</button>
                </div>
            );
        }
        return(
            <div className="row justify-content-around mt-3">
                <button id="setNewPassBTN" className="btn btn-light" onClick={this.setNewPassword}>Set New Password</button>
                <button id="doneActionBTN" className="btn btn-primary" onClick={this.handleDoneClicked}>Done</button>
            </div>
            
        );
    }
    //#endregion
    
    render() {
        if(this.state.redirectToDashboard!==false) {
            return <Redirect to={{
                pathname: "/dashboard",
                search: "?d="+this.props.drawerName+"&dn="+this.props.drawerDesc, // back to the current drawer
                state: { reloadFiles: this.state.reloadFiles }
            }}/>
        }
        if(this.state.redirectToLink!=="") {
            return <Redirect to={this.state.redirectToLink}/>
        }
        if(this.state.showPassPrompt) {
            return(
                <div>{this.showPasswordPrompt()}</div>
            );
        }
        if(this.state.showDrawerList) {
            return (
                <div>
                    <MainNavbar showHome/>
                    <div className="container" id="mainContainer">
                        {this.showDrawerList()}
                    </div>
                    <MainFooter/>
                </div>
            );
        }
        
        return (<div>
            <MainNavbar showHome/>
            <div className="container" id="mainContainer">
                { this.state.showPassDetails? this.showPassDetails(this.state.showPassDetails) : this.showPassList() } 
            </div>
            <MainFooter/>
        </div>);
    }
}

ViewPasswordsPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    textFileBlob: PropTypes.object,
    drawerName: PropTypes.string,
};

function mapStateToProps(state) {

    return {
        fileName: state.currentFile.fileName,
        drawerName: state.currentFile.drawerName,
        drawerDesc: state.currentFile.drawerDesc,
        decryptHint: state.currentFile.decryptHint,
        textFileBlob: state.currentFile.dataBlob,
        myStuff: state.myStuff?state.myStuff:[],
    };
}

export default connect(mapStateToProps,)(ViewPasswordsPage);