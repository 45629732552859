// things we just generally need.
import 'bootstrap/dist/css/bootstrap.min.css';     
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from "react-router-dom";
import './STYLES/index.css';
import './STYLES/App.css';
import './STYLES/bubbles.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {store} from './STORE/configureStore';
import {CookiesProvider} from "react-cookie";
import {Provider} from "react-redux";

import 'toastr';  
import './STYLES/App.css';

ReactDOM.render(
    
    <CookiesProvider>
        <Provider store={store}>
            <Router basename={process.env.PUBLIC_URL}>
                <App />
            </Router>
            
        </Provider>
    </CookiesProvider> ,
    
    
    
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
