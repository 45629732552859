/* eslint-disable */
import {AESEncrypter} from "./AESHandler";

export class ChunkedFileLoader {

    offset: number = 0;
    fileSize: number = 0;
    file:File;
    chunkSize:number=0;
    haveDataCallback:function;
    doneFlag:boolean;
    
    /**
     * Read a large file in chunked blobs and call the callback with each chunk.
     *
     * borrowed from:
     *    https://stackoverflow.com/questions/14438187/javascript-filereader-parsing-long-file-in-chunks
     *
     * @param file - the fileINfo block for the file to load
     * @param chunkSize - size of file chunks to load (bytes)
     * @param callback - called with data blocks as they are loaded (in sequence)
     */
    constructor(file: File, chunkSize: number, callback:(data: Uint8Array, error: string, doneFlag: boolean)=>void) {
        this.file=file;
        this.chunkSize=chunkSize;
        this.fileSize = file.size;
        this.offset = 0;
        this.haveDataCallback=callback;
        this.doneFlag=false;
    }
    
    _readSingleChunk(_offset:number, length:number, _file:File):void {
        const r = new FileReader();
        r["chunkedLoader"] = this;            // adding payload

        if (!_file) {
            return; // this can happen if the job has been cancelled.
        }
        const blob = _file.slice(_offset, length + _offset);
        r.onload = this._processSingleChunk;
        r.onerror = (error) => {
            const msg = "ERROR: "+error.target.error;
            this._handleDone(msg);
        };
        r.readAsArrayBuffer(blob);
    }
    
    /** private */
    _processSingleChunk(evt:Event):void {
        const thisChunkedLoaded = this.chunkedLoader; // our payload so we can ref parent.
        
        if (evt.target.error != null) {
            const msg = "ERROR: read error.  Details:" + evt.target.error;
            console.error(msg);
            this._handleDone(msg);
            return;
        }

        const numBytesRead = evt.target.result.length || evt.target.result.byteLength; // depends on type.
        if (numBytesRead === undefined || numBytesRead === 0) {
            console.debug("Done reading file (0 bytes read)");
            thisChunkedLoaded._handleDone();
            return;
        }

        // read bytes -- update consumer
        thisChunkedLoaded.offset += numBytesRead;
        thisChunkedLoaded.haveDataCallback(evt.target.result, undefined, false);  // not done, no error

        if (thisChunkedLoaded.offset >= thisChunkedLoaded.fileSize) {
            console.debug("Done reading file (at the end)");
            thisChunkedLoaded._handleDone();
            return;
        }

        // off to the next chunk
        thisChunkedLoaded._readSingleChunk(thisChunkedLoaded.offset, thisChunkedLoaded.chunkSize, thisChunkedLoaded.file);
    }

    Cancel() {
        console.debug("chunkedFileLoader(): cancelling job...");
        if(this.file) {this.file=null;}
    }
    
    _handleDone(error) {
        console.debug("Done Reading file...");
        this.haveDataCallback(new Uint8Array(0), error?error:undefined, true);  // done
        this.doneFlag=true;
    }

    /**
     * start reading the setup file.
     * @constructor
     */
    Start() {
        this._readSingleChunk(this.offset, this.chunkSize, this.file);
    }

    /** will be true if the file is done reading.
     * 
     * @returns {boolean}
     * @constructor
     */
    IsDone(): boolean {
        return(this.doneFlag);
    }
}

export class ChunkedFileSaver {

    mime = "application/octet-stream";

    chunkBlobs:Array = [];
    currentChunk:number = 0;
    finalBlob:Blob;
    
    fileName:string;

    /**
     * build a new chunkedFile handler, with the given output fileName.

     * @param fileName (optional) - can be set later using the SaveFileName property
     */
    constructor(fileName:string="") {
        this.fileName = fileName.slice(); // copy
    }

    /**
     * set the filename used for saving.
     * This can be set anytime up until the "SaveFile()" method is called
     * 
     * @param value
     * @constructor
     */
    set SaveFileName(value:string) {
        this.fileName = value;
    }
    
    Cancel() {
        this.currentChunk=0;
        this.chunkBlobs.forEach( (blob) => blob?blob=null:null); // release
        this.finalBlob?this.finalBlob=null:null;
        this.fileName=null;
    }
    
    AddChunk(chunk:Uint8Array) {
        this.chunkBlobs[this.currentChunk] = new Blob([chunk], {type: this.mime});
        //console.log('added chunk', this.currentChunk);
        this.currentChunk++;
    }
    
    SaveFile() {
        this.finalBlob = new Blob(this.chunkBlobs, {type: this.mime});
        window.saveAs(this.finalBlob, this.fileName);
    }
    
    ReturnFileAsBlob() {
        this.finalBlob = new Blob(this.chunkBlobs, {type: this.mime});
        return(this.finalBlob);
    }
    
    CreateLink(linkElementId:string) {
        const link:HTMLElement = document.getElementById(linkElementId);
        link.href = URL.createObjectURL(this.finalBlob);
    }
}

/** a hack to save a blob by making and clicking on a 
 *  temporary link on the webpage.
 *  Returns a promise.
 */
export function saveFileViaTempPageLink(saveFileName,blob) {
    
    const promise = new Promise( (resolve, error) => {
        
        if(navigator.msSaveBlob) { // IE11 and edge?
            console.log("in msSaveBlob...");
            navigator.msSaveBlob(blob, saveFileName);
        }
        else { // other browsers
            console.log("in tmp link creation...")
            
            const reader = new FileReader();
            reader.onloadend = (ev) => {

                const link = document.createElement('a');
                link.href = reader.result;
                link.download = saveFileName; //ev.target.name;

                link.style.display = 'none';
                document.body.appendChild(link);

                setTimeout(function () {
                    link.click();
                    // Cleanup the DOM 
                    document.body.removeChild(link);
                    console.log("clicked - saved to " + saveFileName);
                    resolve(`File saved to '${saveFileName}'`);
                }, 500);

                //console.log("clicking")
                //link.click();
                //console.log("clicked - saved to "+saveFileName);

                //document.body.removeChild(link);
                //resolve(`File saved to '${saveFileName}'`);

                //let clearUrl = reader.result.replace(/^data:image\/\w+;base64,/, '');
                //element.setAttribute('href', 'data:attachment/image' + base64);
                //link.setAttribute('href', 'data:application/octet-stream;base64,' + clearUrl);
                //link.setAttribute('download', 'filename.jpg');

                //link.href = window.URL.createObjectURL(blob);
                //console.log("found filename: "+ev.target.name+" and "+reader.name);
                //ev.target.name="test.png";

                //link.title = saveFileName;
                //link.target = saveFileName;
            }

            reader.readAsDataURL(blob,saveFileName); // deprecated ?
            
            //reader.readAsArrayBuffer(blob);
            //blob.fileName = saveFileName;
            //reader.name = saveFileName;

            /*
            const link = document.createElement('a');
            document.body.appendChild(link);
            //const reader = new FileReader();
            //reader.readAsDataURL(blob,saveFileName);
            link.href = window.URL.createObjectURL(blob);
            link.download = saveFileName;
            setTimeout(function() {
                     link.click();
                     // Cleanup the DOM 
                     document.body.removeChild(link);
                     resolve(`File saved to '${saveFileName}'`);
                 }, 5000);
            // link.click();
            // console.log("clicked - saved to "+saveFileßName);
            // document.body.removeChild(link);
            // resolve(`File saved to '${saveFileName}'`);
            //const file = new File([blob], saveFileName);
           
             */
            
        }
        // ### need error handler.
    });
    
    return(promise);
}

/**
 * use the file handlers and encrypters to encrypt a file in chunks
 * 
 * @param dataBlob
 * @param outFileName
 * @param pass
 * @param hint
 * @param fileType
 * @param updateEncryptProgress
 * @returns {Promise<>}
 */
export function encryptFileInChunks(dataBlob:Blob, outFileName:string, pass:string, hint:string, fileType: number, updateEncryptProgress: Function) : Promise {

    const chunkSize = 16 * 4096; // bytes - needs to be in multiples of 16 (enc block sizes)
    let _chunkedOutputFile = new ChunkedFileSaver();

    const p = new Promise((resolve, reject) => {
        let handler = null;
        try {
            handler = new AESEncrypter(outFileName, pass, hint, fileType, 128, updateEncryptProgress);
        }
        catch(e) {
            reject(e);
        }

        let _chunkedLoader = new ChunkedFileLoader(dataBlob, chunkSize, (data, error, doneFlag) => {
            if (error) {
                reject(error);
            }
            // if(_cancelEncryptionFlag) {
            //     this.cleanupEncryptionRun();
            //     reject("cancelled");
            // }
            try {
                let encBytes = handler.doEncryptIteration(data);
                //console.log(`loadFileInChunks(): got back ${encBytes.length} encrypted bytes...`);
                _chunkedOutputFile.AddChunk(encBytes);
            } catch (ex) {
                console.log("ERROR: " + ex);
                //this.cleanupEncryptionRun();
                reject(ex);
            }
            if (doneFlag) { // done encryption!
                const finalBlob = _chunkedOutputFile.ReturnFileAsBlob();  // ### entire blob reconstructed here.
                console.log("done...");
                resolve(finalBlob);
            }
        });
        _chunkedLoader.Start();
    });

    return(p);
}

