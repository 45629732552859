
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const ERRORED_AJAX_CALL = 'ERRORED_AJAX_CALL';

// clears the store
export const CLEAR_STATE_ON_LOGOUT = 'CLEAR_STATE_ON_LOGOUT';
export const COPY_TO_STORE_ON_STARTUP = "COPY_TO_STORE_ON_STARTUP";

/* FOR OUR USER */
export const SEND_SIGNIN_SUCCESS = "SEND_SIGNIN_SUCCESS";
export const SEND_SIGNOUT_SUCCESS = "SEND_SIGNOUT_SUCCESS";
export const GOT_GUIDANCE_SUCCESS = "GOT_GUIDANCE_SUCCESS";
export const CLEAR_GUIDE_LIST = "CLEAR_GUIDE_LIST";

// FILE HANDLING
export const GOT_DRAWERINFO_SUCCESS = "GOT_DRAWERINFO_SUCCESS";
export const GOT_DRAWER_PASSWORD = "GOT_DRAWER_PASSWORD";
export const SET_FILE_BLOB = "SET_FILE_BLOB";
export const ADD_TO_FILE_UPLOAD_LIST = "ADD_TO_FILE_UPLOAD_LIST";
export const REMOVE_FROM_FILE_UPLOAD_LIST = "REMOVE_FROM_FILE_UPLOAD_LIST";
export const FINISH_FILE_IN_UPLOAD_LIST = "FINISH_FILE_IN_UPLOAD_LIST";
export const REMOVE_FINISHED_IN_UPLOAD_LIST = "REMOVE_FINISHED_IN_UPLOAD_LIST";
export const SET_CURRENT_DRAWER = "SET_CURRENT_DRAWER";
export const SEND_DELETEFILE_SUCCESS = "SEND_DELETEFILE_SUCCESS";
export const SEND_ADD_DRAWER_SUCCESS = "SEND_ADD_DRAWER_SUCCESS";
export const GOT_RENAMEITEM_SUCCESS = "GOT_RENAMEITEM_SUCCESS";
export const GOT_MOVEITEM_SUCCESS = "GOT_MOVEITEM_SUCCESS";
export const ADD_FILE_TO_DRAWER_SUCCESS = "ADD_FILE_TO_DRAWER_SUCCESS";
export const SET_FILE_SORT_ORDER = "SET_FILE_SORT_ORDER";
export const SET_DRAWER_SORT_ORDER = "SET_DRAWER_SORT_ORDER";
