import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {withCookies} from 'react-cookie';
import * as queryString from "query-string";
import Footer from './MainFooter';
import {Redirect} from "react-router";
import Popup from "../COMMON/Popup";
import MainNavBasic from "./MainNavBasic";
import ClientApi from "../API/mockClientApi";
import $ from "jquery";

class ResetPassPage extends Component {

    tmpLink = "";
    
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        
        const values = this.props.location && queryString.parse(this.props.location.search);
        if (values && values.t) {
            this.tmpLink = values.t;
        }
        
        this.state= {
            redirect: false,     // redirect back to login page if reset.
            email: ""            // email address to put into the login box on good reset
        }
    }

    onSubmit(event) {
        event.stopPropagation(); // let the validation checks run

        const form = $("#resetForm")[0];
        if (form.checkValidity() === false) {
            return;
        }
        event.preventDefault();
        
        const pass1 = $("input[name='passWord']").val();
        const pass2 = $("input[name='verifyPass']").val();
        
        if(pass1!==pass2) {
            Popup.showError("Error","Passwords must match");
            return;
        }
        if(pass1.length<6) {
            Popup.showError("Error","Passwords must be longer than 6 characters");
            return;
        }
        ClientApi.doPassResetRequest(this.tmpLink, pass1)
            .then((email) => {
                Popup.show("Reset Successful",`The password for '${email}' has been reset`, () => {
                    this.setState({
                        redirect: true,
                        email: email
                    });
                });
            })
            .catch((error) => {
                Popup.showError("ERROR", error.error, () => {
                    this.setState({
                        redirect: true,
                        email: ""
                    });
                });
            });
    }
    
    render() {

        if(this.state.redirect) {
            return(
                <Redirect to={{
                    pathname: '/',
                    state: {
                        registeredUserName: this.state.email,
                        from: '/dashboard' // where we want to go after login
                    }
                }}/>
            );
        }
        
        return (
            <div>
                <MainNavBasic />
                <div className="container">
                    <p className="ks-display-7 mt-5 mb-5">Reset Password</p>
                    <form id="resetForm" className="my-3">
                        {/* satisfy browser warning */}
                        <input name="username" type="text" id="userName" autoComplete="username" hidden readOnly value=""/>
                        <div className="card m-3 ks-shadow">
                            <div className="card-body bg-light">
                                <div className="row ">
                                    <div className="col-sm-6">
                                        <label htmlFor="passWord" className="col-form-label">Password</label>
                                        <input className="form-control" name="passWord" type="password" 
                                               placeholder="at least 6 characters"
                                               autoComplete="new-password"
                                               required/>
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="verifyPass" className="col-form-label">Verify Password</label>
                                        <input className="form-control" name="verifyPass" type="password" 
                                               placeholder=""
                                               autoComplete="new-password"
                                               required/>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary offset-4 col-4 mt-4" onClick={(e) => this.onSubmit(e)}>Submit</button>
                            </div>
                        </div>
                       
                    </form>
                </div>
                <Footer atBottom/>
            </div>
        );
    }
}

ResetPassPage.propTypes = {
    location: PropTypes.object.isRequired,
    cookies: PropTypes.object.isRequired,
};


export default withCookies(ResetPassPage);
