import $ from 'jquery';
import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import MainNavbar from './MainNavbar';
import Footer from './MainFooter';
import {connect} from "react-redux";
import {
    sendGetFileListRequest, addToFileUploadList,
    setCurrentDrawer, sendDeleteFileRequest,
    sendAddDrawerRequest, sendRenameFileRequest, gotPasswordSet, setFileSortOrderTo, setDrawerSortOrderTo,
} from "../ACTIONS/clientActions";
import FileListItem from "./FileListItem";
import {Redirect} from "react-router";
import Popup from '../COMMON/Popup';
import ErrorPopup from '../COMMON/ErrorPopup';
import * as queryString from "query-string";
import KSUtils from "../SUPPORT/ks-utils";
import PasswordHintPrompt from "../COMMON/PasswordHintPrompt";
import KSReduxUtils from "../SUPPORT/reduxHelpers";
import ClientApi from "../API/mockClientApi";
import {encryptFileInChunks} from "../SUPPORT/fileHandlers";
import {AESEncrypter} from "../SUPPORT/AESHandler";
import ProgressPrompt from "../COMMON/ProgressPrompt";
import {rawurlencode} from "../SUPPORT/byteStrUtils";
import DrawerChooser from "./DrawerChooser";
import FormCommon from "../SUPPORT/FormCommon";
import Guide from "./Guide";

class DashboardPage extends Component {
    
    constructor(props) {
        super(props);

        this.handleFileClickedForDecrypt = this.handleFileClickedForDecrypt.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.addDrawerClicked = this.addDrawerClicked.bind(this);
        this.addFileClicked = this.addFileClicked.bind(this);
        this.goBack = this.goBack.bind(this);
        this.fileDropped = this.fileDropped.bind(this);
        this.setPasswordClicked = this.setPasswordClicked.bind(this);
        this.cancelPassClicked = this.cancelPassClicked.bind(this);
        this.doQuickShare = this.doQuickShare.bind(this);
        this.doLocalDecrypt = this.doLocalDecrypt.bind(this);
        this.signalUseQuickSharePath = this.signalUseQuickSharePath.bind(this);
        this.areUsingQuickSharePath = this.areUsingQuickSharePath.bind(this);

        const reloadFilesListFlag = (props.location.state && props.location.state.reloadFiles)?
            props.location.state.reloadFiles:false;
        
        // grab our desired drawer number and name (d and dn)
        const values = props.location && queryString.parse(props.location.search);
        
        this.state = {
            parentDrawer: (values.d && values.d!=="undefined"?values.d:""),    // start at passed in or toplevel drawer
            parentDrawerDesc: (values.dn && values.dn!=="undefined"?values.dn:""), // toplevel has no description
            reloadFilesListFlag: reloadFilesListFlag,
            redirectToView: "",
            redirectToShareFile: false,
            redirectToMoveFile: false,
            redirectToLocalDecrypt: false,
            fileName: "",
            filesForUpload: [],
            loadedBlob: undefined,
            step: "",                   // for showing different views
            runAfterPass: undefined,  // run this after pass is set.
            fileNameForPassPrompt: "",  // for displaying to the user
        };
        
        this.doingQuickShareFlag=false;      // signal that we need to handle dialogs for quick share
        this.doingLocalDecryptFlag=false;    // signal that we need to handle dialogs for local decrypt
    }
    
    componentDidMount() {

        this.setupDropArea();
        if(this.state.parentDrawer!=="" || this.state.reloadFilesListFlag) { // parent was already loaded at some point (refreshed in App.js)
            this.props.dispatch(sendGetFileListRequest(this.state.parentDrawer))
                .catch((error)=> {
                Popup.showError("",error.error);  
            });
        }
        
        this.props.dispatch(setCurrentDrawer(this.state.parentDrawer, this.state.parentDrawerDesc));
    }
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        this.setParentDrawerDescIfRequired();
    }
    setParentDrawerDescIfRequired() {
        if(!this.state.parentDrawerDesc) { // fill this in using redux since it wasn't passed in on refresh
            const drawer = KSReduxUtils.findOurDrawerGivenNumber(this.state.parentDrawer, this.props.topLevelDrawer);
            let desc = "Home"; // basic toplevel
            if(drawer) {
                desc = (drawer.desc===undefined?"Home":drawer.desc);
            }
            this.setState({parentDrawerDesc: desc});
        }
    }
    
    //#region BREADCRUMBS
    showBreadCrumbs() {
        
        const drawerName = this.state.parentDrawerDesc;
        if(drawerName==="Home") { // main
            return(
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li id="breadCrumbHome" className="breadcrumb-item active">Home</li>
                </ol>
            </nav>
            );
        }
        
        // in a sub-drawer
        return(
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li id="breadCrumbHome" className="breadcrumb-item btn-link ks-pointer" onClick={this.goBack}>Home</li>
                    <li className="breadcrumb-item active" aria-current={drawerName}>{drawerName}</li>
                </ol>
            </nav>
        );
    }
    goBack() {
        this.setCurrentDrawer(undefined); // parent
    }
    //#endregion
    
    //#region ADD FILE
    addFileClicked() {
        this.signalUseQuickSharePath(false);        // normal file handler path
        this.showFilePicker(true);
    }
    showFilePicker(allowMultiple, fileTypes="*") {
        const fileElement = $("#fileElem");
        fileElement.prop("multiple",allowMultiple); // allow multiple selections?
        fileElement.prop("accept",fileTypes);       // find specific kinds of files?
        fileElement.click();
    }
    handleFileSelect(e) {
        if(!e.target.files || e.target.files.length===0) return;
        let fileList = e.target.files; // FileList OBJECT! (not an array)
        
        if(this.areUsingQuickSharePath()) {
            this.getPassAndShareInfo(fileList);
        }
        else if(this.areUsingLocalDecryptPath()) {
            this.getPassAndDecryptLocal(fileList);
        }
        else { // normal file encode and upload into this drawer
            this.ensureWeHaveDestinationDrawerAndUpload(fileList);
        }
    }

    /** show a choosable list of drawers if we don't know where we are right now
     * 
     * @param fileList
     */
    ensureWeHaveDestinationDrawerAndUpload(fileList) {
        const ourDrawer = KSReduxUtils.findOurDrawerFromLocation(this.props.location,this.props.topLevelDrawer);
        if(!ourDrawer || ourDrawer === this.props.topLevelDrawer) { // at the top level or not defined ...
            this.setState({
                filesForUpload: fileList, // save list
                step: "showDrawerList",
                runAfterPass: (ourDrawer) => { // after password set CB
                    this.getPassAndEncryptForCurrentDrawer(this.state.filesForUpload, ourDrawer);
                    this.setState({step: ""}); // back to normal display
                }
            });
        }
        this.getPassAndEncryptForCurrentDrawer(fileList, ourDrawer)
    }
    /**
     * normal processing for multi-file upload for the current drawer
     */
    getPassAndEncryptForCurrentDrawer(fileList,ourDrawer) {
        if(!ourDrawer.pass || ourDrawer.pass==="") { // need a password
            const fileNameForPassPrompt = fileList?fileList.item(0).name:"??";
            this.setState({
                filesForUpload: fileList, // save list
                step: "showPassPrompt",
                fileNameForPassPrompt: fileNameForPassPrompt,
                runAfterPass: (pass,hint) => { // after password set CB
                    this.dispatchEncryptJobs(this.state.filesForUpload, this.state.parentDrawer, pass, hint);
                    this.setState({step: ""}); // back to normal display
                }
            });
        }
        else {
            this.dispatchEncryptJobs(fileList, this.state.parentDrawer, ourDrawer.pass, ourDrawer.hint);
        }
    }
    //#endregion
    
    dispatchEncryptJobs(fileList, destDrawerName, pass, hint) {
        for (let i = 0; i < fileList.length; i++) {
            if(this.props.uploadFiles.find( t => t.drawerName===destDrawerName && 
                                            t.fileInfo.name === fileList.item(i).name &&
                                            t.isFinished !==true)) { // busy file already present -- don't add
                console.log("** not adding file already being uploaded...");
                continue;
            }
            this.props.dispatch(addToFileUploadList(destDrawerName, fileList.item(i), pass, hint));
        }
        document.getElementById("fileElem").value = ""; // make sure user can pick again
    }
    
    //#region PASSWORD_PROMPT
    showPasswordPrompt() {
        return(
            <PasswordHintPrompt fileName={this.state.fileNameForPassPrompt} buttonText="Encrypt" showHint
                                pass={this.props.drawerPass} hint={this.props.drawerHint}
                                onSetPasswordClicked={(pass,hint) => this.setPasswordClicked(pass,hint)}
                                onCancelClicked={this.cancelPassClicked}/>
        );
    }
    cancelPassClicked() {
        this.setState({step: ""}); // back to normal display
    }
    setPasswordClicked(pass:string,hint:string) {
        if (!this.props.drawerPass || this.props.drawerPass === "") {
            this.props.dispatch(gotPasswordSet(this.state.parentDrawer, pass, hint)); // update redux for next use
        }
        
        const runAfterPass = this.state.runAfterPass;
        runAfterPass && runAfterPass(pass,hint); // run CB.
    }
    //#endregion
    
    //#region FILE_ACTIONS
    showFileMenu() {
        return(
            <div className="dropdown-menu-left">
                <button className="btn rounded-circle btn-outline-info mt-3 mt-sm-5 mr-3 ks-pointer" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" id="addMenuBTN"><i className="fa fa-plus"/></button>
                <div className="dropdown-menu ks-pointer" aria-labelledby="addMenuBTN">
                    <div className="dropdown-item py-2" onClick={() => this.newFileItemClicked("passForm")}><i className="fa fa-file-text-o mr-2"/>Password Form</div>
                    <div className="dropdown-item py-2" onClick={() => this.newFileItemClicked("cardForm")}><i className="fa fa-credit-card mr-2"/>Card Form</div>
                    <div className="dropdown-item py-2" onClick={() => this.newFileItemClicked("textPad")}><i className="fa fa-pencil mr-2"/>TextPad</div>
                    <div className="dropdown-divider"/>
                    <div className="dropdown-item py-2" onClick={this.addFileClicked}><i className="fa fa-upload mr-2"/>Local File</div>
                </div>
            </div>
        );

    }
    handleFileClickedForDecrypt(fileItem) {
        const safeFileName = rawurlencode(fileItem.name);  // kill bad chars

        this.setState({
            redirectToView: `/getAndDecrypt?d=${this.state.parentDrawer}&fn=${safeFileName}`
        });
    }
    newFileItemClicked(itemType) {
        let redirectToView = undefined;
        if(itemType==="passForm") {
            redirectToView = "/viewPasses?new=true";
        }
        else if(itemType==="cardForm") {
            redirectToView = "/viewCards?new=true";
        }
        else if(itemType==="textPad") {
            redirectToView = "/viewText?new=true";
        }
        else {
            throw new Error("unknown itemType "+itemType); // shouldn't happen
        }

        const ourDrawer = KSReduxUtils.findOurDrawerFromLocation(this.props.location,this.props.topLevelDrawer);
        if(!ourDrawer.pass || ourDrawer.pass==="") { // need a password
            this.setState({
                step: "showPassPrompt",
                fileNameForPassPrompt: "new file",
                runAfterPass: (pass,hint) => { // pass is set for this drawer now in redux
                    this.setState({redirectToView: redirectToView}); // back to normal display
                }
            });
        }
        else { // we know the password -- go now!
            this.setState({redirectToView: redirectToView}); // back to normal display
        }
    }
    handleDeleteFileClicked(fileItem) { // file
        const drawerName = this.state.parentDrawer;
        const drawerDesc = this.state.parentDrawerDesc;
        Popup.showConfirm("Delete File",`Really delete '${fileItem.name}' from drawer '${drawerDesc}'?`, 
            "Delete",null, 
            () => this.deleteItem(fileItem.name,drawerName));
    }
    handleRenameFileClicked(fileItem) { // file
        const drawerName = this.state.parentDrawer;
        Popup.showPrompt("Rename File",fileItem.name,"Rename",null,
            (newName) => this.renameFile(fileItem.name,drawerName,newName),1);
    }
    handleShareFileClicked(fileItem) { // file
        this.setState({
            redirectToShareFile: true,
            fileName: fileItem.name
        });
    }
    handleDownloadRawFileClicked(fileItem) {
        this.setState({
            redirectToView: `/getAndDecrypt?d=${this.state.parentDrawer}&fn=${encodeURI(fileItem.name)}&raw=true`
        });
    }
    handleCopyFileClicked(fileItem) { // file
        this.setState({
            redirectToView: `/getAndDecrypt?d=${this.state.parentDrawer}&fn=${encodeURI(fileItem.name)}&copy=true`
        });
    }
    handleMoveFileClicked(fileItem) { // file move
        this.setState({
            redirectToMoveFile: true,
            fileName: fileItem.name
        });
    }
    deleteItem(fileName,drawerName) {
        this.props.dispatch(sendDeleteFileRequest(drawerName,fileName))
            .then( () => {
                    const origThing = (fileName === "" ? "drawer" : `file '${fileName}'`);
                    Popup.show("",
                        `<p>Your ${origThing} has been deleted.</p>`);
            })
            .catch( (error) => {
                ErrorPopup.showError("Delete Error",error.error);
            });
    }
    renameFile(fileName,drawerName,newName) {
        if(KSUtils.IsFileEncryptedByExtension(fileName)) { // force rename to include .enc if original appears to be encrypted.
            newName = KSUtils.AddFileExtensionIfNeeded(newName);
        }
        this.props.dispatch(sendRenameFileRequest(drawerName,fileName,newName))
            .then( () => {
                    const origThing = (fileName==="" ? "drawer" : `file '${fileName}'`);
                    Popup.show("",
                        `<p>Your ${origThing} has been renamed to '${newName}'.</p>`);

        })
        .catch( (error) => {
            ErrorPopup.showError("Rename Error",error.error);
        });
    }
    //#endregion

    //#region DRAWER_ACTIONS
    setCurrentDrawer(drawerItem) {
        const parentDrawer = drawerItem?drawerItem.name:"";
        const parentDrawerDesc = drawerItem?drawerItem.desc:"";
        this.setState({parentDrawer});
        this.setState({parentDrawerDesc});
        this.props.dispatch(setCurrentDrawer(parentDrawer,parentDrawerDesc));
        const safeDrawerName = rawurlencode(parentDrawerDesc);  // kill bad chars
        this.props.history.push(`/dashboard?d=${parentDrawer}&dn=${safeDrawerName}`); // keep url consistent for refreshes
    }
    handleDrawerClicked(event,drawerItem) {
        this.setCurrentDrawer(drawerItem);
        this.props.dispatch(sendGetFileListRequest(drawerItem.name));
    }
    addDrawerClicked() {
        const dateStr = KSUtils.getDateAsSextTuple();
        Popup.showPrompt("Add Drawer","Drawer-"+dateStr,"Add",undefined,(drawerName) => {
            this.props.dispatch(sendAddDrawerRequest(drawerName))
                .catch( (error) => {
                    ErrorPopup.showError("Add Error", error.error);
                });
        },1);
    }
    handleDrawerTrashClicked(event,drawerItem) {
        event.stopPropagation();
        Popup.showConfirm("Delete Drawer",`Really delete drawer '${drawerItem.desc}'?`,
            "Yes, Delete",null,
            () => this.deleteItem("",drawerItem.name)); // drawer number only
    }
    handleDrawerRenameClicked(event,drawerItem) {
        event.stopPropagation();
        Popup.showPrompt("Rename Drawer",drawerItem.desc,"Rename",null,
            (newName) => this.renameFile("",drawerItem.name,newName),1);  // drawer number and drawer desc here
    }
    //#endregion

    //#region DRAG_N_DROP
    setupDropArea() {
        let dropArea = $("#ks-drop-area");
        if(dropArea[0]===undefined) {
            return;
        }
        
        if(dropArea.attr('haveListeners')!=="true") { // no registered event handlers yet (note the quotes here)
            
            // prevent default behaviours on our drag area.
            dropArea.on("dragenter", (e) => {
                e.preventDefault();
                e.stopPropagation();
                dropArea.addClass("highlight");
            });
            dropArea.on("dragover", (e) => {
                e.preventDefault();
                e.stopPropagation();
                dropArea.addClass("highlight");
            });
            dropArea.on("dragleave", (e) => {
                e.preventDefault();
                e.stopPropagation();
                dropArea.removeClass("highlight");
            });
            dropArea.on("drop", (e) => {
                e.preventDefault();
                e.stopPropagation();
                dropArea.removeClass("highlight");
                this.fileDropped(e);
            });
            
            dropArea.attr('haveListeners',"true"); // note the quotes here
        }
    }
    fileDropped(e) {
        let dataTransfer = e.originalEvent.dataTransfer;
        let files = dataTransfer.files;
        
        let ourEvent = e;
        ourEvent.target.files = files; // augment

        // function containsFiles(dTransfer) {
        //     if (dTransfer.types) {
        //         for (let i=0; i<dTransfer.types.length; i++) {
        //             if (dTransfer.types[i] === "Files") {
        //                 return true;
        //             }
        //         }
        //     }
        //
        //     return false;
        // }
        //
        // if(!containsFiles(dataTransfer)) {
        //     Popup.showError("","can't drag directories");
        //     return;
        // }
        
        this.signalUseQuickSharePath(false);    // normal flow 
        this.handleFileSelect(ourEvent);
    }
    //#endregion
    
    //#region QUICKSHARE
    signalUseQuickSharePath(useFlag) {
        this.doingQuickShareFlag=useFlag;
    }
    areUsingQuickSharePath() {
        return(this.doingQuickShareFlag);
    }
    doQuickShare() {
        this.signalUseQuickSharePath(true);     // use quick share path
        this.showFilePicker(false);
    }
    encryptQuickShare(fileInfo,pass,hint) {
        
        const p = encryptFileInChunks(fileInfo, fileInfo.name, pass, hint, AESEncrypter.FILE_TYPE_BINARY,(percent) => {
            let percentOfFile = percent / fileInfo.size * 100;
            this.setState({encryptProgress: percentOfFile});
        });
        p.then((encBytesBlob) => {
            console.log(`encrypted ${encBytesBlob.size} worth of data.`);
            this.setState({step: "showUploading"});
            ClientApi.uploadFile(KSUtils.SHARE_MAGIC,fileInfo.name,encBytesBlob,(percent) => {
                this.setState({loadingProgress: percent});
            })
                .then( (result) => {
                    console.log("DONE UPLOAD...quick Link is: "+result.quickLink);
                    this.setState({step: ""}); // back to normal

                    FormCommon.DoSharePopupWithClipCopy(result.quickLink);
                })
                .catch( ({isError, error , statusCode}) => {
                    Popup.showError("Error",error);
                });
        });
        p.catch((error) => {
            Popup.showError("Error",error);
        });
    }
    showEncryptingVisual() {
        return(
            <ProgressPrompt id="encryptProgress" actionVerb="Encrypting" fileName={this.state.fileNameForPassPrompt}
                            percent={this.state.encryptProgress} onCancelClick={this.cancelEncryptClicked}/>
        );
    }
    cancelEncryptClicked() {
        console.log("cancelling encryption run...");
        // ### TODO
        //this._cancelDecryptionFlag=true;
    }
    showLoadingVisual() {
        return(
            <ProgressPrompt id="loadingProgress" actionVerb="Loading" fileName={this.state.fileNameForPassPrompt}
                            percent={this.state.loadingProgress} onCancelClick={this.cancelLoadClicked}/>
        );
    }
    cancelLoadClicked() {
        console.log("cancelling file load...");
        // ### TODO
        //this._cancelLoadFlag=true;
    }
    /**
     * processing for quick share after single file has been selected
     * @param fileList
     */
    getPassAndShareInfo(fileList) {
        if(fileList===undefined || fileList.item===undefined || fileList.item(0)===undefined) { // ignore
            return;
        }

        const fileInfo = fileList.item(0);
        const fileNameForPassPrompt = fileInfo.name;
        this.setState({
            step: "showPassPrompt",
            fileNameForPassPrompt: fileNameForPassPrompt,
            runAfterPass: (pass,hint) => { // after password set CB
                this.setState({step: "showEncrypting"}); // back to normal display
                this.encryptQuickShare(fileInfo, pass, hint);
            }
        });
    }
    //#endregion
    
    //#region LOCAL
    signalUseLocalDecryptPath(useFlag) {
        this.doingLocalDecryptFlag=useFlag;
    }
    areUsingLocalDecryptPath() {
        return(this.doingLocalDecryptFlag);
    }
    doLocalDecrypt() {
        this.signalUseLocalDecryptPath(true);     // use quick share path
        this.showFilePicker(false,".enc");       // single file select, enc files only
    }
    getPassAndDecryptLocal(fileList) {
        if(fileList===undefined || fileList.item===undefined || fileList.item(0)===undefined) { // ignore
            return;
        }

        const fileInfo = fileList.item(0);
        this.setState({ 
            fileName: fileInfo.name,
            loadedBlob: fileInfo,
            redirectToLocalDecrypt: true
        });
    }
    //#endregion
    
    showDrawerActionHeader() {
        return(
                <div className="row justify-content-between mx-2 mt-3">
                    <div className="col-12 col-sm-9">
                        <div className="row justify-content-between">
                            <div className="btn btn-info align-self-center" id="localDecryptBTN"
                                 onClick={this.doLocalDecrypt}>
                                <i className="fa fa-pencil mr-2"/>Local Encrypted File
                            </div>
                            <div id="quickShareBTN" className="btn btn-info align-self-center" onClick={this.doQuickShare}>
                                <i className="fa fa-share mr-2"/>Quick-Share
                            </div>
                        </div>
                    </div>
                    <span className="btn btn-outline-info col-4 offset-sm-0 col-sm-2 mt-sm-0 mt-3 align-self-center" id="addDrawerBTN" 
                          onClick={this.addDrawerClicked}><i className="fa fa-plus-circle "/><i className="fa fa-folder-o ml-2"/>
                    </span>
                </div>
        );
    }
    displayDrawerSortHeader() {
        return <div className="row dropdown-menu-left justify-content-end mt-sm-4">
            <button className="page-link small mr-3" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" id="sortOrderBTN">{this.getSortOrderName()}</button>
            <div className="dropdown-menu ks-pointer" aria-labelledby="sortOrderBTN">
                <div className="dropdown-item py-2" onClick={() => this.sortDrawersBy(this.getNextSortOrderName("name"))}>name</div>
                <div className="dropdown-item py-2" onClick={() => this.sortDrawersBy(this.getNextSortOrderName("recently used"))}>recently used</div>
                {/*<div className="dropdown-item py-2" onClick={() => this.sortDrawersBy(this.getNextSortOrderName("most often used"))}>most used</div>*/}
            </div>
        </div>;
    }
    showDrawersAndFiles() {

        return(
            <div className="row mt-2">

                {this.state.parentDrawer === "" &&
                <div className="offset-md-2 col-md-8 col-12 bg-light border-top border-left border-bottom">

                    {/* SHOW DRAWERS */}
                    { this.showDrawerActionHeader()}
                    {this.displayDrawerSortHeader()}
                    <DrawerChooser drawerList={this.props.drawers}
                                   onDrawerClicked={(e,drawer) => this.handleDrawerClicked(e,drawer)}
                                   onEditClicked={(e,drawer) => this.handleDrawerRenameClicked(e,drawer)}
                                   onTrashClicked={(e,drawer) => this.handleDrawerTrashClicked(e,drawer)}/>
                </div>
                }

                {this.state.parentDrawer !== "" &&
                /* SHOW FILES */
                <div className="offset-md-2 col-md-8 col-12  bg-light border-top border-left border-bottom">
                    <div className="row justify-content-sm-between justify-content-start ml-sm-0 mr-sm-2 mt-sm-0 ml-3 mt-3">
                        <div id="ks-drop-area" className="d-none d-sm-inline text-center text-muted">
                            <span>drag &amp; drop files here<br/><span className="small">** no folders please **</span></span>
                        </div>
                        {this.showFileMenu()}
                    </div>
                    {this.setupDropArea()}
                    {this.displayFileSortHeader()}
                    {this.displayFiles(this.state.parentDrawer)}

                </div>
                }
                <input type="file" id="fileElem" accept="*" style={{display: "none"}} multiple
                       onChange={this.handleFileSelect}/>
            </div>
        );
    }
    
    //#region SORTING
    sortFilesBy(sortType) {
        this.props.dispatch(setFileSortOrderTo(sortType,this.state.parentDrawer));
    }
    sortDrawersBy(sortType) {
        this.props.dispatch(setDrawerSortOrderTo(sortType,this.state.parentDrawer));
    }
    getSortOrderName(isFileSortFlag=false) {
        let curSortOrder = (isFileSortFlag?this.props.fileSortOrder:this.props.drawerSortOrder);
        if(curSortOrder.endsWith("-d")) { // doing a desc sort
            const baseSortOrderName = curSortOrder.slice(0,-2);
            return <span className="text-muted">{baseSortOrderName}<i className="fa fa-arrow-up ml-2"/></span>;
        }
        return <span className="text-muted">{curSortOrder}<i className="fa fa-arrow-down ml-2"/></span>;
    }
    getNextSortOrderName(baseName, isFileSortFlag=false) {
        let curSortOrder = (isFileSortFlag?this.props.fileSortOrder:this.props.drawerSortOrder);
        if(curSortOrder.endsWith("-d")) { // doing a desc sort
            let baseSortOrderName = curSortOrder.slice(0, -2);
            if(baseSortOrderName !== baseName) baseSortOrderName = baseName; 
            return (baseSortOrderName);
        }
        if(curSortOrder !== baseName) curSortOrder = baseName;
        return(curSortOrder+"-d");
    }
    displayFileSortHeader() {
        return <div className="row dropdown-menu-left justify-content-end">
            <button className="page-link small mr-3" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" id="sortOrderBTN">{this.getSortOrderName(true)}</button>
            <div className="dropdown-menu ks-pointer" aria-labelledby="sortOrderBTN">
                <div className="dropdown-item py-2" onClick={() => this.sortFilesBy(this.getNextSortOrderName("name",true))}>name</div>
                <div className="dropdown-item py-2" onClick={() => this.sortFilesBy(this.getNextSortOrderName("last modified",true))}>last modified</div>
                <div className="dropdown-item py-2" onClick={() => this.sortFilesBy(this.getNextSortOrderName("size",true))}>size</div>
            </div>
        </div>;
    }
    //#endregion
    
    displayFiles(parentDrawer) {
        let childFiles = this.props.files; 
        if(parentDrawer!=="") { // not a top level -- get the kids
            const parent = this.props.drawers.find( (item) => {
                return(item.name && item.name.toString() === parentDrawer)
            });
            if(parent) {
                childFiles = parent.files;
            }
        }
        if(!childFiles || childFiles.length===0) {
            return(<div className="text-center">
                    {this.props.loading ? "loading...":"( no files found )"}
                <ul id="filesList" className="list-group my-3"/>
            </div>);
        }

        const files = childFiles.map( (item,index) => {
            return <FileListItem key={index} keyName={index} name={item.name} date={item.date} size={item.size} onClick={() =>this.handleFileClickedForDecrypt(item)}
                                 onTrashClick={() => this.handleDeleteFileClicked(item)}
                                 onRenameClicked={() => this.handleRenameFileClicked(item)}
                                 onShareClicked={() => this.handleShareFileClicked(item)}
                                 onGetRawFileClicked={() => this.handleDownloadRawFileClicked(item)}
                                 onCopyClicked={() => this.handleCopyFileClicked(item)}
                                 onMoveClicked={() => this.handleMoveFileClicked(item)}/>
        });

        return(
            <ul id="filesList" className="list-group my-3">
                {files}
            </ul>
        );
    }
    
    render() {
        if(this.props.files===undefined) { // no data from anywhere ... back to main
            console.debug(">>> redirecting to dashboard due to empty files[]");
            return(<Redirect to='/dashboard' push/>);
        }

        if(this.state.redirectToView!=="") {
            return(<Redirect to={this.state.redirectToView}/>);
        }
        if(this.state.redirectToShareFile) {
            const safeFileName = rawurlencode(this.state.fileName);
            return(<Redirect to={{
                pathname: "/doShareFile",
                search: `?d=${this.state.parentDrawer}&fn=${safeFileName}`,
                state: {}
            }} push/>);
        }
        if(this.state.redirectToMoveFile) {
            const safeFileName = rawurlencode(this.state.fileName);
            return(<Redirect to={{
                pathname: "/doMoveFile",
                search: `?d=${this.state.parentDrawer}&fn=${safeFileName}`,
                state: {}
            }} push/>);
        }
        if(this.state.redirectToLocalDecrypt) {
            const safeFileName = rawurlencode(this.state.fileName);
            const loadedBlob = this.state.loadedBlob;
            loadedBlob["isLocalFile"]=true;     // augment
            return(<Redirect to={{
                pathname: "/getAndDecrypt",
                search: `?d=${this.state.parentDrawer}&fn=${safeFileName}`,
                state: {
                    loadedBlob: this.state.loadedBlob
                }
            }} push/>);
        }
        
        return (
            <div>
                <MainNavbar showHome/>
                
                {this.state.step==="showPassPrompt" && this.showPasswordPrompt()}
                {this.state.step==="showEncrypting" && this.showEncryptingVisual()}
                {this.state.step==="showUploading" && this.showLoadingVisual()}
                
                <div className="container-fluid">
                    {this.showBreadCrumbs()}
                    {this.showDrawersAndFiles()}
                </div>
            <Footer/>
                <Guide loading={this.props.loading}/> {/* using a kicker here */}

            </div>
        );
    }
}

DashboardPage.defaultProps = {
    
};

DashboardPage.propTypes = {
    drawers: PropTypes.array,
    files: PropTypes.array, 
    dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        initialized: state.myStuff.initialized,
        loading: state.ajaxCallsInProgress > 0,
        drawers: state.myStuff ? state.myStuff.drawers : [],
        files: state.myStuff ? state.myStuff.files : [],
        topLevelDrawer: state.myStuff,
        uploadFiles: state.uploadFiles,
        fileSortOrder: state.myStuff.fileSortOrder,
        drawerSortOrder: state.myStuff.drawerSortOrder,
        
    };
}

export default connect(mapStateToProps)(DashboardPage);
