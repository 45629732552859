// @flow

/* base class for the AESEncrypter and AESDecrypter classes */
class AESHandler {

    //#region VARS
    static BIT_KEY_128 = 128;
    static BIT_KEY_192:number = 192;
    static BIT_KEY_256:number = 256;

    static HEADER_MAGIC1:number = 12345;		// magic used in header to verify password quickly
    static HEADER_MAGIC2:number = 34567;		// magic used in header to verify password quickly
    static ENCRYPT_VERSION:number = 101;		// the version of encryption that this file is using (for backward compat)
    static HEADER_VERSION_1:number = 65;		// the version of header that this file is using (for backward compat)

    static FILE_TYPE_NONE:string = "none";          // file type not applicable.
    static FILE_TYPE_TEXTPAD:string = "textpad";    // a freeform text file used to display stuff in a textarea for editting
    static FILE_TYPE_BINARY:string = "binary";      // a binary file that needs an external viewer.
    static FILE_TYPE_CCARD:string = "ccard";        // credit card formatting.
    static FILE_TYPE_PASS:string = "pass";        // password form formatting.

    static MAX_NUM_HINT_CHARS:number = 30;      // the max number of characters allowed in the hint.
    static MAX_NUM_FILENAME_CHARS:number = 120; // the max number of characters allowed in the fileNameHint stored in the header block.

    // Sbox is pre-computed multiplicative inverse in GF(2^8) used in subBytes and keyExpansion [§5.1.1]
    SBOX:Uint8Array= new Uint8Array([
        0x63, 0x7c, 0x77, 0x7b, 0xf2, 0x6b, 0x6f, 0xc5, 0x30, 0x01, 0x67, 0x2b, 0xfe, 0xd7, 0xab, 0x76, 
        0xca, 0x82, 0xc9, 0x7d, 0xfa, 0x59, 0x47, 0xf0, 0xad, 0xd4, 0xa2, 0xaf, 0x9c, 0xa4, 0x72, 0xc0, 
        0xb7, 0xfd, 0x93, 0x26, 0x36, 0x3f, 0xf7, 0xcc, 0x34, 0xa5, 0xe5, 0xf1, 0x71, 0xd8, 0x31, 0x15, 
        0x04, 0xc7, 0x23, 0xc3, 0x18, 0x96, 0x05, 0x9a, 0x07, 0x12, 0x80, 0xe2, 0xeb, 0x27, 0xb2, 0x75, 
        0x09, 0x83, 0x2c, 0x1a, 0x1b, 0x6e, 0x5a, 0xa0, 0x52, 0x3b, 0xd6, 0xb3, 0x29, 0xe3, 0x2f, 0x84, 
        0x53, 0xd1, 0x00, 0xed, 0x20, 0xfc, 0xb1, 0x5b, 0x6a, 0xcb, 0xbe, 0x39, 0x4a, 0x4c, 0x58, 0xcf, 
        0xd0, 0xef, 0xaa, 0xfb, 0x43, 0x4d, 0x33, 0x85, 0x45, 0xf9, 0x02, 0x7f, 0x50, 0x3c, 0x9f, 0xa8, 
        0x51, 0xa3, 0x40, 0x8f, 0x92, 0x9d, 0x38, 0xf5, 0xbc, 0xb6, 0xda, 0x21, 0x10, 0xff, 0xf3, 0xd2, 
        0xcd, 0x0c, 0x13, 0xec, 0x5f, 0x97, 0x44, 0x17, 0xc4, 0xa7, 0x7e, 0x3d, 0x64, 0x5d, 0x19, 0x73, 
        0x60, 0x81, 0x4f, 0xdc, 0x22, 0x2a, 0x90, 0x88, 0x46, 0xee, 0xb8, 0x14, 0xde, 0x5e, 0x0b, 0xdb, 
        0xe0, 0x32, 0x3a, 0x0a, 0x49, 0x06, 0x24, 0x5c, 0xc2, 0xd3, 0xac, 0x62, 0x91, 0x95, 0xe4, 0x79, 
        0xe7, 0xc8, 0x37, 0x6d, 0x8d, 0xd5, 0x4e, 0xa9, 0x6c, 0x56, 0xf4, 0xea, 0x65, 0x7a, 0xae, 0x08, 
        0xba, 0x78, 0x25, 0x2e, 0x1c, 0xa6, 0xb4, 0xc6, 0xe8, 0xdd, 0x74, 0x1f, 0x4b, 0xbd, 0x8b, 0x8a, 
        0x70, 0x3e, 0xb5, 0x66, 0x48, 0x03, 0xf6, 0x0e, 0x61, 0x35, 0x57, 0xb9, 0x86, 0xc1, 0x1d, 0x9e, 
        0xe1, 0xf8, 0x98, 0x11, 0x69, 0xd9, 0x8e, 0x94, 0x9b, 0x1e, 0x87, 0xe9, 0xce, 0x55, 0x28, 0xdf, 
        0x8c, 0xa1, 0x89, 0x0d, 0xbf, 0xe6, 0x42, 0x68, 0x41, 0x99, 0x2d, 0x0f, 0xb0, 0x54, 0xbb, 0x16]
    );

    // Rcon is Round Constant used for the Key Expansion [1st col is 2^(r-1) in GF(2^8)] [§5.2]
    RCON:Array<Uint8Array> = [
        new Uint8Array([0x00, 0x00, 0x00, 0x00]),
        new Uint8Array([0x01, 0x00, 0x00, 0x00]),
        new Uint8Array([0x02, 0x00, 0x00, 0x00]),
        new Uint8Array([0x04, 0x00, 0x00, 0x00]),
        new Uint8Array([0x08, 0x00, 0x00, 0x00]),
        new Uint8Array([0x10, 0x00, 0x00, 0x00]),
        new Uint8Array([0x20, 0x00, 0x00, 0x00]),
        new Uint8Array([0x40, 0x00, 0x00, 0x00]),
        new Uint8Array([0x80, 0x00, 0x00, 0x00]),
        new Uint8Array([0x1b, 0x00, 0x00, 0x00]),
        new Uint8Array([0x36, 0x00, 0x00, 0x00])
    ];


    progressFunction:function = null;           // (function) callback for updating progress

    gCurBlock:number = 0;						// (int) current block we're working on encrypting
    gInputBuffer:Uint8Array;					// (bytearray) points to our current plaintext/unencrpted byte array
    gKeySchedule:Array<Uint8Array>;				// ([])our key schedule						

    gCipherChar:Uint8Array;				// (bytearray) our encrypted output array
    gCurrentCipherBlock:Uint8Array;     // the current cipher being used (changes every *block* bytes)

    gCounterBlock:Uint8Array;			// (bytearray) holds our counter block (gBlockSize bytes used for counter mode encryption)
    gDecodedBytes:Uint8Array;			// (bytearray) holds our decrypted data (after decryption).

    gFileName:string = "";
    gHintString:string = "";
    gFileTypeAsNum:number = 0x00;
    
    isSetupForRun:boolean = false;

    gBlockSize:number = 16;             // the # of bytes that the enc/dec block is currently (set based on # of enc bits)

    totalNumEncryptedBytes:number=0;
    totalNumDecryptedBytes:number=0;
    
    gAesKeyLenBytes:number = 0;         // # of bytes int the key (different for 1218/192/256)
    gAesKeyLenWords:number = 0;         // # of words in the key 
    
    //#endregion

    /**
     * Setup an new AES async operation.  This sets up the various structs and the callback to get the
     * encryption process ready to run.  Call the start() function to kick things off.
     *
     * This expects binary data of some kind.
     *
     * @param fileName
     * @param password (string) The password to use to generate a key
     * @param hintString
     * @param fileType  (number) the type of thing this is (can be used by decryptor to open destinations)
     * @param nBits  (int)   Number of bits to be used in the key (128, 192, or 256)
     * @param progressFunctionCB
     * @returns         nothing
     */
    constructor(fileName:string, password:string, hintString:string, fileType:string, nBits:number=AESHandler.BIT_KEY_128, progressFunctionCB:function=null) {
        
        this.setupEncryptionLevel(nBits);
        
        if(!password) {
            throw new Error("Password cannot be blank");
        }

        this.progressFunction = progressFunctionCB;
        this.gFileTypeAsNum = AESHandler.convertFileType(fileType); // throws

        // use AES itself to encrypt password to get cipher key (using plain password as source for key 
        // expansion) - gives us well encrypted key
        let pwBytes =new Uint8Array(this.gAesKeyLenBytes); // byte array

        for (let i = 0; i < this.gAesKeyLenBytes; i++) {
            // this is not UTF safe
            let pwByte = password.charCodeAt(i);
            pwBytes[i]=(isNaN(pwByte) ? 0 : pwByte);
        }

        let key = this.cipher(pwBytes, this.keyExpansion(pwBytes));  // gives us gBlockSize-byte key
        if(this.gAesKeyLenBytes>16) { // augment key's 0's
            let tmp = new Uint8Array(this.gAesKeyLenBytes);
            tmp.set(new Uint8Array(key), 0);
            tmp.set(new Uint8Array(key.slice(0, this.gAesKeyLenBytes - 16)), 16);
            key = tmp;
        }
        
        this.gKeySchedule = this.keyExpansion(key);  // array

        // 200527 -- encode the filename to avoid issues with non-ascii characters.
        this.gFileName=(!fileName?"noname":encodeURI(fileName.slice(0,AESHandler.MAX_NUM_FILENAME_CHARS))); 
        this.gHintString=(!hintString?"":encodeURI(hintString.substr(0,AESHandler.MAX_NUM_HINT_CHARS)));

        this.Reset();
    }

    //#region ACCESSORS

    /** testing only. */
    get _CurrentCipherBlock() {
        return(this.gCurrentCipherBlock);
    }

    /**
     * return the file type that this encryption object is holding.
     * 
     * @returns {string}
     * @constructor
     */
    get FileType() {
        return(AESHandler.convertFileTypeToString(this.gFileTypeAsNum));
    }

    //#endregion

    /**
     * reset this AESHandler for a new job.
     * NOTE: you need to reset the object before switching from encode to decode, OR starting a new job of either
     * type.
     *
     * @constructor
     */
    Reset() {
        this.gCurBlock=0;
        this.isSetupForRun=false;
        this.totalNumDecryptedBytes=0;
        this.totalNumEncryptedBytes=0;
    }

    //#region HELPER_FUNCTIONS
    
    setupEncryptionLevel(nBits:number):void {
        
        if (!(nBits === AESHandler.BIT_KEY_128 || nBits === AESHandler.BIT_KEY_192 || nBits === AESHandler.BIT_KEY_256)) {
            // standard allows 128/192/256 bit keys
            throw new Error("Must be a key mode of either 128, 192, 256 bits");
        }
        
        this.gBlockSize=16; // constant for AES

        switch(nBits) {
            case(AESHandler.BIT_KEY_128):
                this.gAesKeyLenBytes = nBits / 8;    // 16 bytes
                break;
            case(AESHandler.BIT_KEY_192):
                this.gAesKeyLenBytes = nBits / 8;    // 24 bytes
                break;
            case(AESHandler.BIT_KEY_256):
                this.gAesKeyLenBytes = nBits / 8;    // 32 bytes
                break;
            default:
                throw new Error("Must be a key mode of either 128, 192, 256 bits");
        }
    }

    convertToIntArray(inInt:number): Uint8Array {
        let outArray = new Uint8Array(4);

        outArray[0]=(inInt & 0xff000000) >> 24;
        outArray[1]=(inInt & 0x00ff0000) >> 16;
        outArray[2]=(inInt & 0x0000ff00) >> 8;
        outArray[3]=(inInt & 0x000000ff);

        return(outArray);
    }

    readIntFromArray(inArray:Uint8Array): number {

        let part1 = inArray[0] << 24;
        let part2 = inArray[1] << 16;
        let part3 = inArray[2] << 8;
        let part4 = inArray[3];

        const result = part1 | part2 | part3 | part4;
        return(result);
    }

    getBytesAsCharString(inBytes:Uint8Array):string {
        let outString="";
        inBytes.forEach( (c) => {
            if(c===0) return;  // skip null bytes
            let thing = String.fromCharCode(c);
            outString +=thing;
        });
        return(outString);
    }

    cipher(input:Uint8Array, w:Array<Uint8Array>): Uint8Array {
        // main cipher function [§5.1]
        //input.position = 0;

        let Nb:number = 4;               // block size (in words): no of columns in state (fixed at 4 for AES)
        let Nr:number = w.length / Nb - 1; // no of rounds: 10/12/14 for 128/192/256-bit keys

        let state:Array<Uint8Array> = [
            new Uint8Array(Nb),
            new Uint8Array(Nb),
            new Uint8Array(Nb),
            new Uint8Array(Nb)
        ] ;  // initialise 4xNb byte-array 'state' with input [§3.4]
        for (let i:number = 0; i < 4 * Nb; i++) state[i % 4][Math.floor(i / 4)] = input[i];

        state = this.addRoundKey(state, w, 0, Nb);

        for (let round:number = 1; round < Nr; round++) {
            state = this.subBytes(state, Nb);
            state = this.shiftRows(state, Nb);
            state = this.mixColumns(state);
            state = this.addRoundKey(state, w, round, Nb);
        }

        state = this.subBytes(state, Nb);
        state = this.shiftRows(state, Nb);
        state = this.addRoundKey(state, w, Nr, Nb);

        let output:Uint8Array = new Uint8Array((4 * Nb)); // convert state to 1-d array before returning [§3.4]
        for (let i = 0; i < 4 * Nb; i++) output[i] = state[i % 4][Math.floor(i / 4)];

        return output;
    }

    keyExpansion(key:Uint8Array): Array<Uint8Array> {
        // generate Key Schedule (byte-array Nr+1 x Nb) from Key [§5.2]
        let Nb:number = 4;            // block size (in words): no of columns in state (fixed at 4 for AES)
        let Nk:number = key.length / 4;  // key length (in words): 4/6/8 for 128/192/256-bit keys
        let Nr:number = Nk + 6;       // no of rounds: 10/12/14 for 128/192/256-bit keys

        let w:Array<Uint8Array> = new Array(Nb * (Nr + 1));
        let temp:Uint8Array = new Uint8Array(4);

        for (let i = 0; i < Nk; i++) {
            w[i] = Uint8Array.from([key[4 * i], key[4 * i + 1], key[4 * i + 2], key[4 * i + 3]]);
        }

        for (let i = Nk; i < (Nb * (Nr + 1)); i++) {
            w[i] = new Uint8Array(4);
            for (let t = 0; t < 4; t++) temp[t] = w[i - 1][t];
            if (i % Nk === 0) {
                temp = this.subWord(this.rotWord(temp));
                for (let t = 0; t < 4; t++) temp[t] ^= this.RCON[i / Nk][t];
            } else if (Nk > 6 && i % Nk === 4) {
                temp = this.subWord(temp);
            }
            for (let t = 0; t < 4; t++) w[i][t] = w[i - Nk][t] ^ temp[t];
        }

        return w;
    }

    subBytes(s:Array<Uint8Array>, Nb:number): Array<Uint8Array> {
        // apply SBox to state S [§5.1.1]
        for (let r = 0; r < 4; r++) {
            for (let c = 0; c < Nb; c++) s[r][c] = this.SBOX[s[r][c]];
        }

        return s;
    }

    shiftRows(s:Array<Uint8Array>, Nb:number): Array<Uint8Array> {
        // shift row r of state S left by r bytes [§5.1.2]
        let t:Uint8Array = new Uint8Array(4);
        for (let r = 1; r < 4; r++) {
            for (let c = 0; c < 4; c++) t[c] = s[r][(c + r) % Nb];  // shift into temp copy
            for (let c = 0; c < 4; c++) s[r][c] = t[c];         // and copy back
        } // note that this will work for Nb=4,5,6, but not 7,8 (always 4 for AES):

        return s;  // see asmaes.sourceforge.net/rijndael/rijndaelImplementation.pdf
    }

    mixColumns(s:Array<Uint8Array>): Array<Uint8Array> {
        // combine bytes of each col of state S [§5.1.3]
        for (let c = 0; c < 4; c++) {
            let a:Uint8Array = new Uint8Array(4);  // 'a' is a copy of the current column from 's'
            let b:Uint8Array = new Uint8Array(4);  // 'b' is a•{02} in GF(2^8)
            for (let i = 0; i < 4; i++) {
                a[i] = s[i][c];
                b[i] = ((s[i][c] & 0x80) !== 0) ? (s[i][c] << 1) ^ 0x011b : s[i][c] << 1;
            }
            // a[n] ^ b[n] is a•{03} in GF(2^8)
            s[0][c] = b[0] ^ a[1] ^ b[1] ^ a[2] ^ a[3]; // 2*a0 + 3*a1 + a2 + a3
            s[1][c] = a[0] ^ b[1] ^ a[2] ^ b[2] ^ a[3]; // a0 * 2*a1 + 3*a2 + a3
            s[2][c] = a[0] ^ a[1] ^ b[2] ^ a[3] ^ b[3]; // a0 + a1 + 2*a2 + 3*a3
            s[3][c] = a[0] ^ b[0] ^ a[1] ^ a[2] ^ b[3]; // 3*a0 + a1 + a2 + 2*a3
        }

        return s;
    }

    addRoundKey(state:Array<Uint8Array>, w:Array<Uint8Array>, rnd:number, Nb:number): Array<Uint8Array> {
        // xor Round Key into state S [§5.1.4]
        for (let r = 0; r < 4; r++) {
            for (let c = 0; c < Nb; c++) {
                let value = w[rnd*4+c];
                state[r][c] ^= value[r];
            }
        }

        return state;
    }

    subWord(w:Uint8Array): Uint8Array {
        // apply SBox to 4-byte word w
        for (let i = 0; i < 4; i++) w[i] = this.SBOX[w[i]];

        return w;
    }

    rotWord(w:Uint8Array): Uint8Array {
        // rotate 4-byte word w left by one byte
        let tmp:number = w[0];
        for (let i = 0; i < 3; i++) w[i] = w[i + 1];
        w[3] = tmp;

        return w;
    }

    static convertFileType(fileTypeStr:string):number {
        switch(fileTypeStr) {
            case(AESHandler.FILE_TYPE_NONE): return(0x00);
            case(AESHandler.FILE_TYPE_TEXTPAD): return(0x01);
            case(AESHandler.FILE_TYPE_BINARY): return(0x02);
            case(AESHandler.FILE_TYPE_CCARD): return(0x03);
            case(AESHandler.FILE_TYPE_PASS): return(0x04);
            default: throw new Error("unknown file type "+fileTypeStr);
        }
    }
    
    static convertFileTypeToString(fileTypeNum:number):string {
        switch(fileTypeNum) {
            case(0x00): return(AESHandler.FILE_TYPE_NONE);
            case(0x01): return(AESHandler.FILE_TYPE_TEXTPAD);
            case(0x02): return(AESHandler.FILE_TYPE_BINARY);
            case(0x03): return(AESHandler.FILE_TYPE_CCARD);
            case(0x04): return(AESHandler.FILE_TYPE_PASS);
            default: throw new Error("unknown file type "+fileTypeNum);
        }
    }
     
    //#endregion
}

export class AESEncrypter extends AESHandler {

    /**
     * 
     * @param fileName - the name to store this file as when unencrypted (blank == "noname")
     * @param password - the password used to encrypt the file (also needed to decrypt)
     * @param hintString - any hint to give the decrypter (blank == max security).  Up to 30 chars.
     * @param fileType - the type of thing that this object is (see FILE_TYPE_xxx class vars).  Default FILE_TYPE_BINARY
     * @param nBits
     * @param progressFunctionCB
     */
    constructor(fileName:string, password:string, hintString:string, fileType:string=AESEncrypter.FILE_TYPE_BINARY, 
                nBits:number=AESHandler.BIT_KEY_128, progressFunctionCB:function=null) {
        super(fileName,password,hintString,fileType,nBits,progressFunctionCB);
    }

    //#region ACCESSORS
    get EncryptedBytes() {
        return (this.gCipherChar);
    }
    get TotalNumEncryptedBytes() {
        return(this.totalNumEncryptedBytes);
    }
    //#endregion

    /**
     * Returns: the header to be prepended to the encrypted file bytes.
     *
     * NOTE: the header is in block sizes since all encryption happens in block lengths.
     */
    buildHeaderBlock(fileName:string): Uint8Array
    {
        let numFileNameBlocks:number = Math.ceil(fileName.length/this.gBlockSize); // round up to BLOCK size

        // header length (16 BYTES) = 
        //              4 magic 1, 
        //              4 magic 2, 
        //              1 encryptVersion, 
        //              1 fileType, 
        //              2 future expansion, 
        //              4 dedicated to length in blocks of filename part
        //              + filename (block aligned)
        let retArray:Uint8Array = new Uint8Array(4+4+4+4+(numFileNameBlocks*this.gBlockSize)); // contained in block boundaries!
        let outBufferIndex=0;

        //console.log("addHeaderBlock(): header block is "+retArray.byteLength+" bytes (before encryption)");

        // write the magic number (1 BLOCK in size)
        retArray.set(this.convertToIntArray(AESHandler.HEADER_MAGIC1),outBufferIndex);		// 4 bytes
        outBufferIndex+=4;
        retArray.set(this.convertToIntArray(AESHandler.HEADER_MAGIC2),outBufferIndex);		// 4 bytes
        outBufferIndex+=4;
        retArray[outBufferIndex++]=AESHandler.ENCRYPT_VERSION;	                            // 1 byte
        retArray[outBufferIndex++]=this.gFileTypeAsNum;	                                // 1 byte
        retArray[outBufferIndex++]=0x00;	                                            // 1 byte - future
        retArray[outBufferIndex++]=0x00;	                                            // 1 byte - future
        retArray.set(this.convertToIntArray(numFileNameBlocks),outBufferIndex);		        // 4 bytes //(numFileNameBlocks);
        outBufferIndex+=4;

        // write the filename string (no prefix) -- 0 padded
        for(let i=0;i<fileName.length;i++) {
            retArray[outBufferIndex++]=fileName.charCodeAt(i);
        }

        return(retArray);
    }
    
    /**
     * do a single encryption block run with the header info as being the only stuff to encrypt
     * the result is added to the cyperBytes.
     *
     * Returns the encrypted header block bytes
     */
    encryptHeaderBlock(headerBlock:Uint8Array):Uint8Array
    {
        let headerByteReadIndex=0;
        const encHeaderBuffer = new Uint8Array(headerBlock.byteLength);
        let encHeaderBufferWorkIndex=0;

        this.gCurrentCipherBlock = this.getEncryptCipherCounterBlock();

        // write out standard gBlockSize byte header block (version, #header bytes etc).
        for (let i= 0;i < this.gBlockSize;i++) { // -- xor plaintext with ciphered counter char-by-char --
            encHeaderBuffer[encHeaderBufferWorkIndex++]= (this.gCurrentCipherBlock[i] ^ headerBlock[headerByteReadIndex++]);
        }

        // write out the filename blocks
        while(headerBlock.byteLength - headerByteReadIndex>1) {
            this.gCurrentCipherBlock = this.getEncryptCipherCounterBlock();

            for (let i= 0;i < this.gBlockSize;i++)
            {  // -- xor plaintext with ciphered counter char-by-char --
                encHeaderBuffer[encHeaderBufferWorkIndex++]=(this.gCurrentCipherBlock[i] ^ headerBlock[headerByteReadIndex++]);
            }
        }

        return(encHeaderBuffer);
    }

    /**
     * returns the encrypted header block
     */
    _setupEncryptionRun():Uint8Array {

        this.gCounterBlock=this.buildFirstHalfOfCounterBlock();
        
        const hintLength = (this.gHintString===null || this.gHintString==="")?0:this.gHintString.length;

        let basicHeaderSize = 1+1+1+hintLength+this.gBlockSize/2; // 1==headerVersion,1==hintLen,1==padLen,hintBytes,8==counterBlock
        let paddingSize = this.gBlockSize - basicHeaderSize%this.gBlockSize;
        if(paddingSize===this.gBlockSize) paddingSize=0; // no filler bytes needed

        // build the encrypted part of the header
        let headerBlock:Uint8Array = this.buildHeaderBlock(this.gFileName); // block-length multiple returned
        const encHeaderBytes = this.encryptHeaderBlock(headerBlock);  // header block (not version or hint) encrypted
        const encHeaderBlock = new Uint8Array(basicHeaderSize+paddingSize+headerBlock.byteLength); // header block now setup on block boundaries

        // write out HEADER VERSION number and HINT (or not)
        let encHeaderBlockIndex=0;
        encHeaderBlock[encHeaderBlockIndex++]=AESHandler.HEADER_VERSION_1;
        encHeaderBlock[encHeaderBlockIndex++]=hintLength;
        encHeaderBlock[encHeaderBlockIndex++]=paddingSize;      // added 191121
        if(hintLength>0)
        { //scramble the hint and add to final header block
            let scrambledHint:Uint8Array = this.hintScramble(this.gHintString);
            encHeaderBlock.set(scrambledHint,encHeaderBlockIndex);
            encHeaderBlockIndex+=scrambledHint.byteLength;
        }
        // copy the first 8 bytes from the counter block into our cypher for use by decoder
        encHeaderBlock.set(this.gCounterBlock.subarray(0,8),encHeaderBlockIndex);
        encHeaderBlockIndex+=8;

        encHeaderBlock.set(encHeaderBytes,encHeaderBlockIndex);
        encHeaderBlockIndex+=encHeaderBytes.byteLength;

        // add filler
        let fillerBytes = this._generatePaddingBytes(paddingSize);
        encHeaderBlock.set(fillerBytes,encHeaderBlockIndex);

        //console.log(`header block (encoded with header and hint) is ${encHeaderBlockIndex} bytes.`);

        this.isSetupForRun=true; // we're now setup for iterations

        return(encHeaderBlock);
    }

    _generatePaddingBytes(numBytes:number) {
        let paddingBuf = new Uint8Array(numBytes);
        while(numBytes-->0) {
            paddingBuf[numBytes]=Math.floor(Math.random() * 0xff);
        }
        return(paddingBuf);
    }

    /**
     * encrypts the given bytes and returns a buffer to the encoded chunk of blocks.
     * This will send a progress event every gBlockSize*1000 bytes that it processes.
     *
     * @param data
     */
    doEncryptIteration(data:Uint8Array):Uint8Array {

        this.gInputBuffer = new Uint8Array(data);   // important to cast the ByteArray to a TypedArray!!! -pott
        const dataLength = data.length || data.byteLength; // depends on type of data
        let sourceByteIndex=0;
        let encBufferWriteIndex=0;
        
        if(this.isSetupForRun===false) {
            const encHeaderBlock = this._setupEncryptionRun();
            this.gCipherChar = new Uint8Array(encHeaderBlock.byteLength + dataLength); // special size
            this.gCipherChar.set(encHeaderBlock,0); // always at the start.
            encBufferWriteIndex=encHeaderBlock.byteLength;
            this.totalNumEncryptedBytes=encBufferWriteIndex;
        }
        else {
            if(this.gCipherChar.byteLength !== dataLength) {
                this.gCipherChar = new Uint8Array(dataLength); // don't build new buffers always.
            }
        }

        while(sourceByteIndex<dataLength) {

            let blockLength:number=this.gBlockSize; // normal
            dataLength-sourceByteIndex<this.gBlockSize ? blockLength=dataLength-sourceByteIndex : blockLength=this.gBlockSize;
            for (let i= 0,counterBlockOffset = this.totalNumEncryptedBytes%this.gBlockSize; i < blockLength; i++) {

                if(counterBlockOffset%this.gBlockSize===0) { // new cypher block every blockLength of input bytes
                    this.gCurrentCipherBlock = this.getEncryptCipherCounterBlock();
                    this.gCurBlock++;
                }

                const inValue:number = this.gInputBuffer[sourceByteIndex++];
                this.gCipherChar[encBufferWriteIndex++]=this.gCurrentCipherBlock[counterBlockOffset++] ^ inValue;
                this.totalNumEncryptedBytes++;
            }

            if (this.gCurBlock % 1000 === 0) { // update every 100 blocks
                if(this.progressFunction) this.progressFunction(this.gCurBlock*this.gBlockSize); // report progress in blocks
            }
        }

        return(this.EncryptedBytes);
    }

    //#region HELPERS
    getEncryptCipherCounterBlock() {
        // set counter (block #) in last 8 bytes of counter block (leaving nonce in 1st 8 bytes)
        // done in two stages for 32-bit ops: using two words allows us to go past 2^32 blocks (68GB)
        for (let c = 0;c < 4;c++) this.gCounterBlock[15 - c] = (this.gCurBlock >>> c * 8) & 0xff;
        for (let c = 0;c < 4;c++) this.gCounterBlock[15 - c - 4] = (this.gCurBlock / 0x100000000 >>> c * 8);
        let cipherCntr = this.cipher(this.gCounterBlock, this.gKeySchedule);  // -- encrypt counter block --
        return(cipherCntr);
    }
    buildFirstHalfOfCounterBlock() {

        // initialise counter block (NIST SP800-38A §B.2): millisecond time-stamp for nonce in 1st 8 bytes,
        // block counter in 2nd 8 bytes
        const counterBlock:Uint8Array = new Uint8Array(this.gBlockSize);
        let nonce = (new Date()).getTime();  // timestamp: milliseconds since 1-Jan-1970 
        let nonceSec = Math.floor(nonce / 1000);
        let nonceMs = nonce % 1000;

        // encode nonce with seconds in 1st 4 bytes, and (repeated) ms part filling 2nd 4 bytes
        for (let i = 0; i < 4; i++) counterBlock[i] = (nonceSec >>> i * 8) & 0xff;
        for (let i = 0; i < 4; i++) counterBlock[i + 4] = (nonceMs >>> i * 8) & 0xff;

        return(counterBlock);
    }
    

    /**
     * do a simple scramble of hint to it can't be super easily snooped (this is NOT encrypted though)
     *
     */
    hintScramble(rawHint:string):Uint8Array
    {
        if(rawHint==null) return new Uint8Array(0);

        let retByteArray:Uint8Array = new Uint8Array(rawHint.length);
        let writeByteIndex=0;

        for(let i=0;i<rawHint.length;i++)
        { // do for each char - this assumes byte chars
            let nibLow:number = rawHint.charCodeAt(i);
            nibLow = nibLow  & 0x000f;
            let nibHigh:number = (rawHint.charCodeAt(i) >> 4);
            nibHigh = nibHigh & 0x000f;

            retByteArray[writeByteIndex++]=nibHigh | (nibLow << 4);
        }
        return(retByteArray);
    }
    //#endregion
}

export class AESDecrypter extends AESHandler {

    /**
     *
     * @param password - the password used to encrypt the file (also needed to decrypt)
     * @param nBits
     * @param progressFunctionCB
     */
    constructor(password: string, nBits: number = AESHandler.BIT_KEY_128, progressFunctionCB: function = null) {
        super("", password, "", AESHandler.FILE_TYPE_NONE, nBits, progressFunctionCB);
    }

    //#region ACCESSORS
    get DecryptedBytes() {
        return (this.gDecodedBytes);
    }

    /**
     * any password hint that was stored in the file (may be "" if none was stored)
     *
     * @returns {string}
     * @constructor
     */
    get DecryptHint() {
        return (this.gHintString);
    }

    /**
     * the filename that was found for this file while decrypting it
     *
     * @returns {string|*}
     * @constructor
     */
    get DecryptedFileName() {
        return (this.gFileName);
    }

    /** the type of thing that has been decrypted (ie. textpad doc, binary, etc)
     *  You can use this to decide what to do with the contents).
     * @constructor
     */
    get DecryptedFileType() {
        return(this.gFileTypeAsNum);
    }
    
    get TotalNumDecryptedBytes() {
        return(this.totalNumDecryptedBytes);
    }
    //#endregion

    /**
     * read the hint and header blocks from the encrypted bytes array and decrypt it.
     * Returns: the number of bytes read from the input buffer in processing the header block
     *
     */
    decryptHeaderBlock(headerStartOffset:number):number
    {
        let headerBlockWriteByteIndex=0;
        let encBytesReadIndex=headerStartOffset;

        const cipherCB = this.getDecryptCipherCounterBlock();

        // first block is the magic, encryptVersion, fileType, future, and the number of blocks following for the filename
        let headerBlock:Uint8Array = new Uint8Array(this.gBlockSize);  // basic header block length
        for (let i= 0;i < this.gBlockSize;i++)
        { // -- xor plaintxt with ciphered counter byte-by-byte --
            let byteVal:number = cipherCB[i];
            let textByte:number = this.gInputBuffer[encBytesReadIndex++];
            headerBlock[headerBlockWriteByteIndex++]= (byteVal ^ textByte);
        }

        const headerBlockMagic1 = this.readIntFromArray(headerBlock.slice(0,4));
        const headerBlockMagic2 = this.readIntFromArray(headerBlock.slice(4,8));
        if(headerBlockMagic1!==AESHandler.HEADER_MAGIC1 || headerBlockMagic2!==AESHandler.HEADER_MAGIC2)
        { // header magic mismatch
            console.log("ERROR: decryptHeaderBlock(): header magic mismatch...likely a bad password");
            throw new Error("bad password [10001]");
        }
        let encryptVersion:number = headerBlock[8];
        if(encryptVersion!==AESHandler.ENCRYPT_VERSION)
        { // this file has been encrypted with a different version than what we have
            console.log("ERROR: decryptHeaderBlock(): Old Encryption Version found ["+encryptVersion+"]");
            throw new Error(`unsupported encryption version '${encryptVersion}'`);
        }

        this.gFileTypeAsNum = headerBlock[9]; 
        AESDecrypter.convertFileTypeToString(this.gFileTypeAsNum); // throws if bad.

        // future expansion - headerBlock[10],headerBlock[11]
        
        // get the number of blocks in the filename
        let numFileNameBlocks:number = this.readIntFromArray(headerBlock.slice(12,16));
        //console.log("decryptHeaderBlock(): there are "+numFileNameBlocks+" blocks in filename... reading");

        // decrypt the blocks containing the filename
        let fileNameBlock:Uint8Array = new Uint8Array(numFileNameBlocks*this.gBlockSize);
        let fileNameBlockWriteIndex=0;
        for(let blockNum=0; blockNum<numFileNameBlocks; blockNum++)
        { // read and decrypt each block
            // set counter (block #) in last 8 bytes of counter block (leaving nonce in 1st 8 bytes)
            const cipherCB = this.getDecryptCipherCounterBlock();
            for (let i = 0;i < this.gBlockSize;i++)
            { // -- xor plaintxt with ciphered counter byte-by-byte --
                let byteVal = cipherCB[i];
                let textByte = this.gInputBuffer[encBytesReadIndex++];
                fileNameBlock[fileNameBlockWriteIndex++]=(byteVal ^ textByte);
            }
        }

        this.gFileName=decodeURI(this.getBytesAsCharString(fileNameBlock)); // rest goes into the filename
        console.debug("filename found in header is ["+this.gFileName+"]");

        return(encBytesReadIndex-headerStartOffset); // just the ones that we read
    }

    /**
     * returns the number of bytes read from the encyrpted stream during header parsing.
     *
     * (throws if there was a problem with the header).
     *
     */
    _setupDecryptionRun():number {

        let encBufferReadIndex:number=0;    // restarting at the beginning.

        // read header version and hint.
        let headerVersion:number = this.gInputBuffer[encBufferReadIndex++];
        if(headerVersion!==AESHandler.HEADER_VERSION_1) { // unsupported header type
            throw new Error(`unsupported header version ${headerVersion}`);
        }

        let numHintBytes:number = this.gInputBuffer[encBufferReadIndex++];
        let paddingSize:number = this.gInputBuffer[encBufferReadIndex++];
        if(numHintBytes!==0)
        { // there's a hint here -- get it
            let scrambledHint:Uint8Array = new Uint8Array(numHintBytes);
            scrambledHint.set(this.gInputBuffer.subarray(encBufferReadIndex,encBufferReadIndex+numHintBytes),0);
            encBufferReadIndex+=numHintBytes;
            this.gHintString=decodeURI(this.hintUnScramble(scrambledHint));  // handle non-ascii characters
        }

        // recover nonce from 1st 8 bytes of ciphertext (next 8 bytes)
        // read first 8 byte nonce so we can setup the cypher
        this.gCounterBlock = new Uint8Array(this.gBlockSize); // needs to work out to 16 for now
        this.gCounterBlock.set(this.gInputBuffer.subarray(encBufferReadIndex,encBufferReadIndex+this.gBlockSize/2),0);  // upper half not included
        encBufferReadIndex+=this.gBlockSize/2;

        // remove and decrypt the header block from our data
        let headerBlockSizeBytes = this.decryptHeaderBlock(encBufferReadIndex);
        if(headerBlockSizeBytes===0)
        { // problem with header -- likely a password issue
            // reset for another try
            throw new Error("problem with header block...password?");
        }
        encBufferReadIndex+=headerBlockSizeBytes+paddingSize; // skip padding

        this.gCurBlock = 0;
        this.isSetupForRun=true; // we're now setup for iterations

        //console.log(`read ${encBufferReadIndex} bytes from the header (leaving ${bytesLeft} to work on`);

        return(encBufferReadIndex);
    }

    /**
     * returns the decoded bytes in the chunk
     * This will send a progress event every gBlockSize*1000 bytes that it processes.
     *
     * @param data
     */
    doDecryptIteration(data:Uint8Array):Uint8Array {

        this.gInputBuffer = new Uint8Array(data);          // important to cast the ByteArray to a TypedArray!!! -pott
        let dataLength = data.length || data.byteLength; // depends on type.
        let decodedWriteIndex=0;
        let encBufferReadIndex=0;

        if(dataLength===0) {
            throw new Error('0 length data provided.');
        }

        if(this.isSetupForRun===false) {
            const numBytesInHeader = this._setupDecryptionRun(); // throws
            encBufferReadIndex+=numBytesInHeader;
            dataLength-=numBytesInHeader;               // there is actually only this many bytes to decode (header-)
            this.gDecodedBytes = new Uint8Array(dataLength);
            this.totalNumDecryptedBytes=0;
        }
        else {
            if(dataLength !== this.gDecodedBytes.length) { // resize if needed
                this.gDecodedBytes = new Uint8Array(dataLength);
            }
        }

        while(decodedWriteIndex < dataLength) {

            let blockLength:number=this.gBlockSize; // normal
            dataLength-decodedWriteIndex<this.gBlockSize ? blockLength=dataLength-decodedWriteIndex : blockLength=this.gBlockSize;
            for (let i= 0,counterBlockOffset = this.totalNumDecryptedBytes%this.gBlockSize; i < blockLength; i++) {

                if(counterBlockOffset%this.gBlockSize===0) { // new cypher block every blockLength of input bytes
                    this.gCurrentCipherBlock = this.getDecryptCipherCounterBlock();
                    this.gCurBlock++;
                }

                let byteVal:number = this.gCurrentCipherBlock[counterBlockOffset++];
                let textByte:number = this.gInputBuffer[encBufferReadIndex++];
                this.gDecodedBytes[decodedWriteIndex++]=(byteVal ^ textByte); // -- xor plaintxt with ciphered counter byte-by-byte --
                this.totalNumDecryptedBytes++;
            }

            if (this.gCurBlock % 1000 === 0) { // update every gCurBlockSize*1000 bytes 
                if(this.progressFunction) this.progressFunction(this.gCurBlock*this.gBlockSize);
            }
        }

        return(this.DecryptedBytes);
    }
    
    //#region HELPERS
    getDecryptCipherCounterBlock() {
        // set counter (block #) in last 8 bytes of counter block (leaving nonce in 1st 8 bytes)
        for (let c = 0;c < 4;c++) this.gCounterBlock[15 - c] = ((this.gCurBlock) >>> c * 8) & 0xff;
        for (let c = 0;c < 4;c++) this.gCounterBlock[15 - c - 4] = (((this.gCurBlock + 1) / 0x100000000 - 1) >>> c * 8) & 0xff;

        let cipherCntr:Uint8Array = this.cipher(this.gCounterBlock, this.gKeySchedule);  // encrypt counter block
        return(cipherCntr);
    }
    
    /** do a simple unscramble of hint from lightly protected version to a string (this is NOT an unencrypt)
     *
     */
    hintUnScramble(rawHint:Uint8Array):string
    {
        if(rawHint==null) return "";

        let retByteArray:Uint8Array = new Uint8Array(rawHint.length);
        let writeByteIndex=0;

        for(let i=0;i<rawHint.length;i++)
        { // do for each char - this assumes byte chars
            let nibLow:number = rawHint[i];
            let nibHigh:number = nibLow;
            nibLow = (nibLow >> 4) & 0x000f;
            nibHigh = nibHigh & 0x000f;
            nibHigh = nibHigh << 4;

            retByteArray[writeByteIndex++]=nibHigh | nibLow;
        }

        return(this.getBytesAsCharString(retByteArray));
    }
    //#endregion
}

